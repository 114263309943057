import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, message, Modal } from 'antd';
import useToggle from '../../../hooks/useToggle';
import LanguageInput from '../../../components/LanguagesInput';
import {
  useCreateTutorWorkExperienceMutation,
  useEditTutorWorkExperienceMutation
} from '../../../store/apis/administratives/tutors';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const WordExperienceTeacherForm = ({
  tutorId,
  mode = 'create',
  initialValues = {},
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const actionHooks = {
    create: useCreateTutorWorkExperienceMutation,
    edit: useEditTutorWorkExperienceMutation,
  }
  const [operateWork, { isLoading: isOperateWorkLoading, isFetching: isOperateWorkFetching }] = actionHooks[mode]();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
        fromYear: values.fromYear ? values.fromYear.year() : null,
        toYear: values.toYear ? values.toYear.year() : null,
      }
      await operateWork(body).unwrap();
      message.success(mode === 'create' ? t('Опыт работы добавлен') : t('Опыт работы изменен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, operateWork, mode, t]);

  const actualInitialValues = {
    ...initialValues,
    fromYear: initialValues.fromYear ? dayjs().year(initialValues.fromYear) : null,
    toYear: initialValues.toYear ? dayjs().year(initialValues.toYear) : null,
    tutorId,
  };

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button
              block
              type="link"
              onClick={showModal}
            >
              {t('Добавить опыт работы')}
            </Button>
          ) : (
            <Button
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={showModal}
            />
          )
      }
      <Modal
        open={isModalOpen}
        title={mode === 'create' ? t('Добавить опыт работы') : t('Изменить опыт работы')}
        okText={mode === 'create' ? t('Добавить') : t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        width={900}
        confirmLoading={isOperateWorkLoading || isOperateWorkFetching}
      >
        <Form
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={isOperateWorkLoading || isOperateWorkFetching}
          layout="vertical"
          name="tutor_work_form"
          initialValues={actualInitialValues}
        >
          <FormItem hidden name="tutorId" />
          <FormItem hidden name="id" />
          <FormItem label={t('Место работы')} required style={{ marginBottom: 0 }} rules={[{ required: true }]}>
            <LanguageInput name="place" required type="textarea" rows={3} />
          </FormItem>
          <FormItem label={t('Должность')} required style={{ marginBottom: 0 }} rules={[{ required: true }]}>
            <LanguageInput name="position" required type="textarea" rows={3} />
          </FormItem>
          <FormItem label={t('Год начала работы')} name="fromYear" required rules={[{ required: true }]}>
            <DatePicker picker="year" placeholder={t('Выберите год')} style={{ width: '100%' }} />
          </FormItem>
          <FormItem
            label={t('Год окончания работы')}
            name="toYear"
            required
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('fromYear').isSameOrBefore(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('Год начала работы больше чем год окончания работы')));
                }
              }),
            ]}
          >
            <DatePicker picker="year" placeholder={t('Выберите год')} style={{ width: '100%' }} />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default WordExperienceTeacherForm;
