// import cn from 'classnames';

import styles from './Filters.module.scss';
import { Button, Col, DatePicker, Drawer, Form, Row } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { formatDate } from '../../../utils';
import { identity } from 'ramda';
import queryString from 'query-string';
import DebounceSelect from '../../../components/DebounceSelect';
import { useLazyGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import { useGetStudentsQuery, useLazyGetStudentsQuery } from '../../../store/apis/administratives/students';
import dayjs from 'dayjs';

const Filters = () => {
  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState(false);

  const show = () => setOpen(true);
  const hide = () => setOpen(false);

  const navigate = useNavigate();

  const [
    getTutors,
  ] = useLazyGetTutorsQuery();

  const [
    getStudents,
  ] = useLazyGetStudentsQuery();

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const submitForm = (values) => {

    const query = {
      studentId: values.student?.value ?? null,
      tutorId: values.tutor?.value ?? null,
      createdTimeFrom: values.createdTime?.[0] ? values.createdTime?.[0].startOf('day').toISOString() : null,
      createdTimeTo: values.createdTime?.[1] ? values.createdTime?.[1].endOf('day').toISOString() : null,
    }
    navigate({
      pathname: '',
      search: queryString.stringify(query, { skipNull: true, skipEmptyString: true }),
    });
    hide();
  };

  const reset = useCallback(() => {
    navigate({ pathname: '' });
    hide();
  }, [hide, navigate]);

  const findStudents = async (lastName) => {
    try {
      const { results } = await getStudents({ lastName, page: -1 }).unwrap();
      return results.map((student) => ({
        value: student.id,
        label: student.fullName,
      }))
    } catch (e) {

    }
  }

  const findTutors = async (lastName) => {
    try {
      const { results } = await getTutors({ lastName, page: -1  }).unwrap();
      return results.map((tutor) => ({
        value: tutor.id,
        label: tutor.fullName[i18n.language],
      }))
    } catch (e) {

    }
  }

  return (
    <>
      <Button icon={<FilterOutlined />} type="primary" shape="circle" onClick={show} />
      <Drawer
        title={t('Фильтры')}
        placement="right"
        onClose={hide}
        open={open}
      >
        <Form
          onFinish={submitForm}
          layout="vertical"
        >
          <Form.Item label={t('За период')} name="createdTime">
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              allowClear={false}
            />
          </Form.Item>
          <Form.Item label={t('Студент')} name="student">
            <DebounceSelect
              placeholder={t('Введите ФАМИЛИЮ для поиска')}
              fetchOptions={findStudents}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item label={t('Учитель')} name="tutor">
            <DebounceSelect
              placeholder={t('Введите ФАМИЛИЮ для поиска')}
              fetchOptions={findTutors}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Row justify="space-between">
            <Col>
              <Button onClick={reset} htmlType="reset">
                {t('Сбросить')}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                {t('Показать')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default Filters;
