import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './RejectReview.module.scss';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { useApproveReviewMutation, useRejectReviewMutation } from '../../../store/apis/administratives/reviews';
import { Button, message, Popconfirm } from 'antd';

const RejectReview = ({ reviewId }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [reject, { isLoading, isFetching }] = useRejectReviewMutation();

  const rejectReview = useCallback(async () => {
    try {
      await reject(reviewId).unwrap();
      message.success(t('Отзыв отклонен'));
      close();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [reject, reviewId, t, close]);

  return (
    <Popconfirm
      open={isOpen}
      title={t('Вы уверены?')}
      description={t('Этот комментарий не будет опубликован, а его оценка учтена')}
      okText={t('Да, отклонить')}
      cancelText={t('Нет')}
      onConfirm={rejectReview}
      onCancel={close}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
    >
      <Button
        size="small"
        type="primary"
        onClick={open}
        danger
        disabled={!reviewId}
      >
        {t('Отклонить')}
      </Button>
    </Popconfirm>
  );
};

export default RejectReview;
