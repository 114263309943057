import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.query({
      query: (params) => ({
        url: apiRoutes.packages(),
        params,
      }),
      providesTags: ['PACKAGES'],
    }),

    transferPackage: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.transferPackage(id),
        body,
        method: 'POST'
      }),
      invalidatesTags: invalidateOn({ success: ['PACKAGES'] }),
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useTransferPackageMutation,
} = rootApi;
