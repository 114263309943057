import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Form, message, Select, Skeleton } from 'antd';
import MainError from '../../errors/MainError';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import { useEditLearningGoalMutation, useGetLearningGoalQuery } from '../../../store/apis/dictionaries/learning-goals';
import { dictionaryToSelect } from '../../../utils';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import {
  useEditQualificationMutation,
  useGetQualificationQuery
} from '../../../store/apis/dictionaries/qualifications';

const Details = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [edit, { isLoading }] = useEditQualificationMutation();
  const { data: subjects, isLoading: isSubjectsLoading } = useGetSubjectsQuery({ page: -1 });

  const {
    data: dictionaryData,
    isLoading: isDictionaryLoading,
    isFetching: isDictionaryFetching,
    error: dictionaryError,
  } = useGetQualificationQuery(id);

  const handleSubmit = useCallback(async (body) => {
    try {
      await edit({ id, body }).unwrap();
      message.success(t('Квалификация сохранена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [edit, id, t]);

  const handleExit = useCallback(() => {
    navigate(routes.qualifications());
  }, [navigate]);

  if (isDictionaryLoading || isDictionaryFetching) return <Skeleton />
  if (dictionaryError) return <MainError error={dictionaryError} />

  return (
    <div>
      <ContentHeader title={dictionaryData.sysName} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{
          ...dictionaryData,
          subject: dictionaryData.subject.id,
        }}
        loading={isLoading}
        saveTitle={t('Сохранить')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >
        <Form.Item name="subject" label={t('Предмет')}>
          <Select
            showSearch
            placeholder={t('Выберите предмет')}
            loading={isSubjectsLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={dictionaryToSelect(subjects?.results)}
          />
        </Form.Item>
      </DictionaryForm>
    </div>
  );
};

export default Details;
