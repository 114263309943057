import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getVideoAboutPlatform: build.query({
      query: (params) => ({
        url: apiRoutes.videoAboutPlatform(),
        params,
      }),
      providesTags: ['VIDEO_ABOUT_PLATFORM'],
    }),

    editVideoAboutPlatform: build.mutation({
      query: (body) => ({
        url: apiRoutes.videoAboutPlatform(),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['VIDEO_ABOUT_PLATFORM'] }),
    }),
  })
});

export const {
  useGetVideoAboutPlatformQuery,
  useEditVideoAboutPlatformMutation,
} = rootApi;
