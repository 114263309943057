import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './RejectPackageCancellationRequest.module.scss';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { useRejectPackageCancellationRequestMutation } from '../../../store/apis/finances/packageCancellations';

const RejectPackageCancellationRequest = ({ id }) => {
  const [t] = useTranslation();
  const [isOpen, show, hide] = useToggle();

  const [
    rejectPackageCancellationRequest,
    {
      isLoading,
      isFetching,
    }
  ] = useRejectPackageCancellationRequestMutation();

  const submitForm = useCallback(async (values) => {
    try {
      await rejectPackageCancellationRequest({ id, body: values }).unwrap();
      message.success(t('В возврате отказано'));
      hide();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [hide, id, rejectPackageCancellationRequest, t]);

  return (
    <>
      <Button
        danger
        onClick={show}
        disabled={!id}
      >
        {t('Отказать')}
      </Button>
      <Modal
        title={t('Отказать в возврате')}
        open={isOpen}
        onCancel={hide}
        footer={false}
        destroyOnClose
      >
        <p>
          {t('Вы уверены что хотите отказать в возврате средств студенту?')}
        </p>
        <Form
          name="reject_cancellation_package_request"
          onFinish={submitForm}
          layout="vertical"
          disabled={isLoading || isFetching}
        >
          <Form.Item label={t('Комментарий')} name="comment" required rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Row justify="end" gutter={16}>
            <Col>
              <Button onClick={hide}>
                {t('Закрыть окно')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading || isFetching}
              >
                {t('Подтвердить')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RejectPackageCancellationRequest;
