import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import useQueryString from '../../../hooks/useQueryString';
import DictionaryList from '../../../containers/shared/DictionaryList';
import { useGetEducationLevelsQuery } from '../../../store/apis/dictionaries/education-levels';

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20 } = useQueryString();
  const { data, isLoading, isFetching } = useGetEducationLevelsQuery({ page, pageSize });

  return (
    <DictionaryList
      title={t('Степени образования')}
      data={data?.results}
      dataCount={data?.count}
      loading={isLoading || isFetching}
      detailRoute={routes.educationLevels}
    />
  );
};

export default List;
