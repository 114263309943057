import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetLegalDocumentsQuery } from '../../../store/apis/administratives/legalDocuments';
import ContentHeader from '../../../components/ContentHeader';
import ListFilters from '../../lessons/ListFilters';
import { Skeleton, Table } from 'antd';
import { formatDate } from '../../../utils';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import Create from '../Create';

const List = () => {
  const [t, i18n] = useTranslation();
  const {
    data: legalDocument,
    isLoading: isLegalDocumentLoading,
    isFetching: isLegalDocumentFetching,
  } = useGetLegalDocumentsQuery({ page: -1 });

  const columns = useMemo(() => ([
    {
      dataIndex: 'header',
      key: 'header',
      title: t('Название'),
      render: (value, raw) => <Link to={routes.legalDocuments(raw.id)}>{value[i18n.language]}</Link>,
    },
    {
      dataIndex: 'createdTime',
      key: 'createdTime',
      title: t('Дата создания'),
      render: (value) => formatDate(value, true),
    },
    {
      dataIndex: 'editedTime',
      key: 'editedTime',
      title: t('Дата редактирования'),
      render: (value) => formatDate(value, true),
    },
    {
      dataIndex: 'isPublic',
      key: 'isPublic',
      title: t('Опубликован?'),
      render: (value) => value ? '✅' : '❌',
    },
    {
      dataIndex: 'id',
      key: 'id',
      title: t('Файлы'),
      render: (value, raw) => (
        <>
          <a href={raw.pdfUrl} target="_blank" download>PDF</a>
          {' '}
          <a href={raw.docxUrl} target="_blank" download>DOCX</a>
        </>
      ),
    },
  ]), [i18n.language, t]);

  if (isLegalDocumentLoading || isLegalDocumentFetching) return (
    <Skeleton loading />
  )

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Правовые документы')}>
          <Create />
        </ContentHeader>
      </div>
      <div className={styles.body}>
        <Table
          rootClassName="overflow-scroll"
          columns={columns}
          dataSource={legalDocument?.results}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default List;
