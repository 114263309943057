import React, { useState } from 'react';
import cn from 'classnames';

import styles from './AddPromoPackage.module.scss';
import { FiGift } from 'react-icons/fi';
import ActionButton from '../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { Form, message, Modal, Select } from 'antd';
import {
  useAddPromoTariffToStudentMutation,
  useGetPromoTariffsQuery,
  useGetTariffsQuery
} from '../../../store/apis/administratives/tariffs';
import { dictionaryToSelect } from '../../../utils';
import { find, propEq } from 'ramda';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';

const AddPromoPackage = ({
  studentId,
}) => {
  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const {
    data: promoTariffs,
    isFetching: isFetchingPromoTariffs,
  } = useGetPromoTariffsQuery({ page: -1 });

  const {
    data: tutors,
    isFetching: isTutorsFetching,
  } = useGetTutorsQuery({ page: -1 });

  const {
    data: allTariffs,
    isFetching: isFetchingAllTariffs,
  } = useGetTariffsQuery({ page: -1 });

  const [
    addPromoTariffToStudent,
    { isLoading: isAddingTariff },
  ] = useAddPromoTariffToStudentMutation();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleFinish = async (values) => {
    try {
      await addPromoTariffToStudent(values).unwrap();
      message.success(t('Промо тариф назначен студенту'));
      closeModal();
      form.resetFields();
    } catch (e) {
      console.error(e);
    }
  };

  const tariffOptions = (promoTariffs?.tariffs && allTariffs?.results)
    ? promoTariffs.tariffs.map((tariffId) => {
      const tariffData = find(propEq(tariffId, 'id'))(allTariffs.results);
      return {
        value: tariffId,
        label: `${tariffData.sysName} - ${t('lessonsCount', { count: tariffData.lessonCount })}`,
      };
    })
    : [];

  const tutorOptions = (tutors && tutors.results)
    ? tutors.results.map((tutor) => ({
      value: tutor.id,
      label: `${tutor.fullName[i18n.language]} - ${tutor.subjects[0]?.name[i18n.language]}`,
    }))
    : [];

  return (
    <>
      <ActionButton color="blue" text={t('Назначить промо пакет')} icon={<FiGift />} onClick={openModal} />
      <Modal
        title={t('Назначить промо пакет')}
        open={open}
        onOk={form.submit}
        onCancel={() => {
          form.resetFields();
          closeModal();
        }}
        confirmLoading={isAddingTariff}
        cancelText={t('Отменить')}
        okText={t('Назначить')}
        destroyOnClose
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Form.Item name="studentId" hidden initialValue={studentId} />
          <Form.Item label={t('Преподаватель')} name="tutorId" required rules={[{ required: true }]}>
            <Select
              options={tutorOptions}
              loading={isTutorsFetching}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item label={t('Промо пакет')} name="tariffId" required rules={[{ required: true }]}>
            <Select options={tariffOptions} loading={isFetchingPromoTariffs} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddPromoPackage;
