import React from 'react';
import cn from 'classnames';

import styles from './Create.module.scss';
import { message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Form from '../Form';
import { useCreateVacancyMutation } from '../../../store/apis/administratives/vacancies';

const Create = () => {
  const [t] = useTranslation();
  const [
    createVacancy,
    {
      isLoading: isCreating,
    }
  ] = useCreateVacancyMutation();
  const handleSubmit = async (values) => {
    try {
      await createVacancy(values).unwrap();
      message.success(t('Предмет преподавания создан'))
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  return (
    <div>
      <Typography.Title level={4}>
        {t('Предмет преподавания')}
      </Typography.Title>
      <Form
        initialValues={{}}
        onSubmit={handleSubmit}
        loading={isCreating}
      />
    </div>
  );
};

export default Create;
