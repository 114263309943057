import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { message, Skeleton } from 'antd';
import MainError from '../../errors/MainError';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import { useEditLearningGoalMutation, useGetLearningGoalQuery } from '../../../store/apis/dictionaries/learning-goals';

const Details = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [edit, { isLoading }] = useEditLearningGoalMutation();

  const {
    data: dictionaryData,
    isLoading: isDictionaryLoading,
    isFetching: isDictionaryFetching,
    error: dictionaryError,
  } = useGetLearningGoalQuery(id);

  const handleSubmit = useCallback(async (body) => {
    try {
      await edit({ id, body }).unwrap();
      message.success(t('Цель обучения сохранена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [edit, id, t]);

  const handleExit = useCallback(() => {
    navigate(routes.learningGoals());
  }, [navigate]);

  if (isDictionaryLoading || isDictionaryFetching) return <Skeleton />
  if (dictionaryError) return <MainError error={dictionaryError} />

  return (
    <div>
      <ContentHeader title={dictionaryData.sysName} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{
          ...dictionaryData,
        }}
        loading={isLoading}
        saveTitle={t('Сохранить')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >

      </DictionaryForm>
    </div>
  );
};

export default Details;
