// import cn from 'classnames';

import styles from './BookkeepingList.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetBookkeepingsQuery } from '../../../store/apis/finances/bookkeeping';
import useQueryString from '../../../hooks/useQueryString';
import { Button, Col, Empty, Row, Skeleton, Table, Tag, Typography } from 'antd';
import { useCallback } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { formatDate, formatNumber, toSom } from '../../../utils';
import usePermissions from '../../../hooks/usePermissions';
import DeleteBookkeeping from '../../../containers/bookkeeping/DeleteBookkeeping';
import routes from '../../../router/routes';
import TakeDateForBookkeepingModal from '../../../containers/bookkeeping/TakeDateForBookkeepingModal';

const BookkeepingList = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const navigate = useNavigate();
  const checkPermissions = usePermissions();

  const {
    data: bookkeepings,
    isLoading,
  } = useGetBookkeepingsQuery({
    page,
    pageSize,
    ...restQuery,
  });

  const columns = [
    {
      key: 'fromDate',
      dataIndex: 'fromDate',
      title: t('Начальная дата'),
      render: (date) => formatDate(date),
    },
    {
      key: 'toDate',
      dataIndex: 'toDate',
      title: t('Конечная дата'),
      render: (date) => formatDate(date),
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: t('Итоговая сумма'),
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'amountWithoutTaxes',
      dataIndex: 'amountWithoutTaxes',
      title: t('Итоговая сумма с учетом налогов'),
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'isTotallyPaid',
      dataIndex: 'isTotallyPaid',
      title: t('Состояние'),
      render: (isTotallyPaid) => isTotallyPaid
        ? <Tag color="green">{t('Выплачена')}</Tag>
        : <Tag color="red">{t('Не выплачена полностью')}</Tag>,
    },
    {
      key: 'totallyPaidTime',
      dataIndex: 'totallyPaidTime',
      title: t('Дата полной выплаты'),
      render: (date) => date ? formatDate(date) : '-',
    },
    {
      key: 'actions',
      dataIndex: 'id',
      title: t('Действия'),
      render: (id) => (
        checkPermissions(['SUPER_ADMIN', 'ACCOUNTANT'])
          ? <DeleteBookkeeping id={id} />
          : null
      ),
    },
  ];

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  if (isLoading) return <Skeleton />;
  if (!bookkeepings) return <Empty description={t('Не удалось получить данные, попробуйте еще раз')} />

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <Typography.Title level={4}>{t('Ведомости (выплаты)')}</Typography.Title>
        </Col>
        <Col>
          <TakeDateForBookkeepingModal />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={bookkeepings.results}
        onChange={handlePageChange}
        onRow={(row) => ({
          onClick: () => {
            navigate(routes.tutorBookkeeping(row.id));
          },
        })}
        pagination={{
          total: bookkeepings.count,
          current: page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </div>
  );
};

export default BookkeepingList;
