import React from 'react';

import styles from './MainError.module.scss';

const MainError = ({ error }) => {
  console.log(error);
  return (
    <div className={styles.error}>
      <div className={styles.errorIcon}>🤕</div>
      <div className={styles.errorTitle}>{error.status}</div>
      <div className={styles.errorSubtitle}>{error.data?.message}</div>
    </div>
  );
};

export default MainError;
