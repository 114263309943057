import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './MainDashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetDashboardQuery } from '../../../store/apis/reports/dashboard';
import { DatePicker, Col, Divider, Row, Skeleton, Space, Statistic, Typography } from 'antd';
import { green, gold, blue } from '@ant-design/colors';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

const MainDashboard = () => {
  const [t] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);

  const {
    data: stat,
    isLoading,
    isFetching,
  } = useGetDashboardQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  if (isLoading || isFetching) return <Skeleton loading />

  return (
    <div>
      <Row style={{ marginBottom: 32 }}>
        <Col>
          <Title level={3}>{t('Основная статистика')}</Title>
          <Space>
            <Text>{t('Статистика за период')}</Text>
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              onChange={changeFilterDate}
              value={lessonDateDiapason}
              allowClear={false}
            />

          </Space>
        </Col>
      </Row>
      <Row gutter={16} justify="space-around">
        <Col>
          <Statistic
            title={t('Всего получено')}
            value={stat.data.totalIncomeAmount}
            groupSeparator="'"
            valueStyle={{ color: blue.primary }}
            suffix={t('сум')}
          />
        </Col>
        <Col>
          <Statistic
            title={t('Процент платформы')}
            value={stat.data.totalSystemIncomeAmount}
            groupSeparator="'"
            valueStyle={{ color: green.primary }}
            suffix={t('сум')}
          />
        </Col>
        <Col>
          <Statistic
            title={t('Процент учителей')}
            value={stat.data.totalTutorIncomeAmount}
            groupSeparator="'"
            valueStyle={{ color: gold.primary }}
            suffix={t('сум')}
          />
        </Col>
        <Divider />
        <Col>
          <Statistic
            title={t('Активных студентов')}
            value={stat.data.activeStudentCount}
            prefix="🗣️"
          />
        </Col>
        <Col>
          <Statistic
            title={t('Новых студентов')}
            value={stat.data.totalStudentCount}
            prefix="👥"
          />
        </Col>
        <Divider />
        <Col>
          <Statistic
            title={t('Проведено уроков')}
            value={stat.data.finishedLessonCount}
            prefix="📚"
          />
        </Col>
        <Col>
          <Statistic
            title={t('Завершено пробных уроков')}
            value={stat.data.finishedTrialLessonCount}
            prefix="📚"
          />
        </Col>
        <Col>
          <Statistic
            title={t('Забронировано пробных уроков')}
            value={stat.data.trialPackageLessonsCount}
            prefix="📚"
          />
        </Col>
      </Row>
    </div>
  );
};

export default MainDashboard;
