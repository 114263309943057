import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './LanguageSkillTeacherForm.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, message, Modal, Select } from 'antd';
import useToggle from '../../../hooks/useToggle';
import {
  useCreateTutorLanguageSkillMutation,
  useCreateTutorWorkExperienceMutation, useEditTutorLanguageSkillMutation
} from '../../../store/apis/administratives/tutors';
import LanguageInput from '../../../components/LanguagesInput';
import { useGetLanguagesQuery } from '../../../store/apis/dictionaries/languages';
import { dictionaryToSelect } from '../../../utils';
import { useGetLanguageLevelsQuery } from '../../../store/apis/dictionaries/languages-levels';
import { EditOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const LanguageSkillTeacherForm = ({
  tutorId,
  initialValues = {},
  mode = 'create',
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const { data: languages, isLoading: isLanguagesLoading, isFetching: isLanguagesFetching } = useGetLanguagesQuery({ page: -1 });
  const { data: languageLevels, isLoading: isLanguageLevelsLoading, isFetching: isLanguageLevelsFetching } = useGetLanguageLevelsQuery({ page: -1 });

  const actionHooks = {
    create: useCreateTutorLanguageSkillMutation,
    edit: useEditTutorLanguageSkillMutation,
  };

  const [operateLanguageSkill, { isLoading: isOperateTutorLanguageSkillLoading, isFetching: isOperateTutorLanguageSkillFetching }] = actionHooks[mode]();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
      }
      await operateLanguageSkill(body).unwrap();
      message.success(mode === 'create' ? t('Язык владения добавлен') : t('Язык владения изменен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, operateLanguageSkill, mode, t]);

  const actualInitialValues = {
    ...initialValues,
    language: initialValues.language?.id,
    languageSkillLevel: initialValues.languageSkillLevel?.id,
    tutorId,
  };

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button
              block
              type="link"
              onClick={showModal}
            >
              {t('Добавить язык владения')}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={showModal}
              size="small"
              icon={<EditOutlined />}
            />
          )
      }
      <Modal
        open={isModalOpen}
        title={mode === 'create' ? t('Добавить язык владения') : t('Изменить язык владения')}
        okText={mode === 'create' ? t('Добавить') : t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isOperateTutorLanguageSkillLoading || isOperateTutorLanguageSkillFetching}
      >
        <Form
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={isOperateTutorLanguageSkillLoading || isOperateTutorLanguageSkillFetching}
          layout="vertical"
          name="tutor_work_form"
          initialValues={actualInitialValues}
        >
          <FormItem hidden name="tutorId" />
          <FormItem hidden name="id" />
          <FormItem label={t('Язык')} name="language">
            <Select
              showSearch
              placeholder={t('Выберите язык')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isLanguagesLoading || isLanguagesFetching}
              options={dictionaryToSelect(languages?.results)}
            />
          </FormItem>
          <FormItem label={t('Уровень')} name="languageSkillLevel">
            <Select
              showSearch
              placeholder={t('Уровень')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isLanguageLevelsLoading || isLanguageLevelsFetching}
              options={dictionaryToSelect(languageLevels?.results)}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default LanguageSkillTeacherForm;
