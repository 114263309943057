import apiRoutes from '../../apiRoutes';
import { invalidateOn } from '../utils';
import { rootApi } from './index';


const tutorCv = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTutorCvs: builder.query({
      query: (params) => ({
        url: apiRoutes.tutorCv(),
        params,
      }),
      providesTags: ['CVS'],
    }),

    markAsResolved: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.tutorCv(id),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['CVS'] }),
    }),
  })
});

export const {
  useGetTutorCvsQuery,
  useMarkAsResolvedMutation,
} = tutorCv;
