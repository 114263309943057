import React from 'react';
import { Result } from 'antd';

const FetchErrorContent = ({ error }) => {

  return (
    <Result
      status={error.status}
      title={error.status}
      subTitle={error.data?.message}
    />
  );
};

export default FetchErrorContent;
