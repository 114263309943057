import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Form, message, Select } from 'antd';
import routes from '../../../router/routes';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useCreateLearningGoalMutation } from '../../../store/apis/dictionaries/learning-goals';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import { dictionaryToSelect } from '../../../utils';

const Create = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [create, { isFetching, isLoading }] = useCreateLearningGoalMutation();
  const { data: subjects, isLoading: isSubjectsLoading } = useGetSubjectsQuery({ page: -1 });

  const handleSubmit = useCallback(async (values) => {
    try {
      await create({ body: values }).unwrap();
      message.success(t('Цель обучения создана'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [create, t]);

  const handleExit = useCallback(() => {
    navigate(routes.learningGoals());
  }, [navigate]);

  return (
    <div>
      <ContentHeader title={t('Создать новую цель обучения')} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{}}
        loading={isFetching || isLoading}
        saveTitle={t('Сохранить и создать еще')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >

      </DictionaryForm>
    </div>
  );
};

export default Create;
