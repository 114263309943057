// import cn from 'classnames';

import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDeleteBookkeepingMutation } from '../../store/apis/finances/bookkeeping';

const DeleteBookkeeping = ({ id }) => {
  const [t] = useTranslation();

  const [
    deleteBookkeeping,
    { isLoading: isDeleting },
  ] = useDeleteBookkeepingMutation();

  const handleDelete = async (e) => {
    e.stopPropagation();
    try {
      await deleteBookkeeping(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Popconfirm
      title={t('Вы уверены')}
      onConfirm={handleDelete}
      okButtonProps={{
        loading: isDeleting,
      }}
    >
      <Button icon={<DeleteOutlined />} danger size="small" type="link" onClick={(e) => { e.stopPropagation() }} />
    </Popconfirm>
  );
};

export default DeleteBookkeeping;
