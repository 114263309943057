import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';

import styles from './Form.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import { Button, Col, Form as AntForm, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { dictionaryToSelect, normFile } from '../../../utils';
import LanguageInput from '../../../components/LanguagesInput';
import Uploader from '../../../containers/shared/Uploader';

const FormItem = AntForm.Item;

const Form = ({
  initialValues,
  onSubmit,
  loading,
}) => {
  const [t] = useTranslation();

  const { data: subjects, isLoading: isSubjectsLoading } = useGetSubjectsQuery({ page: -1 });

  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    await onSubmit(values);
    form.resetFields();
  };

  const handleSubmitAndExit = useCallback(async (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    await onSubmit(values);
    navigate(routes.tutorStories());
  }, [form, navigate, onSubmit]);

  return (
    <AntForm
      form={form}
      name="tutor-stories-form"
      onFinish={handleSubmit}
      initialValues={{
        ...initialValues,
        subject: initialValues?.subject?.id
      }}
      layout="vertical"
      disabled={loading}
    >
      <FormItem
        label={t('Опубликован?')}
        required
        name="isPublic"
        valuePropName="checked"
      >
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </FormItem>
      <FormItem
        label={t('Порядковый номер')}
        required
        name="orderId"
      >
        <InputNumber prefix="№" />
      </FormItem>
      <FormItem name="subject" label={t('Предмет')} style={{ width: '33%' }}>
        <Select
          showSearch
          placeholder={t('Выберите предмет')}
          loading={isSubjectsLoading}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={dictionaryToSelect(subjects?.results)}
        />
      </FormItem>
      <FormItem label={t('ФИО учителя')} required style={{ marginBottom: 0 }}>
        <LanguageInput required name="fullName" />
      </FormItem>
      <FormItem label={t('Заголовок')} required style={{ marginBottom: 0 }}>
        <LanguageInput required name="header" />
      </FormItem>
      <FormItem label={t('Текст')} required style={{ marginBottom: 0 }}>
        <LanguageInput required name="text" type="textarea" rows={10} />
      </FormItem>
      <FormItem
        label={t('Фото учителя')}
        name="imagePath"
        getValueFromEvent={normFile}
        required
      >
        <Uploader
          maxCount={1}
          multiple={false}
          group="subjects"
          existedFileUrl={initialValues?.imageUrl}
        />
      </FormItem>
      <Row justify="end">
        <Col>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              onClick={handleSubmitAndExit}
            >
              {t('Сохранить и выйти')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              onClick={handleSubmit}
            >
              {t('Сохранить')}
            </Button>
          </Space>
        </Col>
      </Row>
    </AntForm>
  );
};

export default Form;
