import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (params) => ({
        url: apiRoutes.dashboard(),
        params,
      }),
      providesTags: ['DASHBOARD'],
    }),

    getStatsByTutor: builder.query({
      query: (params) => ({
        url: apiRoutes.statsByTutor(),
        params,
      }),
      providesTags: ['STATS_BY_TUTOR'],
    }),
    getStatsByTariff: builder.query({
      query: (params) => ({
        url: apiRoutes.statsByTariff(),
        params,
      }),
      providesTags: ['STATS_BY_TARIFF'],
    }),
    getStatsBySubject: builder.query({
      query: (params) => ({
        url: apiRoutes.statsBySubject(),
        params,
      }),
      providesTags: ['STATS_BY_SUBJECT'],
    }),
    getTutorsWithdrawals: builder.query({
      query: (params) => ({
        url: apiRoutes.tutorsWithdrawals(),
        params,
      }),
      providesTags: ['TUTORS_WITHDRAWALS'],
    }),
    getPaymentStats: builder.query({
      query: (params) => ({
        url: apiRoutes.paymentStats(),
        params,
      }),
      providesTags: ['PAYMENT_STATS'],
    }),
    getPackageCancellationDashboard: builder.query({
      query: (params) => ({
        url: apiRoutes.packageCancellationDashboard(),
        params,
      }),
      providesTags: ['PACKAGE_CANCELLATION_DASHBOARD'],
    }),
    getPackageCancellationByTutor: builder.query({
      query: (params) => ({
        url: apiRoutes.packageCancellationByTutor(),
        params,
      }),
      providesTags: ['PACKAGE_CANCELLATION_BY_TUTOR'],
    }),
    getDebitsByTutor: builder.query({
      query: (params) => ({
        url: apiRoutes.debitsByTutor(),
        params,
      }),
      providesTags: ['DEBITS_BY_TUTOR'],
    }),
    getLessonMovements: builder.query({
      query: (params) => ({
        url: apiRoutes.movementsByTutor(),
        params,
      }),
      providesTags: ['LESSON_MOVEMENTS'],
    }),
    getSmsToPlaymobile: builder.query({
      query: (params) => ({
        url: apiRoutes.smsToPlaymobile(),
        params,
      }),
      providesTags: ['SMS_PLAYMOBILE'],
    }),
    getScheduleLogs: builder.query({
      query: (params) => ({
        url: apiRoutes.scheduleLogs(),
        params,
      }),
      providesTags: ['SCHEDULE_LOGS'],
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetStatsByTutorQuery,
  useGetStatsByTariffQuery,
  useGetStatsBySubjectQuery,
  useGetTutorsWithdrawalsQuery,
  useGetPaymentStatsQuery,
  useGetPackageCancellationDashboardQuery,
  useGetPackageCancellationByTutorQuery,
  useGetDebitsByTutorQuery,
  useGetLessonMovementsQuery,
  useGetSmsToPlaymobileQuery,
  useGetScheduleLogsQuery,
  useLazyGetScheduleLogsQuery,
} = rootApi;
