// import cn from 'classnames';

import styles from './TransferPackage.module.scss';
import { Button, DatePicker, Form, Input, message, Modal, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useTransferLessonMutation } from '../../../store/apis/administratives/students';
import { RollbackOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTransferPackageMutation } from '../../../store/apis/administratives/packages';
import { useGetTutorsQuery, useLazyGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import DebounceSelect from '../../../components/DebounceSelect';

const TransferPackage = ({ packageId }) => {
  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [
    transferPackage,
    { isLoading: isTransfering },
  ] = useTransferPackageMutation();

  const [
    getTutors,
    { isLoading },
  ] = useLazyGetTutorsQuery();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const findTutors = async (lastName) => {
    try {
      const { results } = await getTutors({ lastName }).unwrap();
      return results.map((tutor) => ({
        value: tutor.id,
        label: tutor.fullName[i18n.language],
      }))
    } catch (e) {

    }
  }

  const handleSubmit = async (values) => {
    try {
      const body = {
        toTutorId: values.toTutorId.value,
      };
      await transferPackage({ id: packageId, body }).unwrap();
      message.success(t('Пакет перенесен'));
      form.resetFields();
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Tooltip title={t('Перенести пакет на другого учителя')}>
        <Button onClick={openModal} size="small" icon={<RollbackOutlined />} type="link" />
      </Tooltip>
      <Modal
        destroyOnClose
        open={open}
        title={t('Перенести пакет')}
        onOk={form.submit}
        onCancel={closeModal}
        okText={t('Перенести пакет')}
        cancelText={t('Отменить')}
        confirmLoading={isTransfering}
      >
        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item label={t('Учитель на которого нужно перенести пакет')} name="toTutorId" rules={[{ required: true }]}>
            <DebounceSelect
              placeholder={t('Введите ФАМИЛИЮ для поиска')}
              fetchOptions={findTutors}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TransferPackage;
