import React, { useCallback, useMemo } from 'react';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Col, message, Popconfirm, Row, Skeleton, Table } from 'antd';
import { useGetSocialLinksQuery, useRemoveSocialLinksMutation } from '../../../store/apis/administratives/socialLinks';
import {
  FaDiscord,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaOdnoklassniki,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaVk,
  FaWhatsapp,
  FaYoutube
} from 'react-icons/fa';
import Form from '../Form';
import { DeleteOutlined } from '@ant-design/icons';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const navigate = useNavigate();

  const {
    data: socialLink,
    isFetching: isSocialLinksFetching,
    isLoading: isSocialLinkLoading,
  } = useGetSocialLinksQuery({ page, pageSize });

  const [
    removeSocialLink,
    {
      isLoading: isRemoveSocialLinkLoading,
      isFetching: isRemoveSocialLinkFetching,
    }
  ] = useRemoveSocialLinksMutation();

  // const handleNavigateToCreate = useCallback(() => {
  //   navigate(routes.faqs('create'));
  // }, [navigate]);

  const socialLinksIcons = useMemo(() => ({
    telegram: FaTelegramPlane,
    facebook: FaFacebookF,
    instagram: FaInstagram,
    youtube: FaYoutube,
    twitter: FaTwitter,
    tiktok: FaTiktok,
    linkedin: FaLinkedin,
    discord: FaDiscord,
    whatsapp: FaWhatsapp,
    vk: FaVk,
    odnoklassniki: FaOdnoklassniki,
  }), []);

  const handlePageChange = useCallback((page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ ...restQuery, page: page.current, pageSize: page.pageSize }),
    });
  }, [navigate, restQuery]);

  const handleRemoveSocialLink = useCallback((id) => async () => {
    try {
      await removeSocialLink(id).unwrap();
      message.success(t('Ссылка удалена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [removeSocialLink, t]);

  const columns = useMemo(() => [
    {
      title: t('Порядковый номер'),
      dataIndex: 'orderId',
      key: 'id',
    },
    {
      title: t('Иконка'),
      dataIndex: 'iconName',
      key: 'iconName',
      render: (value) => {
        const IconComponent = socialLinksIcons[value];
        if (!IconComponent) return t('Не найдено');
        return <IconComponent />
      },
    },
    {
      title: t('Ссылка'),
      dataIndex: 'url',
      key: 'url',
      render: (value) => value,
    },
    {
      title: t('Действия'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, raw) => (
        <>
          <Form mode="edit" id={raw.id} initialValues={raw} />
          <Popconfirm
            title={t('Вы уверены что хотите удалить ссылку?')}
            onConfirm={handleRemoveSocialLink(raw.id)}
            okText={t('Да, удалить')}
            cancelText={t('Нет, отменить')}
          >
            <Button icon={<DeleteOutlined />} type="link" danger />
          </Popconfirm>
        </>
      ),
    },
  ], [socialLinksIcons, t]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Ссылки на социальные сети')}>
          <Form mode="create" />
        </ContentHeader>
      </div>
      <Row>
        <Col md={24}>
          {
            (isSocialLinksFetching || isSocialLinkLoading)
              ? <Skeleton loading />
              : (
                <Table
                  rootClassName="overflow-scroll"
                  loading={isSocialLinksFetching || isSocialLinkLoading}
                  columns={columns}
                  rowKey="id"
                  dataSource={socialLink?.results}
                  onChange={handlePageChange}
                  pagination={{
                    total: socialLink?.count,
                    current: page,
                    locale: { items_per_page: `/ ${t('на странице')}` },
                    pageSize,
                    showSizeChanger: true,
                    showTitle: true,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              )
          }
        </Col>
      </Row>
    </div>
  );
};

export default List;
