import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Create.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Input, message, Modal } from 'antd';
import useToggle from '../../../hooks/useToggle';
import LanguageInput from '../../../components/LanguagesInput';
import Uploader from '../../../containers/shared/Uploader';
import { normFile } from '../../../utils';
import { useCreateLegalDocumentsMutation } from '../../../store/apis/administratives/legalDocuments';

const Create = () => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [form] = Form.useForm();

  const [
    createDocument,
    {
      isLoading: isCreateDocumentLoading,
      isFetching: isCreateDocumentFetching,
    },
  ] = useCreateLegalDocumentsMutation();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();

      await createDocument(values).unwrap();
      message.success(t('Документ создан'));
      close();
      form.resetFields();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [close, createDocument, form, t]);

  const handleClose = useCallback(() => {
    form.resetFields();
    close();
  }, [close, form]);

  return (
    <>
      <Button
        htmlType="button"
        type="primary"
        onClick={open}
      >
        {t('Создать')}
      </Button>
      <Modal
        open={isOpen}
        title={t('Создать документ')}
        onOk={handleSubmit}
        onCancel={handleClose}
        okText={t('Создать')}
        cancelText={t('Отменить')}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          disabled={isCreateDocumentLoading || isCreateDocumentFetching}
        >
          <Form.Item
            label={t('Название документа')}
            required
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
            name="header"
          >
            <LanguageInput name="header" />
          </Form.Item>
          <Form.Item name="isPublic" valuePropName="checked">
            <Checkbox>{t('Опубликован?')}</Checkbox>
          </Form.Item>
          <Form.Item
            label={t('Системное название')}
            name="alias"
            required
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('PDF файл')}
            name="pdfPath"
            getValueFromEvent={normFile}
            required
            rules={[{ required: true }]}
          >
            <Uploader
              maxCount={1}
              multiple={false}
              group="legal_documents"
            />
          </Form.Item>
          <Form.Item
            label={t('DOCX файл')}
            name="docxPath"
            getValueFromEvent={normFile}
            required
            rules={[{ required: true }]}
          >
            <Uploader
              maxCount={1}
              multiple={false}
              group="legal_documents"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Create;
