import { createBrowserRouter } from 'react-router-dom';

import Entry from '../views/Entry';
import routes from './routes';
import Application from '../views/Application';
import Welcome from '../contents/main/Welcome';
import ErrorPage from '../views/ErrorPage';

import TeacherList from '../contents/teachers/List';
import TeacherDetails from '../contents/teachers/Details';

import TariffList from '../contents/tariffs/List';
import TariffDetails from '../contents/tariffs/Details';
import TariffCreate from '../contents/tariffs/Create';

import SubjectList from '../contents/subjects/List';
import SubjectDetails from '../contents/subjects/Details';
import SubjectCreate from '../contents/subjects/Create';

import LanguageList from '../contents/laguages/List';
import LanguageDetails from '../contents/laguages/Details';
import LanguageCreate from '../contents/laguages/Create';

import LanguageLevelList from '../contents/laguageLevels/List';
import LanguageLevelDetails from '../contents/laguageLevels/Details';
import LanguageLevelCreate from '../contents/laguageLevels/Create';

import EducationLevelList from '../contents/educationLevels/List';
import EducationLevelDetails from '../contents/educationLevels/Details';
import EducationLevelCreate from '../contents/educationLevels/Create';

import EducationInstitutionList from '../contents/educationInstitutions/List';
import EducationInstitutionDetails from '../contents/educationInstitutions/Details';
import EducationInstitutionCreate from '../contents/educationInstitutions/Create';

import LearningGoalList from '../contents/learningGoals/List';
import LearningGoalDetails from '../contents/learningGoals/Details';
import LearningGoalCreate from '../contents/learningGoals/Create';

import QualificationList from '../contents/qualifications/List';
import QualificationDetails from '../contents/qualifications/Details';
import QualificationCreate from '../contents/qualifications/Create';

import WorkExperienceCategoriesList from '../contents/workExperienceCategories/List';
import WorkExperienceCategoriesDetails from '../contents/workExperienceCategories/Details';
import WorkExperienceCategoriesCreate from '../contents/workExperienceCategories/Create';

import AgeCategoriesList from '../contents/ageCategories/List';
import AgeCategoriesDetails from '../contents/ageCategories/Details';
import AgeCategoriesCreate from '../contents/ageCategories/Create';

import PriceCategoriesList from '../contents/priceCategories/List';
import PriceCategoriesDetails from '../contents/priceCategories/Details';
import PriceCategoriesCreate from '../contents/priceCategories/Create';

import FaqList from '../contents/faqs/List';
import FaqDetails from '../contents/faqs/Details';
import FaqCreate from '../contents/faqs/Create';

import ReviewList from '../contents/reviews/List';

import TutorCVList from '../contents/tutorCvs/List';

import StudentList from '../contents/students/List';
import StudentDetails from '../contents/students/Details';

import LessonsList from '../contents/lessons/List';
import LessonDetails from '../contents/lessons/Details';

import WithdrawalList from '../contents/withdrawals/List';

import PackageCancellationList from '../contents/packageCancellations/List';

import ProtectRoute from './ProtectRoute';
import MainDashboard from '../contents/dashboard/MainDashboard';
import StatsByTutor from '../contents/dashboard/StatsByTutor';
import StatsByTariff from '../contents/dashboard/StatsByTariff';
import StatsBySubject from '../contents/dashboard/StatsBySubject';
import TutorsWithdrawals from '../contents/dashboard/TutorsWithdrawals';
import PaymentStats from '../contents/dashboard/PaymentStats';

import PackageCancellationRequestReasonsList from '../contents/packageCancellationRequestReasons/List';
import PackageCancellationRequestReasonsDetails from '../contents/packageCancellationRequestReasons/Details';
import PackageCancellationRequestReasonsCreate from '../contents/packageCancellationRequestReasons/Create';

import SuccessStoriesList from '../contents/successStories/List';

import SocialLinkList from '../contents/socialsLinks/List';

import ContactDetails from '../contents/contacts/Details';

import LegalDocumentList from '../contents/legalDocuments/List';
import LegalDocumentDetails from '../contents/legalDocuments/Details';

import NotificationList from '../contents/notifications/List';
import NotificationDetails from '../contents/notifications/Details';

import SettingList from '../contents/settings/List';
import StatsByPackageCancellations from '../contents/dashboard/StatsByPackageCancellations';
import DebitsByTutor from '../contents/dashboard/DebitsByTutor';
import LessonMovementsByTutor from '../contents/dashboard/LessonMovementsByTutor';
import AccountSettings from '../contents/settings/AccountSettings';

import VacanciesList from '../contents/vacancies/List';
import VacanciesDetails from '../contents/vacancies/Details';
import VacanciesCreate from '../contents/vacancies/Create';

import TutorStoryList from '../contents/tutorStories/List';
import TutorStoryDetails from '../contents/tutorStories/Details';
import TutorStoryCreate from '../contents/tutorStories/Create';

import StaffList from '../contents/staff/List';
import TrialLessonsList from '../contents/trialLessons/TrialLessonsList';
import TutorWorkTimesList from '../contents/tutorWorkTimes/TutorWorkTimesList';
import UsersNotFinishedRegistrationList
  from '../contents/usersNotFinishedRegistration/UsersNotFinishedRegistrationList';
import BookkeepingList from '../contents/bookkeeping/BookkeepingList';
import BookkeepingDetails from '../contents/bookkeeping/BookkeepingDetails';
import BookkeepingCreation from '../contents/bookkeeping/BookkeepingCreation';
import VideoAboutPlatformDetails from '../contents/videoAboutPlatform/Details';

import SmsToPlaymobileList from '../contents/smsToPlayMobile/List';
import ScheduleLogsList from '../contents/scheduleLogs/List';
import LessonSchedulesList from '../contents/lessonSchedules/List';

export default createBrowserRouter([
  {
    path: routes.root(),
    element: <Entry />,
    errorElement: <ErrorPage />
  },
  {
    path: routes.app(),
    element: <ProtectRoute><Application /></ProtectRoute>,
    errorElement: <Application />,
    children: [
      {
        path: routes.welcome(),
        element: <Welcome />,
      },
      {
        path: routes.teachers(),
        element: <TeacherList />
      },
      {
        path: routes.teachers(':id'),
        element: <TeacherDetails />
      },
      {
        path: routes.reviews(),
        element: <ReviewList />
      },
      {
        path: routes.reviews(':id'),
        element: <TeacherDetails />
      },
      {
        path: routes.tariffs(),
        element: <TariffList />
      },
      {
        path: routes.tariffs('create'),
        element: <TariffCreate />
      },
      {
        path: routes.tariffs(':id'),
        element: <TariffDetails />
      },
      {
        path: routes.faqs(),
        element: <FaqList />
      },
      {
        path: routes.faqs('create'),
        element: <FaqCreate />
      },
      {
        path: routes.faqs(':id'),
        element: <FaqDetails />
      },
      {
        path: routes.subjects(),
        element: <SubjectList />
      },
      {
        path: routes.subjects('create'),
        element: <SubjectCreate />
      },
      {
        path: routes.subjects(':id'),
        element: <SubjectDetails />
      },
      {
        path: routes.languages(),
        element: <LanguageList />
      },
      {
        path: routes.languages(':id'),
        element: <LanguageDetails />
      },
      {
        path: routes.languages('create'),
        element: <LanguageCreate />
      },
      {
        path: routes.languageLevels(),
        element: <LanguageLevelList />
      },
      {
        path: routes.languageLevels(':id'),
        element: <LanguageLevelDetails />
      },
      {
        path: routes.languageLevels('create'),
        element: <LanguageLevelCreate />
      },
      {
        path: routes.educationLevels(),
        element: <EducationLevelList />
      },
      {
        path: routes.educationLevels(':id'),
        element: <EducationLevelDetails />
      },
      {
        path: routes.educationLevels('create'),
        element: <EducationLevelCreate />
      },
      {
        path: routes.educationInstitution(),
        element: <EducationInstitutionList />
      },
      {
        path: routes.educationInstitution(':id'),
        element: <EducationInstitutionDetails />
      },
      {
        path: routes.educationInstitution('create'),
        element: <EducationInstitutionCreate />
      },
      {
        path: routes.learningGoals(),
        element: <LearningGoalList />
      },
      {
        path: routes.learningGoals(':id'),
        element: <LearningGoalDetails />
      },
      {
        path: routes.learningGoals('create'),
        element: <LearningGoalCreate />
      },
      {
        path: routes.qualifications(),
        element: <QualificationList />
      },
      {
        path: routes.qualifications(':id'),
        element: <QualificationDetails />
      },
      {
        path: routes.qualifications('create'),
        element: <QualificationCreate />
      },
      {
        path: routes.workExperienceCategories(),
        element: <WorkExperienceCategoriesList />
      },
      {
        path: routes.workExperienceCategories(':id'),
        element: <WorkExperienceCategoriesDetails />
      },
      {
        path: routes.workExperienceCategories('create'),
        element: <WorkExperienceCategoriesCreate />
      },
      {
        path: routes.ageCategories(),
        element: <AgeCategoriesList />
      },
      {
        path: routes.ageCategories(':id'),
        element: <AgeCategoriesDetails />
      },
      {
        path: routes.ageCategories('create'),
        element: <AgeCategoriesCreate />
      },
      {
        path: routes.priceCategories(),
        element: <PriceCategoriesList />
      },
      {
        path: routes.priceCategories(':id'),
        element: <PriceCategoriesDetails />
      },
      {
        path: routes.priceCategories('create'),
        element: <PriceCategoriesCreate />
      },
      {
        path: routes.packageCancellationRequestsReasons(),
        element: <PackageCancellationRequestReasonsList />
      },
      {
        path: routes.packageCancellationRequestsReasons(':id'),
        element: <PackageCancellationRequestReasonsDetails />
      },
      {
        path: routes.packageCancellationRequestsReasons('create'),
        element: <PackageCancellationRequestReasonsCreate />
      },
      {
        path: routes.tutorCv(),
        element: <TutorCVList />
      },
      {
        path: routes.lessons(),
        element: <LessonsList />
      },
      {
        path: routes.lessons(':id'),
        element: <LessonDetails />
      },
      {
        path: routes.students(),
        element: <StudentList />
      },
      {
        path: routes.students(':id'),
        element: <StudentDetails />
      },
      {
        path: routes.withdrawals(),
        element: <WithdrawalList />
      },
      {
        path: routes.tutorBookkeeping(),
        element: <BookkeepingList />
      },
      {
        path: routes.tutorBookkeeping(':id'),
        element: <BookkeepingDetails />
      },
      {
        path: routes.tutorBookkeepingCreation(),
        element: <BookkeepingCreation />
      },
      {
        path: routes.packageCancellation(),
        element: <PackageCancellationList />
      },
      {
        path: routes.dashboard(),
        element: <MainDashboard />
      },
      {
        path: routes.statsByTutor(),
        element: <StatsByTutor />
      },
      {
        path: routes.statsByTariff(),
        element: <StatsByTariff />
      },
      {
        path: routes.statsBySubject(),
        element: <StatsBySubject />
      },
      {
        path: routes.tutorsWithdrawals(),
        element: <TutorsWithdrawals />,
      },
      {
        path: routes.statsBypackageCancellations(),
        element: <StatsByPackageCancellations />,
      },
      {
        path: routes.debitsByTutor(),
        element: <DebitsByTutor />,
      },
      {
        path: routes.lessonMovementsByTutor(),
        element: <LessonMovementsByTutor />,
      },
      {
        path: routes.payments(),
        element: <PaymentStats />
      },
      {
        path: routes.successStories(),
        element: <SuccessStoriesList />
      },
      {
        path: routes.socialLinks(),
        element: <SocialLinkList />
      },
      {
        path: routes.contacts(),
        element: <ContactDetails />
      },
      {
        path: routes.legalDocuments(),
        element: <LegalDocumentList />
      },
      {
        path: routes.legalDocuments(':id'),
        element: <LegalDocumentDetails />
      },
      {
        path: routes.notifications(),
        element: <NotificationList />
      },
      {
        path: routes.notifications(':id'),
        element: <NotificationDetails />
      },
      {
        path: routes.settings(),
        element: <SettingList />
      },
      {
        path: routes.accountSettings(),
        element: <AccountSettings />
      },
      {
        path: routes.vacancies(),
        element: <VacanciesList />
      },
      {
        path: routes.vacancies('create'),
        element: <VacanciesCreate />
      },
      {
        path: routes.vacancies(':id'),
        element: <VacanciesDetails />
      },
      {
        path: routes.tutorStories(),
        element: <TutorStoryList />
      },
      {
        path: routes.tutorStories(':id'),
        element: <TutorStoryDetails />
      },
      {
        path: routes.tutorStories('create'),
        element: <TutorStoryCreate />
      },
      {
        path: routes.staff(),
        element: <StaffList />
      },
      {
        path: routes.trialLessons(),
        element: <TrialLessonsList />
      },
      {
        path: routes.lessonSchedules(),
        element: <LessonSchedulesList />
      },
      {
        path: routes.tutorWorkTimes(),
        element: <TutorWorkTimesList />
      },
      {
        path: routes.userNotFinishedRegistration(),
        element: <UsersNotFinishedRegistrationList />
      },
      {
        path: routes.videoAboutPlatform(),
        element: <VideoAboutPlatformDetails />
      },
      {
        path: routes.smsToPlaymobile(),
        element: <SmsToPlaymobileList />
      },
      {
        path: routes.scheduleLogs(),
        element: <ScheduleLogsList />
      },
    ],
  }
]);
