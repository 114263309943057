// import cn from 'classnames';

import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePayOneByPaycheckMutation } from '../../store/apis/finances/bookkeeping';

const PayOneBookkeeping = ({
  paycheckId,
}) => {
  const [t] = useTranslation();

  const [
    payOneBookkeeping,
    { isLoading: isPaying },
  ] = usePayOneByPaycheckMutation();

  const handlePay = async () => {
    try {
      await payOneBookkeeping(paycheckId).unwrap();
      message.success(t('Успешно'));
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <Popconfirm
      title={t('Вы уверены')}
      onConfirm={handlePay}
      okButtonProps={{
        loading: isPaying,
      }}
    >
      <Button
        size="small"
        type="link"
      >
        {t('Оплатить')}
      </Button>
    </Popconfirm>
  );
};

export default PayOneBookkeeping;
