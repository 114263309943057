import React from 'react';
import cn from 'classnames';

import styles from './DebitsByTutor.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetDebitsByTutorQuery } from '../../../store/apis/reports/dashboard';
import { Skeleton, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { formatNumber, joinExisting } from '../../../utils';
import ContentHeader from '../../../components/ContentHeader';
import usePermissions from '../../../hooks/usePermissions';

const { Title } = Typography;

const DebitsByTutor = () => {
  const [t] = useTranslation();
  const hasAccess = usePermissions();
  const {
    data: debits,
    isLoading: isDebitsLoading,
    isFetching: isDebitsFetching,
    error: debitsError,
  } = useGetDebitsByTutorQuery();

  const columns = [
    {
      title: t('Учитель'),
      dataIndex: 'id',
      key: 'id',
      render: (id, raw) => hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])
        ? <Link to={routes.teachers(id)}>{joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')}</Link>
        : joinExisting([raw.lastName, raw.firstName, raw.secondName], ' '),
    },
    {
      title: t('Всего оплачено (сум)'),
      dataIndex: 'inputAmount',
      key: 'inputAmount',
      render: (amount) => formatNumber(amount),
    },
    {
      title: t('Снято с учеников (сум)'),
      dataIndex: 'expendAmount',
      key: 'expendAmount',
      render: (amount) => formatNumber(amount),
    },
    {
      title: t('Остаток у учеников (сум)'),
      dataIndex: 'debitAmount',
      key: 'debitAmount',
      render: (amount) => formatNumber(amount),
    },
  ];

  if (isDebitsLoading) return <Skeleton />;
  if (debitsError) return (
    <div>
      <Title>Ой, кажется произошла ошибка</Title>
      <pre>{JSON.stringify(debitsError)}</pre>
    </div>
  );
  if (!debits) return null;

  return (
    <div>
      <ContentHeader title={t('Дебеты по учителям')}/>
      <Table
        loading={isDebitsFetching}
        columns={columns}
        rootClassName="overflow-scroll"
        rowKey="id"
        dataSource={debits.rows}
        pagination={{
          pageSize: 20,
        }}
      />
    </div>
  );
};

export default DebitsByTutor;
