import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { message, Typography } from 'antd';
import {
  useEditTutorStoryMutation,
  useGetTutorStoryDetailsQuery
} from '../../../store/apis/administratives/tutorStories';
import Form from '../Form';

const Details = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const {
    data: tutorStory,
    isLoading: isTutorStoryLoading,
    isFetching: isTutorStoryFetching,
  } = useGetTutorStoryDetailsQuery(id, { skip: !id });

  const [
    editTutorStory,
    {
      isLoading: isEditTutorStoryLoading,
    }
  ] = useEditTutorStoryMutation();

  const handleSubmit = async (values) => {
    try {
      await editTutorStory({ body: values, id });
      message.success(t('Отзыв изменен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  return (
    <div>
      <Typography.Title level={4}>
        {t('Отзыв учителя о платформе')}
      </Typography.Title>
      <Form
        initialValues={tutorStory}
        onSubmit={handleSubmit}
        loading={isTutorStoryLoading || isTutorStoryFetching || isEditTutorStoryLoading}
      />
    </div>
  );
};

export default Details;
