import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getEducationLevels: build.query({
      query: (params) => ({
        url: apiRoutes.educationLevels(),
        params,
      }),
      providesTags: ['EDUCATION_LEVELS'],
    }),
    getEducationLevel: build.query({
      query: (langId) => ({
        url: apiRoutes.educationLevels(langId),
      }),
      providesTags: ['EDUCATION_LEVEL'],
    }),
    editEducationLevel: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.educationLevels(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['EDUCATION_LEVELS', 'EDUCATION_LEVEL'] }),
    }),
    createEducationLevel: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.educationLevels(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['EDUCATION_LEVELS', 'EDUCATION_LEVEL'] }),
    }),
  })
});

export const {
  useGetEducationLevelsQuery,
  useGetEducationLevelQuery,
  useEditEducationLevelMutation,
  useCreateEducationLevelMutation,
} = rootApi;
