import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ApproveReview.module.scss';
import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { useApproveReviewMutation } from '../../../store/apis/administratives/reviews';

const ApproveReview = ({ reviewId }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [approve, { isLoading, isFetching }] = useApproveReviewMutation();

  const approveReview = useCallback(async () => {
    try {
      await approve(reviewId).unwrap();
      message.success(t('Отзыв принят и опубликован'));
      close();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [approve, close, reviewId, t]);

  return (
    <Popconfirm
      open={isOpen}
      title={t('Вы уверены?')}
      description={t('Этот комментарий и оценка будут опубликованы и видны другим учеником')}
      okText={t('Да, опубликовать')}
      cancelText={t('Нет, отменить')}
      onConfirm={approveReview}
      onCancel={close}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
    >
      <Button size="small" type="primary" onClick={open} disabled={!reviewId}>{t('Подтвердить')}</Button>
    </Popconfirm>
  );
};

export default ApproveReview;
