import React, { useEffect } from 'react';
import cn from 'classnames';

import styles from './ChangeRoleForm.module.scss';
import { useTranslation } from 'react-i18next';
import { Form as AntForm, Form, Input, Modal, Select, Typography } from 'antd';
import {
  useChangeStaffPasswordMutation,
  useChangeStaffRoleMutation,
  useStaffDetailsQuery
} from '../../../store/apis/users';
import usePermissions from '../../../hooks/usePermissions';
import { without } from 'ramda';

const { Title } = Typography;


const rolesList = [
  { label: 'Админ', value: 'ADMIN' },
  { label: 'Менеджер', value: 'MANAGER' },
  { label: 'HR-менеджер', value: 'HR' },
  { label: 'Бухгалтер', value: 'ACCOUNTANT' },
];

const ChangeRoleForm = ({
  open,
  id,
  onClose,
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const checkPermissions = usePermissions();

  const {
    data: staffDetails,
    isFetching: isStaffDetailsFetching,
  } = useStaffDetailsQuery(id, { skip: !id });

  const [
    changeRole,
    { isLoading: isChanging }
  ] = useChangeStaffRoleMutation();

  const handleFinish = async (values) => {
    try {
      const body = {
        roles: [values.role],
      }
      await changeRole({ id, body }).unwrap();
      onClose();
      form.resetFields();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={open}
      title={t('Изменить роль пользователя') }
      onOk={form.submit}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      width={600}
      destroyOnClose
      confirmLoading={isChanging}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        wrapperCol={{ sm: 17 }}
        labelCol={{ sm: 7 }}
        disabled={isStaffDetailsFetching}
      >
        <Title level={5}>{t('Текущая роль')}: {staffDetails?.roles[0]?.name}</Title>
        <AntForm.Item name="role" label={t('Роль')} required rules={[{ required: true }]}>
          <Select
            options={
              checkPermissions(['SUPER_ADMIN'])
                ? rolesList
                : rolesList.filter((role) => role.value !== 'ADMIN')
            }
          />
        </AntForm.Item>
      </Form>
    </Modal>
  );
};

export default ChangeRoleForm;
