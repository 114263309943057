import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query({
      query: (params) => ({
        url: apiRoutes.contacts(),
        params,
      }),
      providesTags: ['CONTACTS'],
    }),

    createContacts: build.mutation({
      query: (body) => ({
        url: apiRoutes.contacts(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['CONTACTS'] }),
    }),
    updateContacts: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.contacts(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['CONTACTS'] }),
    }),
  })
});

export const {
  useGetContactsQuery,
  useCreateContactsMutation,
  useUpdateContactsMutation,
} = rootApi;
