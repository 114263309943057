// import cn from 'classnames';

import { Button, Col, Form, message, Row, Space, Typography } from 'antd';
import LanguageInput from '../../../components/LanguagesInput';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEditVideoAboutPlatformMutation,
  useGetVideoAboutPlatformQuery
} from '../../../store/apis/administratives/videoAboutPlatform';

const { Title } = Typography;

const Details = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const {
    data: videoData,
    isFetching: isVideoDataFetching,
  } = useGetVideoAboutPlatformQuery();

  const [
    updateVideoData,
    { isLoading: isUpdatingVideoData },
  ] = useEditVideoAboutPlatformMutation();

  useEffect(() => {
    console.log('works');
    if (videoData) {
      form.setFieldsValue(videoData);
    }
  }, [videoData, form]);

  const handleSubmit = async (values) => {
    try {
      await updateVideoData(values).unwrap();
      message.success(t('Сохранено'))
    } catch (e) {
      console.log(e);
    }
  };

  const isFormDisabled = isVideoDataFetching || isUpdatingVideoData;

  return (
    <div>
      <Title level={3}>{t('Видео о платформе')}</Title>
      <Form
        form={form}
        onFinish={handleSubmit}
        disabled={isFormDisabled}
        labelCol={{
          md: 5,
          lg: 6,
          xl: 4
        }}
      >
        <Form.Item hidden name="coverPath" />
        <Form.Item label={t('Заголовок')}>
          <LanguageInput name="header" />
        </Form.Item>
        <Form.Item label={t('Описание')}>
          <LanguageInput name="description" type="textarea" />
        </Form.Item>
        <Form.Item label={t('Ссылка на видео')}>
          <LanguageInput name="videoUrl" />
        </Form.Item>

        <Row justify="end">
          <Col>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isFormDisabled}
              >
                {t('Сохранить')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Details;
