import React, { useCallback } from 'react';
import { Button, Col, Form as AntForm, Input, Row, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import LanguageInput from '../../../components/LanguagesInput';

const { Item: FormItem } = AntForm;

const DictionaryForm = ({
  initialValues,
  onSubmit,
  loading,
  onExit,
  saveTitle,
  saveAndExitTitle,
  children,
}) => {
  const [t] = useTranslation();
  const [form] = AntForm.useForm();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    await onSubmit(values);
    form.resetFields();
  }, [form, onSubmit]);

  const handleSubmitAndExit = useCallback(async (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    await onSubmit(values);
    onExit();
  }, [form, onExit, onSubmit]);

  return (
    <div>
      <AntForm
        form={form}
        name="subject-form"
        onFinish={onSubmit}
        initialValues={initialValues}
        layout="vertical"
        disabled={loading}
      >
        <FormItem label={t('Активный?')} name="isActive" valuePropName="checked">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </FormItem>
        <FormItem label={t('Системное название')} name="sysName" rules={[{ required: true }]}>
          <Input />
        </FormItem>
        <FormItem hidden name="isActive" />
        <FormItem label={t('Название')} required style={{ marginBottom: 0 }}>
          <LanguageInput name="name" required />
        </FormItem>

        <FormItem label={t('Короткое название')} style={{ marginBottom: 0 }}>
          <LanguageInput name="shortName" />
        </FormItem>


        <FormItem label={t('Описание')} style={{ marginBottom: 0 }}>
          <LanguageInput name="description" />
        </FormItem>

        {children}

        <Row justify="end">
          <Col>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loading}
                onClick={handleSubmitAndExit}
              >
                {saveAndExitTitle}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loading}
                onClick={handleSubmit}
              >
                {saveTitle}
              </Button>
            </Space>
          </Col>
        </Row>
      </AntForm>
    </div>
  );
};

export default DictionaryForm;
