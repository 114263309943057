import { isRejectedWithValue } from '@reduxjs/toolkit';
import { message, Modal } from 'antd';
import i18n from '../../localization/i18n';
import router from '../../router';
import routes from '../../router/routes';
import { removeCredentials } from '../../utils';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (!isRejectedWithValue(action)) return next(action);

  const response = action.payload;

  if (response.status === 500) {
    console.error('Произошла ошибка 500');
  }

  if (response.status === 401) {
    message.error(i18n.t('Срок вашей сессии истек'));
    router.navigate(routes.root());
    removeCredentials();
    return next(action);
  }

  if (response.data) {
    if (response.data.type === 'form') {
      const { details } = response.data;
      message.error(`${response.status} - ${response.data.message}`);
      Modal.error({
        title: i18n.t('Исправьте ошибки'),
        content: (
          <div>
            {details.map(({ name, errors }, i) => (
              <div key={i}>{name}: {errors.join(', ')}</div>
            ))}
          </div>
        ),
      });
      return next(action);
    }
    const { message: errorMessage } = response.data;
    message.error(errorMessage);
    return next(action);
  }
  message.error(`${action.payload.status} - ${action.payload.error}`);

  return next(action);
}
