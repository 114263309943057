import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useCreateSubjectMutation } from '../../../store/apis/dictionaries/subjects';
import { Form as AntForm, message } from 'antd';
import routes from '../../../router/routes';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import Uploader from '../../../containers/shared/Uploader';
import { normFile } from '../../../utils';

const { Item: FormItem } = AntForm;

const Create = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [createSubject, { isFetching: isCreateSubjectFetching, isLoading }] = useCreateSubjectMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await createSubject({ body: values }).unwrap();
      message.success(t('Предмет создан'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [createSubject, t]);

  const handleExit = useCallback(() => {
    navigate(routes.subjects());
  }, [navigate]);

  return (
    <div>
      <ContentHeader title={t('Создать новый предмет')} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{}}
        loading={isCreateSubjectFetching || isLoading}
        saveTitle={t('Сохранить и создать еще')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >
        <FormItem
          label={t('Картинка предмета')}
          name="picturePath"
          getValueFromEvent={normFile}
          required
        >
          <Uploader
            maxCount={1}
            multiple={false}
            group="subjects"
          />
        </FormItem>
      </DictionaryForm>
    </div>
  );
};

export default Create;
