import React, { useCallback, useEffect } from 'react';
import { Button, Col, Form as AntForm, InputNumber, Row, Select, Space, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import LanguageInput from '../../../components/LanguagesInput';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { useGetFaqTagsQuery } from '../../../store/apis/administratives/faqs';

const FormItem = AntForm.Item;

const Form = ({
  initialValues,
  onSubmit,
  loading,
  saveAndResetLabel,
}) => {
  const [t] = useTranslation();
  const [form] = AntForm.useForm();
  const navigate = useNavigate();

  const {
    data: faqTags,
    isLoading: isFaqTagsLoading,
    isFetching: isFaqTagsFetching,
  } = useGetFaqTagsQuery({ page: -1 })

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const handleSubmit = useCallback(async (e) => {
    // console.log(form.getFieldsValue());
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    await onSubmit(values);
    form.resetFields();
  }, [form, onSubmit]);

  const handleSubmitAndExit = useCallback(async (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    await onSubmit(values);
    navigate(routes.faqs());
  }, [form, navigate, onSubmit]);

  return (
    <AntForm
      form={form}
      name="faq-form"
      onFinish={handleSubmit}
      initialValues={initialValues}
      layout="vertical"
      disabled={loading}
    >
      <Space size={60}>
        <FormItem label={t('Опубликован?')} name="isPublished" valuePropName="checked">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </FormItem>
      </Space>

      <FormItem label={t('Вопрос')} required style={{ marginBottom: 0 }}>
        <LanguageInput name="question" required type="textarea" />
      </FormItem>

      <FormItem label={t('Ответ')} required style={{ marginBottom: 0 }}>
        <LanguageInput required name="answer" type="textarea" />
      </FormItem>

      <FormItem label={t('Порядковый номер')} style={{ marginBottom: 0 }} name="orderId">
        <InputNumber prefix="№" />
      </FormItem>

      <FormItem label={t('Отобразить вопрос для')} style={{ marginBottom: 20, marginTop: 20 }} name="tags">
        <Select
          showSearch
          mode="multiple"
          placeholder={t('Выберите для кого нужно отобразить этот вопрос')}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isFaqTagsLoading || isFaqTagsFetching}
          options={faqTags?.results.map((d) => ({
            value: d.id,
            label: d.sysName,
          }))}
        />
      </FormItem>

      <FormItem label={t('Ссылка на видео')} style={{ marginBottom: 0 }}>
        <LanguageInput name="explanationVideo" />
      </FormItem>

      <Row justify="end">
        <Col>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              onClick={handleSubmitAndExit}
            >
              {t('Сохранить и выйти')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              onClick={handleSubmit}
            >
              {saveAndResetLabel ? saveAndResetLabel : t('Сохранить')}
            </Button>
          </Space>
        </Col>
      </Row>
    </AntForm>
  );
};

export default Form;
