import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { message, Skeleton } from 'antd';
import MainError from '../../errors/MainError';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import { useEditLanguageMutation, useGetLanguageQuery } from '../../../store/apis/dictionaries/languages';

const Details = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editLanguage, { isLoading: isEditingLanguage }] = useEditLanguageMutation();

  const {
    data: language,
    isLoading: isLanguageLoading,
    isFetching: isLanguageFetching,
    error: languageError,
  } = useGetLanguageQuery(id);

  const handleSubmit = useCallback(async (body) => {
    try {
      await editLanguage({ id, body }).unwrap();
      message.success(t('Язык сохранен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [editLanguage, id, t]);

  const handleExit = useCallback(() => {
    navigate(routes.languages());
  }, [navigate]);

  if (isLanguageLoading || isLanguageFetching) return <Skeleton />
  if (languageError) return <MainError error={languageError} />

  return (
    <div>
      <ContentHeader title={language.sysName} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={language}
        loading={isEditingLanguage}
        saveTitle={t('Сохранить')}
        saveAndExitTitle={t('Сохранить и выйти')}
      />
    </div>
  );
};

export default Details;
