import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getNotificationList: build.query({
      query: (params) => ({
        url: apiRoutes.notifications(),
        params,
      }),
      providesTags: ['NOTIFICATIONS'],
    }),
    getNotificationDetails: build.query({
      query: (id) => ({
        url: apiRoutes.notifications(id),
      }),
      providesTags: ['NOTIFICATION_DETAILS'],
    }),
    getDeliveryMethods: build.query({
      query: (params) => ({
        url: apiRoutes.deliveryMethods(),
        params,
      }),
      providesTags: ['DELIVERY_METHODS'],
    }),

    updateNotification: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.notifications(id),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['NOTIFICATIONS', 'NOTIFICATION_DETAILS'] })
    }),
    activateDeliveryMethodForNotification: build.mutation({
      query: ({ typeId, body }) => ({
        url: apiRoutes.activateDeliveryMethodForNotification(typeId),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['NOTIFICATIONS', 'NOTIFICATION_DETAILS'] })
    }),
    deactivateDeliveryMethodForNotification: build.mutation({
      query: ({ typeId, body }) => ({
        url: apiRoutes.deactivateDeliveryMethodForNotification(typeId),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['NOTIFICATIONS', 'NOTIFICATION_DETAILS'] })
    }),

    activateDeliveryMethodGlobally: build.mutation({
      query: (id) => ({
        url: apiRoutes.activateDeliveryMethodGlobally(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['DELIVERY_METHODS', 'NOTIFICATIONS', 'NOTIFICATION_DETAILS'] })
    }),
    deactivateDeliveryMethodForGlobally: build.mutation({
      query: (id) => ({
        url: apiRoutes.deactivateDeliveryMethodGlobally(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['DELIVERY_METHODS', 'NOTIFICATIONS', 'NOTIFICATION_DETAILS'] })
    }),
  })
});

export const {
  useGetNotificationListQuery,
  useGetNotificationDetailsQuery,
  useGetDeliveryMethodsQuery,
  useUpdateNotificationMutation,
  useActivateDeliveryMethodForNotificationMutation,
  useDeactivateDeliveryMethodForNotificationMutation,
  useActivateDeliveryMethodGloballyMutation,
  useDeactivateDeliveryMethodForGloballyMutation,
} = rootApi;
