import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Form, message, Select } from 'antd';
import routes from '../../../router/routes';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useCreateLearningGoalMutation } from '../../../store/apis/dictionaries/learning-goals';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import { dictionaryToSelect } from '../../../utils';
import { useCreateQualificationMutation } from '../../../store/apis/dictionaries/qualifications';

const Create = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [create, { isFetching, isLoading }] = useCreateQualificationMutation();
  const { data: subjects, isLoading: isSubjectsLoading } = useGetSubjectsQuery({ page: -1 });

  const handleSubmit = useCallback(async (values) => {
    try {
      await create({ body: values }).unwrap();
      message.success(t('Квалификация создана'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [create, t]);

  const handleExit = useCallback(() => {
    navigate(routes.qualifications());
  }, [navigate]);

  return (
    <div>
      <ContentHeader title={t('Создать новую квалификацию')} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{}}
        loading={isFetching || isLoading}
        saveTitle={t('Сохранить и создать еще')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >
        <Form.Item name="subject" label={t('Предмет')}>
          <Select
            showSearch
            placeholder={t('Выберите предмет')}
            loading={isSubjectsLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={dictionaryToSelect(subjects?.results)}
          />
        </Form.Item>
      </DictionaryForm>
    </div>
  );
};

export default Create;
