import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { Button, Form, Input, message, Modal, Typography } from 'antd';
import { useChangeTutorPhoneMutation } from '../../../store/apis/administratives/tutors';

const FormItem = Form.Item;

const ChangePhoneForTutor = ({ tutorId }) => {
  const [t] = useTranslation();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const [form] = Form.useForm();

  const [
    changePhone,
    {
      isLoading,
      isFetching,
    }
  ] = useChangeTutorPhoneMutation();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();

      await changePhone({ tutorId, body: values }).unwrap();
      message.success(t('Телефон изменен'));
      hideModal();
      form.resetFields();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, changePhone, tutorId, t, hideModal]);

  return (
    <>
      <Button
        block
        type="primary"
        onClick={showModal}
      >
        {t('Сменить телефон')}
      </Button>
      <Modal
        open={isModalOpen}
        title={t('Сменить телефон учителю')}
        okText={t('Сменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isLoading || isFetching}
      >
        <Form
          form={form}
          disabled={isLoading || isFetching}
          layout="vertical"
          name="tutor_phone_form"
        >
          <FormItem
            name="phoneNumber"
            label={t('Новый телефон')}
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
          <Typography.Text>Формат: 998909995533</Typography.Text>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePhoneForTutor;
