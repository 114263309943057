import { createApi } from '@reduxjs/toolkit/query/react';
import apiRoutes, { baseUrl } from '../../apiRoutes';
import { baseQuery } from '../utils';


export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: baseQuery(baseUrl),
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: apiRoutes.login(),
        method: 'POST',
        body,
      }),
    })
  })
});

export const {
  useLoginMutation,
} = authApi;
