import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './AccountSettings.module.scss';
import { useGetAccountSettingsQuery, usePatchAccountSettingsMutation } from '../../../store/apis/settings';
import { Button, Form, InputNumber, message, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ContentHeader from '../../../components/ContentHeader';
import usePermissions from '../../../hooks/usePermissions';

const { Title } = Typography;
const FormItem = Form.Item;

const AccountSettings = () => {
  const [t] = useTranslation();
  const hasAccess = usePermissions();
  const {
    data: settings,
    isLoading: isSettingsLoading,
    isFetching: isSettingsFetching,
    error: settingsError,
  } = useGetAccountSettingsQuery();

  const [
    patchSettings,
    {
      isLoading: isPatchSettingsLoading,
      isFetching: isPatchSettingsFetching,
    }
  ] = usePatchAccountSettingsMutation();

  const onFinish = useCallback(async (values) => {
    if (!hasAccess(['SUPER_ADMIN'])) return;
    try {
      await patchSettings(values).unwrap();
      message.success(t('Настройки сохранены'));
    } catch (e) {
      console.error(e);
    }
  }, [patchSettings, t]);

  if (isSettingsLoading) return <Skeleton />;
  if (settingsError) return (
    <div>
      <Title>Ой, кажется произошла ошибка</Title>
      <pre>{JSON.stringify(settingsError, 0, 2)}</pre>
    </div>
  );

  return (
    <div>
      <ContentHeader title={t('Настройки доступа')} />
      <Form
        onFinish={onFinish}
        layout="horizontal"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 8 }}
        initialValues={settings}
        disabled={
          isSettingsFetching
            || isPatchSettingsLoading
            || isPatchSettingsFetching
            || !hasAccess(['SUPER_ADMIN'])
        }
      >
        <FormItem
          name="accessTokenTime"
          label={t('Срок сессии до обновления')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('секунд')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="refreshToken"
          label={t('Срок ключа для обновления')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('секунд')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="sessionCount"
          label={t('Количество одновременных сессий')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('сессий')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="otpCodeTimeAlive"
          label={t('Срок действия кода, подтверждающего номер телефона')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('секунд')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="limitOtpCodesPerHour"
          label={t('Лимит кодов, подтверждающих номер телефона (в час)')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('шт')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        {
          hasAccess(['SUPER_ADMIN']) && (
            <FormItem wrapperCol={{ offset: 9, span: 8 }}>
              <Button type="primary" htmlType="submit" block loading={isPatchSettingsLoading || isPatchSettingsFetching}>
                {t('Сохранить')}
              </Button>
            </FormItem>
          )
        }
      </Form>
    </div>
  );
};

export default AccountSettings;
