import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ChangePasswordForTutor.module.scss';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { Button, Form, Input, InputNumber, message, Modal } from 'antd';
import { useChangeTutorPasswordMutation } from '../../../store/apis/administratives/tutors';

const FormItem = Form.Item;

const ChangePasswordForTutor = ({ tutorId }) => {
  const [t] = useTranslation();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const [form] = Form.useForm();

  const [
    changePassword,
    {
      isLoading,
      isFetching,
    }
  ] = useChangeTutorPasswordMutation();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();

      await changePassword({ tutorId, body: values }).unwrap();
      message.success(t('Пароль изменен'));
      hideModal();
      form.resetFields();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, changePassword, tutorId, t, hideModal]);

  return (
    <>
      <Button
        block
        type="primary"
        onClick={showModal}
      >
        {t('Сменить пароль')}
      </Button>
      <Modal
        open={isModalOpen}
        title={t('Сменить пароль учителю')}
        okText={t('Сменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isLoading || isFetching}
      >
        <Form
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={isLoading || isFetching}
          layout="vertical"
          name="tutor_price_form"
        >
          <FormItem
            name="password"
            label={t('Пароль')}
            rules={[{ required: true }]}
          >
            <Input.Password />
          </FormItem>
          <FormItem
            name="confirmPassword"
            label={t('Повторите пароль')}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Пароли не совпадают!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordForTutor;
