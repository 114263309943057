import React from 'react';

import styles from './Details.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetTutorDetailsQuery, useGetTutorPriceQuery } from '../../../store/apis/administratives/tutors';
import { useParams } from 'react-router';
import { Affix, Badge, Card, Col, Empty, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { formatDate, formatNumber, joinExisting } from '../../../utils';
import ReactPlayer from 'react-player';
import LabelData from '../../../components/LabelData';
import MainError from '../../errors/MainError';
import PersonalTeacherDataForm from '../../../containers/teacher/PersonalTeacherDataForm';
import EducationTeacherForm from '../../../containers/teacher/EducationTeacherForm';
import CertificatesTeacherForm from '../../../containers/teacher/CertificatesTeacherForm';
import WordExperienceTeacherForm from '../../../containers/teacher/WorkExperienceTeacherForm';
import LanguageSkillTeacherForm from '../../../containers/teacher/LanguageSkillTeacherForm';
import RemoveEducation from '../../../containers/teacher/RemoveEducation';
import RemoveCertificate from '../../../containers/teacher/RemoveCertificate';
import RemoveWorkExperience from '../../../containers/teacher/RemoveWorkExperience';
import RemoveLanguageSkill from '../../../containers/teacher/RemoveLanguageSkill';
import TeacherPriceForm from '../../../containers/teacher/TeacherPriceForm';
import ToggleTutorProfilePublicity from '../../../containers/teacher/ToggleTutorProfilePublicity';
import { green } from '@ant-design/colors';
import ChangePasswordForTutor from '../../../containers/teacher/ChangePasswordForTutor';
import ChangePhoneForTutor from '../../../containers/teacher/ChangePhoneForTutor';
import UpdateTutorInitialRate from '../../../containers/teacher/UpdateTutorInitialRate';

const { Text, Title } = Typography;

const Details = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();
  const { data: tutor, isLoading, isFetching, error } = useGetTutorDetailsQuery(id);
  const { data: tutorPrice, isLoading: isTutorPriceLoading, isFetching: isTutorPriceFetching } = useGetTutorPriceQuery({ tutorId: tutor?.id }, { skip: !tutor });

  if (error) return <MainError error={error} />
  if (isLoading || isFetching || isTutorPriceLoading || isTutorPriceFetching) return <Skeleton loading />

  const {
    subjects,
    learningGoals,
    educations,
    certificates,
    workExperiences,
    languageSkills,
  } = tutor;

  return (
    <div className={styles.tutor}>
      <Title level={2}>
        {joinExisting([
          tutor.lastName?.[i18n.language],
          tutor.firstName?.[i18n.language],
          tutor.secondName?.[i18n.language],
        ], ' ')}
        {' '}
        <Text type="secondary">
          {tutor.id}
        </Text>
      </Title>
      <Row gutter={12} align="top">
        <Col xs={24} md={8} xl={6} xxl={5}>
          <div>
            <Card>
              <Title level={3} style={{ textAlign: 'center', color: green.primary }}>
                {tutor.isPromote ? t('Продвигается') : null}
              </Title>
              <div className={styles.tutorPhoto}>
                <img src={tutor.photoUrl} alt=""/>
              </div>
              <LabelData label={t('Состояние')} data={<Tag color={tutor.isPublished ? 'green' : 'red'}>{tutor.isPublished ? 'Активный' : 'Неактивный'}</Tag>} />
              <LabelData label={t('Номер телефона')} data={tutor.phoneNumber} />
              <LabelData label={t('Дата добавления в систему')} data={formatDate(tutor.createdTime)} />
              <LabelData
                label={t('Рейтинг публичный')}
                data={`${tutor.rating ?? 0} (${tutor.reviewCount || 0})`}
              />
              <LabelData
                label={t('Рейтинг пользователей')}
                data={`${tutor.accumulatedRating ?? 0} (${tutor.accumulatedReviewCount || 0})`}
              />
              <LabelData
                label={t('Рейтинг изначальный')}
                data={`${tutor.initialRating ?? 0} (${tutor.initialReviewCount || 0})`}
              />
              <LabelData
                label={t('Проведено уроков публичный')}
                data={tutor.completedLessonCount}
              />
              <LabelData
                label={t('Проведено уроков пользователям')}
                data={tutor.accumulatedCompletedLessonCount}
              />
              <LabelData
                label={t('Проведено уроков изначально')}
                data={tutor.initialCompletedLessonCount}
              />
              <LabelData
                label={t('Цена за час')}
                data={formatNumber(tutorPrice.pricePerLesson, t('сум'))}
              />
              <LabelData
                label={t('Отчисления за урок')}
                data={
                  tutorPrice.tutorRevenuePercent
                    ? formatNumber(tutorPrice.tutorRevenuePercent, '%')
                    : formatNumber(tutorPrice.tutorFixIncomePerLesson, 'сум')
                }
              />
            </Card>
            <div className={styles.tutorActions}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <TeacherPriceForm tutorId={tutor.id} initialValues={tutorPrice} />
                <ToggleTutorProfilePublicity tutorId={tutor.id} isPublished={tutor.isPublished} />
                <ChangePhoneForTutor tutorId={tutor.id} />
                <UpdateTutorInitialRate tutorId={tutor.id} />
                <ChangePasswordForTutor tutorId={tutor.id} />
              </Space>
            </div>
          </div>
        </Col>

        <Col xs={24} md={16} xl={18} xxl={19}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Card
              title={t('Презентационные данные')}
              type="inner"
              extra={
                <PersonalTeacherDataForm initialValues={tutor} mode="edit" />
              }
            >
              <LabelData label={t('Дата рождения')} data={formatDate(tutor.birthdate)} />
              <LabelData label={t('Обучает с')} data={formatDate(tutor.teachingSinceDate)} />
              <LabelData
                label={t('Предметы обучения')}
                data={
                  (subjects && subjects.length > 0)
                    ? subjects.map((subject) => (
                      <Tag color="blue" key={subject.id}>{subject.name[i18n.language]}</Tag>
                    )) : null
                }
              />
              <LabelData
                label={t('Цели обучения')}
                data={
                  (learningGoals && learningGoals.length > 0)
                    ? learningGoals.map((learningGoal) => (
                      <Tag color="blue" key={learningGoal.id}>{learningGoal.name[i18n.language]}</Tag>
                    )) : null
                }
              />
              <LabelData
                label={t('Приветственный текст')}
                data={tutor.introductionText?.[i18n.language]}
              />
              <LabelData
                label={t('Приветственное видео')}
                data={
                  <ReactPlayer
                    controls
                    url={tutor.introductionVideoUrl}
                    width="320px"
                    height="180px"
                  />
                }
              />
            </Card>
            <Card
              type="inner"
              title={t('Образование')}
              extra={
                <EducationTeacherForm tutorId={tutor.id} />
              }
            >
              {
                (educations && educations.length > 0)
                  ? educations.map((education) => (
                    <LabelData
                      key={education.id}
                      label={joinExisting([education.fromYear, education.toYear], ' - ')}
                      extra={
                      <>
                        <EducationTeacherForm
                          tutorId={tutor.id}
                          initialValues={education}
                          mode="edit"
                        />
                        <RemoveEducation id={education.id} />
                      </>
                      }
                      data={
                        joinExisting([
                          education.educationInstitution?.name[i18n.language],
                          education.educationLevel?.name[i18n.language],
                          education.speciality?.[i18n.language],
                        ], ', ')
                      }
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
            <Card
              type="inner"
              title={t('Сертификаты')}
              extra={
                <CertificatesTeacherForm tutorId={tutor.id} />
              }
            >
              {
                (certificates && certificates.length > 0)
                  ? certificates.map((certificate) => (
                    <LabelData
                      key={certificate.id}
                      label={certificate.year}
                      extra={
                        <>
                          <CertificatesTeacherForm
                            tutorId={tutor.id}
                            mode="edit"
                            initialValues={certificate}
                          />
                          <RemoveCertificate id={certificate.id} />
                        </>
                      }
                      data={
                        joinExisting([
                          certificate.issuer?.[i18n.language],
                          certificate.name?.[i18n.language],
                          certificate.score,
                        ], ', ')
                      }
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
            <Card
              type="inner"
              title={t('Опыт работы')}
              extra={
                <WordExperienceTeacherForm tutorId={tutor.id} />
              }
            >
              {
                (workExperiences && workExperiences.length > 0)
                  ? workExperiences.map((workExperience) => (
                    <LabelData
                      key={workExperience.id}
                      label={`${workExperience.fromYear} - ${workExperience.toYear}`}
                      extra={
                        <>
                          <WordExperienceTeacherForm
                            tutorId={tutor.id}
                            initialValues={workExperience}
                            mode="edit"
                          />
                          <RemoveWorkExperience id={workExperience.id} />
                        </>
                      }
                      data={
                        joinExisting([
                          workExperience.place?.[i18n.language],
                          workExperience.position?.[i18n.language],
                        ], ', ')
                      }
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
            <Card
              type="inner"
              title={t('Владение языками')}
              extra={
                <LanguageSkillTeacherForm tutorId={tutor.id} />
              }
            >
              {
                (languageSkills && languageSkills.length > 0)
                  ? languageSkills.map((languageSkill) => (
                    <LabelData
                      key={languageSkill.id}
                      label={languageSkill.language?.name?.[i18n.language]}
                      extra={
                        <>
                          <LanguageSkillTeacherForm
                            tutorId={tutor.id}
                            mode="edit"
                            initialValues={languageSkill}
                          />
                          <RemoveLanguageSkill id={languageSkill.id} />
                        </>
                      }
                      data={languageSkill.languageSkillLevel?.name[i18n.language]}
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
