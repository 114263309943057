import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Col, Row, Skeleton, Table } from 'antd';
import { useGetFaqsQuery } from '../../../store/apis/administratives/faqs';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { sort } from 'ramda';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const navigate = useNavigate();

  const { data: faqs, isFetching: isFaqsFetching, isLoading: isFaqsLoading } = useGetFaqsQuery({ page, pageSize });

  const handleNavigateToCreate = useCallback(() => {
    navigate(routes.faqs('create'));
  }, [navigate]);

  const handlePageChange = useCallback((page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ ...restQuery, page: page.current, pageSize: page.pageSize }),
    });
  }, [navigate, restQuery]);

  const columns = useMemo(() => [
    {
      title: t('Порядковый номер'),
      dataIndex: 'orderId',
      key: 'id',
    },
    {
      title: t('Вопрос'),
      dataIndex: 'question',
      key: 'question',
      render: (value, raw) => (
        value
          ? <Link to={routes.faqs(raw.id)}>{value[i18n.language]}</Link>
          : '-'
      ),
    },
    {
      title: t('Ответ'),
      dataIndex: 'answer',
      key: 'answer',
      render: (value) => value ? value[i18n.language] : '-',
    },
    {
      title: t('Опубликован?'),
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (value) => value ? '✅' : '❌',
    },
  ], [i18n.language, t]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Вопросы и ответы')}>
          <Button type="primary" onClick={handleNavigateToCreate}>
            {t('Создать')}
          </Button>
        </ContentHeader>
      </div>
      <Row>
        <Col md={24}>
          {
            (isFaqsFetching || isFaqsLoading)
              ? <Skeleton loading />
              : (
                <Table
                  rootClassName="overflow-scroll"
                  loading={isFaqsLoading || isFaqsFetching}
                  columns={columns}
                  rowKey="id"
                  dataSource={
                    faqs?.results
                      ? sort((a, b) => a.orderId - b.orderId, faqs.results)
                      : []
                  }
                  onChange={handlePageChange}
                  pagination={{
                    total: faqs?.count,
                    current: page,
                    locale: { items_per_page: `/ ${t('на странице')}` },
                    pageSize,
                    showSizeChanger: true,
                    showTitle: true,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              )
          }
        </Col>
      </Row>
    </div>
  );
};

export default List;
