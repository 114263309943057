import React, { useState } from 'react';
import cn from 'classnames';

import styles from './AnnulLessonModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, message, Popconfirm } from 'antd';
import { useAnnulLessonMutation } from '../../../store/apis/finances/lessons';

const AnnulLessonModal = ({ lessonId }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [
    annulLesson,
    {
      isLoading,
      isFetching,
    },
  ] = useAnnulLessonMutation();

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    try {
      await annulLesson(lessonId).unwrap();
      message.success(t('Урок аннулирован'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title={t('Вы уверены что хотите аннулировать урок?')}
      description={t('Списанные у студента деньги будут ему возвращены')}
      open={open}
      onConfirm={handleOk}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
      okText={t('Да, аннулировать')}
      cancelText={t('Нет, отменить')}
      onCancel={handleCancel}
    >
      <Button
        type="primary"
        danger
        onClick={showPopconfirm}
      >
        {t('Аннулировать урок')}
      </Button>
    </Popconfirm>
  );
};

export default AnnulLessonModal;
