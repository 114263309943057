import React, { useEffect, useState } from 'react';
import {
  useGetStudentClientCardCommentsQuery,
  useGetStudentDetailsQuery,
  usePostClientCardCommentMutation,
  useUpdateClientCardMutation
} from '../../../store/apis/administratives/students';
import { useParams } from 'react-router';
import { Button, Col, DatePicker, Form, Input, List, message, Row, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import dayjs from 'dayjs';
import { useStaffListQuery } from '../../../store/apis/users';
import { find, propEq } from 'ramda';

const { Title, Text } = Typography;

const ContactWithStudent = () => {
  const [t] = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [contactDate, setContactDate] = useState(null);

  const {
    data: student,
    isFetching: isStudentFetching,
    error,
  } = useGetStudentDetailsQuery(id, { skip: !id });

  const {
    data: staffData,
    isLoading,
    isFetching,
  } = useStaffListQuery({ page: -1 });

  const [
    updateClientCard,
    { isLoading: isClientCardUpdating }
  ] = useUpdateClientCardMutation();

  useEffect(() => {
    if (student?.clientCard?.nextContactDate) {
      setContactDate(dayjs(student.clientCard.nextContactDate));
    }
  }, [student]);

  const [
    postClientCardComment,
    { isLoading: isPostingComment },
  ] = usePostClientCardCommentMutation();

  const {
    data: comments,
    isFetching: isCommentsFetching,
  } = useGetStudentClientCardCommentsQuery({ id }, { skip: !id });

  const submitComment = async (values) => {
    try {
      await postClientCardComment({ id, body: values }).unwrap();
      form.resetFields();
      message.success(t('Комментарий создан'));
    } catch (e) {
      console.log(e);
    }
  };

  const changeContactDate = async (d) => {
    try {
      await updateClientCard({ id, body: { nextContactDate: d.format('YYYY-MM-DD') } }).unwrap();
      message.success(t('Дата следующего контакта изменена'));
      setContactDate(d);
    } catch (e) {

    }
  };

  const getAuthorFullName = (id) => {
    if (!staffData) return id;
    const user = find(propEq(id, 'id'))(staffData.results);
    if (!user) return id;
    return [user.lastName, user.firstName, user.secondName].join(' ');
  }

  return (
    <div>
      <Row>
        <Col md={12}>
          <Form onFinish={submitComment} layout="vertical" disabled={isPostingComment} form={form}>
            <Form.Item label={t('Комментарий')} name="text">
              <Input.TextArea/>
            </Form.Item>
            <Button htmlType="submit" type="primary" block loading={isPostingComment}>
              {t('Сохранить комментарий')}
            </Button>
          </Form>
        </Col>
        <Col md={12}>
          <div style={{ textAlign: 'right' }}>
            <Text style={{ marginBottom: 0, marginTop: 0 }}>
              {t('Запланированный контакт с пользователем')}:
              <br/>
              <b>
                <DatePicker
                  value={contactDate}
                  format="DD.MM.YYYY"
                  onChange={changeContactDate}
                  placeholder={t('Выберите дату')}
                />
                {' '}
                {
                  student.clientCard?.nextContactDate
                    ? dayjs(student.clientCard.nextContactDate).fromNow()
                    : null
                }
              </b>
            </Text>
          </div>
        </Col>
      </Row>
      <Spin spinning={isCommentsFetching}>
        <List
          header={<Title level={4} style={{ marginBottom: 0 }}>{t('Комментарии')}</Title>}
          dataSource={comments}
          itemLayout="vertical"
          renderItem={(item) => (
            <List.Item key={item.id}>
              <Space>
                <Title level={5} style={{ marginBottom: 0 }}>{getAuthorFullName(item.createdBy)}</Title>
                <Text type="secondary">{formatDate(item.createdTime, true)}</Text>
              </Space>
              <div>
                {item.text}
              </div>
            </List.Item>
          )}
        />
      </Spin>
    </div>
  );
};

export default ContactWithStudent;
