import apiRoutes from '../../../apiRoutes';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLessonSchedules: build.query({
      query: (params) => ({
        url: apiRoutes.lessonSchedules(),
        params,
      }),
      providesTags: ['LESSON_SCHEDULES'],
    }),
  })
});

export const {
  useGetLessonSchedulesQuery,
} = rootApi;
