import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useGetStatsByTariffQuery } from '../../../store/apis/reports/dashboard';
import { formatNumber, joinExisting } from '../../../utils';
import { Link } from 'react-router-dom';
import { Col, DatePicker, Row, Skeleton, Space, Table, Typography } from 'antd';
import routes from '../../../router/routes';

const { Title, Text } = Typography;

const StatsByTariff = () => {
  const [t, i18n] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);

  const {
    data: stat,
    isLoading,
    isFetching,
  } = useGetStatsByTariffQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const columns = useMemo(() => [
    {
      title: t('Тариф'),
      dataIndex: 'name',
      key: 'name',
      render: (data, raw) => <Link to={routes.tariffs(raw.id)}>{data[i18n.language]}</Link>,
    },
    {
      title: t('Куплено'),
      dataIndex: 'packageCount',
      key: 'packageCount',
      render: (data) => data ?? 0,
    },
  ], [t, i18n]);

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  if (isLoading || isFetching) return <Skeleton loading />

  return (
    <div>
      <Row style={{ marginBottom: 32 }}>
        <Col>
          <Title level={3}>{t('Статистика по тарифам')}</Title>
          <Space>
            <Text>{t('Статистика за период')}</Text>
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              onChange={changeFilterDate}
              value={lessonDateDiapason}
              allowClear={false}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={stat.rows}
            rowKey="id"
            pagination={{
              pageSize: 50,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StatsByTariff;
