import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import WorkTimeWeekly from '../../../containers/tutorWorkTimes/WorkTimeWeekly';
import { Empty, Spin, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetTutorWorkTimeQuery } from '../../../store/apis/administratives/tutorWorkTime';
import { getDateDiapasonLabel } from '../../../utils';

const { Title } = Typography;

const TutorWorkTimeSlots = ({ tutorId, tutorName }) => {
  const [t, i18n] = useTranslation();

  const {
    data,
    isFetching: isTutorWorkTimesFetching,
  } = useGetTutorWorkTimeQuery(tutorId);

  const tabItems = data?.results.map(({ id, startTime, endTime, timeSpans }) => ({
    label: getDateDiapasonLabel(startTime, endTime),
    key: id,
    children: (
      <div>
        <WorkTimeWeekly value={timeSpans} readOnly />
      </div>
    ),
  }));

  return (
    <Spin spinning={isTutorWorkTimesFetching} size="large">
      <Title level={3}>
        <Link to={routes.teachers(tutorId)}>{tutorName}</Link>
      </Title>
      <div>
        {
          (tabItems && tabItems.length > 0)
            ? (
              <Tabs
                tabPosition="left"
                items={tabItems}
              />
            ) : (
              <Empty description={t('У учителя нет установленного времени работы')}/>
            )
        }
      </div>
    </Spin>
  );
};

export default TutorWorkTimeSlots;
