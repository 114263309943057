import React from 'react';

import styles from './QueryView.module.scss';
import useQueryString from '../../../hooks/useQueryString';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'antd';
import { isEmpty, isNil } from 'ramda';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const QueryView = ({
  label,
  tags,
  onClose,
}) => {
  const [t] = useTranslation();

  const render = () => {
    if (isEmpty(tags) || isNil(tags)) return <Tag>{t('Все')}</Tag>

    return tags.map((tag) => (
      <Tag
        key={tag.id}
        closable
        closeIcon={<CloseCircleOutlined />}
        onClose={() => onClose(tag)}
      >
        {tag.label}
      </Tag>))
  };

  return (
    <div className={styles.query}>
      <Text>{label}: </Text>
      {render()}
    </div>
  );
};

export default QueryView;
