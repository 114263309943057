import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ListFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Drawer, Form, Row, Select, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import { useGetStudentsQuery } from '../../../store/apis/administratives/students';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { find, identity, propEq } from 'ramda';
import { formatDate } from '../../../utils';

const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

const ListFilters = () => {
  const [t, i18n] = useTranslation();
  const [isOpen, show, hide] = useToggle();
  const navigate = useNavigate();

  const {
    data: tutorData,
    isLoading: isTutorDataLoading,
    isFetching: isTutorDataFetching,
  } = useGetTutorsQuery({ page: -1 });

  const {
    data: studentData,
    isLoading: isStudentDataLoading,
    isFetching: isStudentDataFetching,
  } = useGetStudentsQuery({ page: -1 });

  const submitForm = useCallback((values) => {
    const lessonTimeFrom = values.lessonTimeRange && values.lessonTimeRange[0]
      ? values.lessonTimeRange[0].toISOString()
      : null;
    const lessonTimeTo = values.lessonTimeRange && values.lessonTimeRange[1]
      ? values.lessonTimeRange[1].toISOString()
      : null;


    const query = {
      ...values,
      lessonTimeFrom,
      lessonTimeTo,
    };

    navigate({
      pathname: '',
      search: queryString.stringify(query, { skipNull: true, skipEmptyString: true }),
    });
    hide();
  }, [t, tutorData, studentData, navigate, hide]);

  const reset = useCallback(() => {
    navigate({ pathname: '' });
    hide();
  }, [hide, navigate]);

  return (
    <>
      <Tooltip title={t('Фильтры')}>
        <Button
          type="primary"
          shape="circle"
          icon={<FilterOutlined />}
          onClick={show}
        />
      </Tooltip>
      <Drawer
        title={t('Фильтры')}
        placement="right"
        onClose={hide}
        open={isOpen}
      >
        <Form
          onFinish={submitForm}
          layout="vertical"
        >
          <FormItem label={t('Время урока (от - до)')} name="lessonTimeRange">
            <RangePicker showTime format="DD.MM.YYYY - HH:mm" />
          </FormItem>

          <FormItem label={t('Учитель')} name="tutorId">
            <Select
              loading={isTutorDataLoading || isTutorDataFetching}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={tutorData?.results.map(({ id, fullName }) => ({
                value: id,
                label: fullName?.[i18n.language],
              }))}
              allowClear
            />
          </FormItem>
          <FormItem label={t('Ученик')} name="studentId">
            <Select
              loading={isStudentDataLoading || isStudentDataFetching}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={studentData?.results.map(({ id, fullName }) => ({
                value: id, label: fullName,
              }))}
              allowClear
            />
          </FormItem>
          <FormItem label={t('Тип урока')} name="tariffGroup">
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'trial', label: t('trial')},
                { value: 'bonus', label: t('bonus')},
                { value: 'other', label: t('other')},
              ]}
              allowClear
            />
          </FormItem>
          <Row justify="space-between">
            <Col>
              <Button onClick={reset} htmlType="reset">
                {t('Сбросить')}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                {t('Показать')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ListFilters;
