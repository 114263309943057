import React, { useCallback, useMemo, useState } from 'react';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetLessonsQuery } from '../../../store/apis/finances/lessons';
import useQueryString from '../../../hooks/useQueryString';
import { Button, Skeleton, Space, Table, Tag } from 'antd';
import ContentHeader from '../../../components/ContentHeader';
import QueryView from '../../../containers/shared/QueryView';
import routes from '../../../router/routes';
import { formatDate } from '../../../utils';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import ListFilters from '../ListFilters';
import { filter, find, identity, omit, propEq } from 'ramda';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import { useGetStudentsQuery, useLazyGetStudentDetailsQuery } from '../../../store/apis/administratives/students';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page, pageSize, ...query } = useQueryString();

  const [revealedPhones, setRevealedPhones] = useState({});

  const navigate = useNavigate();

  const {
    data: lessonData,
    isLoading: isLessonDataLoading,
    isFetching: isLessonDataFetching,
  } = useGetLessonsQuery({ page, pageSize, ...query });

  const {
    data: tutorData,
    isLoading: isTutorDataLoading,
    isFetching: isTutorDataFetching,
  } = useGetTutorsQuery({ page: -1 });

  const {
    data: studentData,
    isLoading: isStudentDataLoading,
    isFetching: isStudentDataFetching,
  } = useGetStudentsQuery({ page: -1 });

  const [
    getStudentDetails,
    { isFetching: isStudentFetching },
  ] = useLazyGetStudentDetailsQuery();

  const getPhone = (id) => async (e) => {
    e.stopPropagation();
    const studentDetails = await getStudentDetails(id).unwrap();

    setRevealedPhones({
      ...revealedPhones,
      [id]: studentDetails.phoneNumber,
    })
  };

  const columns = useMemo(() => [
    {
      title: t('По расписанию'),
      dataIndex: 'lessonTime',
      key: 'lessonTime',
      render: (data) => (
        <Tag color="volcano">{formatDate(data, true)}</Tag>
      ),
    },
    {
      title: t('Фактическое время'),
      dataIndex: 'startedTime',
      key: 'startedTime',
      render: (data, raw) => (
        <Space direction="vertical">
          <Tag color="green">{formatDate(data, true)}</Tag>
          <Tag color="green">{formatDate(raw.finishedTime, true)}</Tag>
        </Space>
      ),
    },
    {
      title: t('Начал урок'),
      dataIndex: 'startAutorType',
      key: 'startAutorType',
      render: (data) => data ? data.name[i18n.language] : '-',
    },
    {
      title: t('Закончил урок'),
      dataIndex: 'finishAutorType',
      key: 'finishAutorType',
      render: (data) => data ? data.name[i18n.language] : '-',
    },
    {
      title: t('Состояние'),
      dataIndex: 'state',
      key: 'state',
      render: (data) => data.name[i18n.language],
    },
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (data) => data.fullName?.[i18n.language],
    },
    {
      title: t('Ученик'),
      dataIndex: 'student',
      key: 'student',
      render: (student) => (
        <Space direction="vertical">
          {student.fullName?.[i18n.language]}
          {
            revealedPhones[student.id] || (
              <Button
                type="link"
                size="small"
                style={{ paddingLeft: 0 }}
                onClick={getPhone(student.id)}
              >
                {t('Показать номер телефона')}
              </Button>
            )
          }
        </Space>
      ),
    },
    {
      title: t('Предмет'),
      dataIndex: 'subject',
      key: 'subject',
      render: (data) => data.name[i18n.language],
    },
  ], [t, i18n.language, revealedPhones, getPhone]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...query }),
    })
  }, [navigate, query]);

  const goToLessonDetails = useCallback((record) => () => {
    navigate(routes.lessons(record.id));
  }, [navigate]);

  // const view = [
  //   values.tutorId
  //     ? `${t('Учитель')}: ${find(propEq(values.tutorId, 'id'), tutorData.results)?.fullName?.[i18n.language]}`
  //     : null,
  //   values.studentId
  //     ? `${t('Ученик')}: ${find(propEq(values.studentId, 'id'), studentData.results)?.fullName}`
  //     : null,
  //   values.lessonTimeRange
  //     ? `${t('За дату')}: ${values.lessonTimeRange?.map((d) => formatDate(d, true)).join(' - ')}`
  //     : null,
  // ].filter(identity);

  const onClearTag = (tag) => {
    const omitProperty =
      tag.id === 'lessonTimeRange'
        ? ['lessonTimeTo', 'lessonTimeFrom']
        : [tag.id];

    const newQuery = omit(omitProperty)({
      page,
      pageSize,
      ...query,
    });
    navigate({
      pathname: '',
      search: queryString.stringify(newQuery, { skipNull: true, skipEmptyString: true }),
    });
  };

  const findById = (id, list) => (list && id) ? find(propEq(id, 'id'), list) : {};

  const queryView = filter(({ value }) => value)([
    {
      id: 'tutorId',
      label: `${t('Учитель')}: ${findById(query.tutorId, tutorData?.results)?.fullName?.[i18n.language]}`,
      value: query.tutorId,
    },
    {
      id: 'studentId',
      label: `${t('Ученик')}: ${findById(query.studentId, studentData?.results)?.fullName}`,
      value: query.studentId,
    },
    {
      id: 'lessonTimeRange',
      label: query.lessonTimeTo
        ? `${t('За дату')} ${formatDate(query.lessonTimeFrom)} - ${formatDate(query.lessonTimeTo)}`
        : null,
      value: query.lessonTimeTo,
    },
    {
      id: 'tariffGroup',
      label: query.tariffGroup
        ? `${t('Тип урока')} ${t(query.tariffGroup)}`
        : null,
      value: query.tariffGroup,
    },
  ])

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Проведенные уроки')}>
          <ListFilters />
        </ContentHeader>
        <QueryView
          label={t('Показаны уроки')}
          tags={queryView}
          onClose={onClearTag}
        />
      </div>

      <div className={styles.body}>
        <Skeleton loading={isLessonDataLoading || isLessonDataFetching}>
          <Table
            columns={columns}
            dataSource={lessonData?.results}
            rowKey="id"
            pagination={{
              total: lessonData?.count,
              current: page,
              locale: { items_per_page: `/ ${t('на странице')}` },
              pageSize,
              showSizeChanger: true,
              showTitle: true,
              pageSizeOptions: [20, 50, 100],
            }}
            onChange={handlePageChange}
            rootClassName="overflow-scroll"
            rowClassName={styles.bodyRow}
            onRow={(record) => ({
              onClick: goToLessonDetails(record),
            })}
          />
        </Skeleton>
      </div>
    </div>
  );
};

export default List;
