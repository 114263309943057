// import cn from 'classnames';

import { Button, Form, Input, InputNumber, message, Modal, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../hooks/useToggle';
import { useUpdateTutorInitialRateMutation } from '../../store/apis/administratives/tutors';

const FormItem = Form.Item;

const UpdateTutorInitialRate = ({ tutorId }) => {
  const [t] = useTranslation();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const [form] = Form.useForm();

  const [
    updateInitialRate,
    {
      isLoading,
      isFetching,
    }
  ] = useUpdateTutorInitialRateMutation();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();

      await updateInitialRate({ tutorId, body: values }).unwrap();
      message.success(t('Изначальный рейтинг изменен'));
      hideModal();
      form.resetFields();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, updateInitialRate, tutorId, t, hideModal]);

  return (
    <>
      <Button
        block
        type="primary"
        onClick={showModal}
      >
        {t('Изначальный рейтинг')}
      </Button>
      <Modal
        open={isModalOpen}
        title={t('Изменить изначальный рейтинг')}
        okText={t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isLoading || isFetching}
      >
        <Form
          form={form}
          disabled={isLoading || isFetching}
          layout="vertical"
          name="tutor_phone_form"
        >
          <FormItem
            name="initialRating"
            label={t('Рейтинг')}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>
          <FormItem
            name="initialReviewCount"
            label={t('Отзывов')}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>
          <FormItem
            name="initialCompletedLessonCount"
            label={t('Провел уроков')}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateTutorInitialRate;
