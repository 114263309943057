import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import useQueryString from '../../../hooks/useQueryString';
import DictionaryList from '../../../containers/shared/DictionaryList';
import { useGetEducationInstitutionsQuery } from '../../../store/apis/dictionaries/education-institution';
import { useGetLearningGoalsQuery } from '../../../store/apis/dictionaries/learning-goals';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page = 1, pageSize = 20 } = useQueryString();
  const { data, isLoading, isFetching } = useGetLearningGoalsQuery({ page, pageSize });



  return (
    <DictionaryList
      title={t('Цели обучения')}
      data={data?.results}
      dataCount={data?.count}
      loading={isLoading || isFetching}
      detailRoute={routes.learningGoals}
    />
  );
};

export default List;
