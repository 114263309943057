import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTutorStories: build.query({
      query: (params) => ({
        url: apiRoutes.tutorStory(),
        params,
      }),
      providesTags: ['TUTOR_STORIES'],
    }),
    getTutorStoryDetails: build.query({
      query: (id) => ({
        url: apiRoutes.tutorStory(id),
      }),
      providesTags: ['TUTOR_STORY_DETAILS'],
    }),
    editTutorStory: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.tutorStory(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_STORIES', 'TUTOR_STORY_DETAILS'] }),
    }),
    createTutorStory: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorStory(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_STORIES', 'TUTOR_STORY_DETAILS'] }),
    }),
  })
});

export const {
  useGetTutorStoriesQuery,
  useGetTutorStoryDetailsQuery,
  useEditTutorStoryMutation,
  useCreateTutorStoryMutation,
} = rootApi;
