import React from 'react';
import { message, Typography } from 'antd';
import Form from '../Form';
import { useTranslation } from 'react-i18next';
import { useCreateTutorStoryMutation } from '../../../store/apis/administratives/tutorStories';

const Create = () => {
  const [t] = useTranslation();
  const [
    createTutorStory,
    {
      isLoading: isCreating,
    }
  ] = useCreateTutorStoryMutation();

  const handleSubmit = async (values) => {
    try {
      await createTutorStory(values).unwrap();
      message.success(t('Отзыв создан'))
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  return (
    <div>
      <Typography.Title level={4}>
        {t('Отзыв учителя о платформе')}
      </Typography.Title>
      <Form
        initialValues={{}}
        onSubmit={handleSubmit}
        loading={isCreating}
      />
    </div>
  );
};

export default Create;
