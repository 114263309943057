import React, { useCallback, useMemo } from 'react';
import { Button, Form as AntForm, Input, InputNumber, message, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { EditOutlined } from '@ant-design/icons';
import {
  FaDiscord,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaOdnoklassniki,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaVk,
  FaWhatsapp,
  FaYoutube
} from 'react-icons/fa';
import { keys } from 'ramda';
import {
  useAddSocialLinksMutation,
  useUpdateSocialLinksMutation
} from '../../../store/apis/administratives/socialLinks';


const FormItem = AntForm.Item;

const Form = ({ mode, id, initialValues = {} }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();
  const [form] = AntForm.useForm();

  const [
    addSocialLinks,
    {
      isLoading: isAddSocialLinksLoading,
      isFetching: isAddSocialLinksFetching,
    },
  ] = useAddSocialLinksMutation();

  const [
    editSocialLinks,
    {
      isLoading: isEditSocialLinksLoading,
      isFetching: isEditSocialLinksFetching,
    },
  ] = useUpdateSocialLinksMutation();

  const formOptions = useMemo(() => ({
    create: {
      title: t('Создать ссылку'),
      okText: t('Создать'),
      isLoading: isAddSocialLinksLoading || isAddSocialLinksFetching,
      operateAction: addSocialLinks,
      successMessage: t('Ссылка создана'),
    },
    edit: {
      title: t('Редактировать ссылку'),
      okText: t('Редактировать'),
      isLoading: isEditSocialLinksLoading || isEditSocialLinksFetching,
      operateAction: editSocialLinks,
      successMessage: t('Ссылка изменена'),
    },
  }), [addSocialLinks, isAddSocialLinksFetching, isAddSocialLinksLoading, isEditSocialLinksLoading, isEditSocialLinksFetching, editSocialLinks, t]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
      }

      await formOptions[mode].operateAction({ body, id }).unwrap();
      message.success(formOptions[mode].successMessage);
      close();
      form.resetFields();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [close, form, formOptions, id, mode]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    close();
  }, [close, form]);

  const socialLinksIcons = useMemo(() => ({
    telegram: FaTelegramPlane,
    facebook: FaFacebookF,
    instagram: FaInstagram,
    youtube: FaYoutube,
    twitter: FaTwitter,
    tiktok: FaTiktok,
    linkedin: FaLinkedin,
    discord: FaDiscord,
    whatsapp: FaWhatsapp,
    vk: FaVk,
    odnoklassniki: FaOdnoklassniki,
  }), []);

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button type="primary" onClick={open}>
              {t('Создать')}
            </Button>
          ) : (
            <Button icon={<EditOutlined />} type="link" onClick={open} />
          )
      }
      <Modal
        open={isOpen}
        title={formOptions[mode].title}
        okText={formOptions[mode].okText}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={handleCancel}
        maskClosable={false}
        confirmLoading={formOptions[mode].isLoading}
        destroyOnClose
      >
        <AntForm
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={formOptions[mode].isLoading}
          layout="vertical"
          // name="success_story_form"
          initialValues={initialValues}
        >
          <FormItem name="url" label={t('Ссылка')} rules={[{ required: true }]}>
            <Input />
          </FormItem>
          <FormItem name="orderId" label={t('Порядковый номер')} rules={[{ required: true }]}>
            <InputNumber />
          </FormItem>
          <FormItem name="iconName" label={t('Иконка')} required style={{ marginBottom: 0 }} rules={[{ required: true }]}>
            <Select
              options={keys(socialLinksIcons).map((name) => {
                const IconComponent = socialLinksIcons[name];
                return {
                  value: name,
                  label: <IconComponent />,
                };
              })}
            />
          </FormItem>
        </AntForm>
      </Modal>
    </>
  );
};

export default Form;
