import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import QueryView from '../../../containers/shared/QueryView';
import ListFilters from '../ListFilters';
import { useGetStudentsQuery } from '../../../store/apis/administratives/students';
import useQueryString from '../../../hooks/useQueryString';
import { Skeleton, Space, Table, Tag } from 'antd';
import { formatDate } from '../../../utils';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { filter, identity, omit } from 'ramda';

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, ...query } = useQueryString();
  const navigate = useNavigate();

  const {
    data: studentData,
    isLoading,
    isFetching,
  } = useGetStudentsQuery({ page, pageSize, ...query });

  const columns = useMemo(() => [
    {
      title: t('ФИО'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (data) => data,
    },
    {
      title: t('Телефон'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (data) => data,
    },
    {
      title: t('Дата рождения'),
      dataIndex: 'birthdate',
      key: 'birthdate',
      render: (data) => formatDate(data),
    },
    {
      title: t('Почта'),
      dataIndex: 'email',
      key: 'email',
      render: (data) => data,
    },
    {
      title: t('Дата создания'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (data) => formatDate(data, true),
    },
    {
      title: t('Последний контакт'),
      dataIndex: 'clientCard',
      key: 'clientCard-1',
      render: (data) => {
        if (!data) return '-';
        return <Tag color="blue">{formatDate(data.editedTime, true)}</Tag>
      },
    },
    {
      title: t('Следующий контакт'),
      dataIndex: 'clientCard',
      key: 'clientCard-2',
      render: (data) => {
        if (!data || !data.nextContactDate) return '-';
        return <Tag color="blue">{formatDate(data.nextContactDate)}</Tag>
      },
    },
    {
      title: t('Статус'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (data) => <Tag color={data ? 'green' : 'red'}>{data ? t('Активный') : t('Неактивный')}</Tag>,
    },
  ], [t]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...query }),
    })
  }, [navigate, query]);

  const goToStudentDetails = useCallback((record) => () => {
    navigate(routes.students(record.id));
  }, [navigate]);

  const onClearTag = (tag) => {
    const omitProperty =
      tag.id === 'nextContactDate'
        ? ['nextContactDateTo', 'nextContactDateFrom']
        : [tag.id];

    const newQuery = omit(omitProperty)({
      page,
      pageSize,
      ...query,
    });
    navigate({
      pathname: '',
      search: queryString.stringify(newQuery, { skipNull: true, skipEmptyString: true }),
    });
  };

  const queryView = filter(({ value }) => value)([
    {
      id: 'lastName',
      label: `${t('Фамилия')}: ${query.lastName}`,
      value: query.lastName,
    },
    {
      id: 'firstName',
      label: `${t('Имя')}: ${query.firstName}`,
      value: query.firstName,
    },
    {
      id: 'phoneNumber',
      label: `${t('Телефон')}: ${query.phoneNumber}`,
      value: query.phoneNumber,
    },
    {
      id: 'isActive',
      label: query.isActive ? t('Только активные') : null,
      value: query.isActive,
    },
    {
      id: 'nextContactDate',
      label: query.nextContactDateTo
        ? `${t('Контакт в период')} ${formatDate(query.nextContactDateFrom)} - ${formatDate(query.nextContactDateTo)}`
        : null,
      value: query.nextContactDateTo,
    },
  ])

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Студенты')}>
          <ListFilters />
        </ContentHeader>
        <QueryView
          label={t('Показаны студенты')}
          tags={queryView}
          onClose={onClearTag}
        />
      </div>
      <div className={styles.body}>
        <Skeleton loading={isLoading || isFetching}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={studentData?.results}
            rowKey="id"
            pagination={{
              total: studentData?.count,
              current: page,
              locale: { items_per_page: `/ ${t('на странице')}` },
              pageSize,
              showSizeChanger: true,
              showTitle: true,
              pageSizeOptions: [20, 50, 100],
            }}
            onChange={handlePageChange}
            rowClassName={styles.bodyRow}
            onRow={(record) => ({
              onClick: goToStudentDetails(record),
            })}
          />
        </Skeleton>
      </div>
    </div>
  );
};

export default List;
