import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getEducationInstitutions: build.query({
      query: (params) => ({
        url: apiRoutes.educationInstitution(),
        params,
      }),
      providesTags: ['EDUCATION_INSTITUTIONS'],
    }),
    getEducationInstitution: build.query({
      query: (langId) => ({
        url: apiRoutes.educationInstitution(langId),
      }),
      providesTags: ['EDUCATION_INSTITUTION'],
    }),
    editEducationInstitution: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.educationInstitution(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['EDUCATION_INSTITUTIONS', 'EDUCATION_INSTITUTION'] }),
    }),
    createEducationInstitution: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.educationInstitution(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['EDUCATION_INSTITUTIONS', 'EDUCATION_INSTITUTION'] }),
    }),
  })
});

export const {
  useGetEducationInstitutionsQuery,
  useGetEducationInstitutionQuery,
  useEditEducationInstitutionMutation,
  useCreateEducationInstitutionMutation,
} = rootApi;
