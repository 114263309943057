import React, { useCallback, useEffect } from 'react';
import { Button, Col, Form as AntForm, Input, InputNumber, Row, Space, Switch, Radio} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import LanguageInput from '../../../components/LanguagesInput';
import Uploader from '../../../containers/shared/Uploader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { normFile } from '../../../utils';
import usePermissions from '../../../hooks/usePermissions';

const FormItem = AntForm.Item;

const Form = ({
  onSubmit,
  initialValues,
  loading,
  saveAndResetLabel,
  disabledDangerField = false,
}) => {
  const [t] = useTranslation();
  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  const hasAccess = usePermissions();

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const handleSubmit = useCallback(async (e) => {
    if (!hasAccess(['ADMIN', 'SUPER_ADMIN'])) return;
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();

    const actualValues = {
      ...values,
      tutorSalePercentage: values.payDiscount === 'teacher' ? values.salePercentage : 0,
    };
    await onSubmit(actualValues);
    form.resetFields();
  }, [form, onSubmit, hasAccess]);

  const handleSubmitAndExit = useCallback(async (e) => {
    if (!hasAccess(['ADMIN', 'SUPER_ADMIN'])) return;
    e.preventDefault();
    const values = form.getFieldsValue();
    await form.validateFields();
    const actualValues = {
      ...values,
      tutorSalePercentage: values.payDiscount === 'teacher' ? values.salePercentage : 0,
    };
    await onSubmit(actualValues);
    navigate(routes.tariffs());
  }, [form, navigate, onSubmit, hasAccess]);

  return (
    <AntForm
      form={form}
      name="tariff-form"
      onFinish={handleSubmit}
      initialValues={initialValues}
      layout="vertical"
      disabled={loading || !hasAccess(['ADMIN', 'SUPER_ADMIN'])}
    >
      <Space size={60}>
        <FormItem label={t('Активный?')} name="isActive" valuePropName="checked">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </FormItem>
        <FormItem label={t('Публичный?')} name="isPublic" valuePropName="checked">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </FormItem>
      </Space>
      <FormItem label={t('Системное название')} name="sysName" rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem hidden name="isActive" />
      <FormItem label={t('Название')} required style={{ marginBottom: 0 }}>
        <LanguageInput name="name" required />
      </FormItem>

      <FormItem label={t('Короткое название')} required style={{ marginBottom: 0 }}>
        <LanguageInput name="shortName" />
      </FormItem>


      <FormItem label={t('Описание')} required style={{ marginBottom: 0 }}>
        <LanguageInput name="description" />
      </FormItem>

      <Space size={40}>
        <FormItem label={t('Порядковый номер')} style={{ marginBottom: 0 }} name="orderId">
          <InputNumber prefix="№" />
        </FormItem>
        <FormItem label={t('Количество уроков')} style={{ marginBottom: 0 }} name="lessonCount" >
          <InputNumber disabled={disabledDangerField} />
        </FormItem>
        <FormItem label={t('Действие тарифа (месяцев)')} style={{ marginBottom: 0 }} name="validityPeriod">
          <InputNumber />
        </FormItem>
      </Space>

      <div />

      <Space size={40}>
        <FormItem label={t('Процент скидки')} style={{ marginTop: 16 }} name="salePercentage">
          <InputNumber prefix="%" disabled={disabledDangerField} />
        </FormItem>
        <FormItem label={t('У кого удерживать сумму скидки')} style={{ marginTop: 16 }} name="payDiscount">
          <Radio.Group disabled={disabledDangerField}>
            <Radio value="teacher">{t('У учителя')}</Radio>
            <Radio value="platform">{t('У платформы')}</Radio>
          </Radio.Group>
        </FormItem>
      </Space>


      <FormItem
        label={t('Картинка тарифа')}
        name="picturePath"
        getValueFromEvent={normFile}
        required
        style={{ marginTop: 20 }}
      >
        <Uploader
          maxCount={1}
          multiple={false}
          group="tariffs"
          existedFileUrl={initialValues.pictureUrl}
        />
      </FormItem>

      {
        hasAccess(['ADMIN', 'SUPER_ADMIN']) && (
          <Row justify="end">
            <Col>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  onClick={handleSubmitAndExit}
                >
                  {t('Сохранить и выйти')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {saveAndResetLabel ? saveAndResetLabel : t('Сохранить')}
                </Button>
              </Space>
            </Col>
          </Row>
        )
      }
    </AntForm>
  );
};

export default Form;
