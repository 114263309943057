import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './TeacherPriceForm.module.scss';
import { Button, Col, Radio, Form, InputNumber, message, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { useSetTutorPriceMutation } from '../../../store/apis/administratives/tutors';

const FormItem = Form.Item;

const TeacherPriceForm = ({ tutorId, initialValues }) => {
  const initMode = initialValues.tutorFixIncomePerLesson ? 'fixed' : 'percent';
  const [t] = useTranslation();
  const [isModalOpen, showModal, hideModal] = useToggle();
  const [mode, setMode] = useState(initMode);

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const [
    setTutorPrice,
    { isLoading: isSetTutorPriceLoading, isFetching: isSetTutorPriceFetching },
  ] = useSetTutorPriceMutation();

  const [form] = Form.useForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();

      await setTutorPrice({ tutorId, body: values }).unwrap();
      message.success(t('Цена установлена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  return (
    <>
      <Button
        block
        type="primary"
        onClick={showModal}
      >
        {t('Установить цену за час')}
      </Button>
      <Modal
        open={isModalOpen}
        title={t('Установить цену учителя')}
        okText={t('Установить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isSetTutorPriceLoading || isSetTutorPriceFetching}
      >
        <Row justify="center">
          <Col>
            <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
              <Radio.Button value="percent">{t('Проценты')}</Radio.Button>
              <Radio.Button value="fixed">{t('Фикса')}</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        {
          mode === 'percent' && (
            <Form
              form={form}
              onFinish={() => {
                console.log('finish');
              }}
              initialValues={initialValues}
              disabled={isSetTutorPriceLoading || isSetTutorPriceFetching}
              layout="vertical"
              name="tutor_price_form"
            >
              <FormItem name="pricePerLesson" label={t('Цена за час')} rules={[{ required: true }]}>
                <InputNumber prefix="so'm" style={{ width: '100%' }} />
              </FormItem>
              <FormItem name="tutorRevenuePercent" label={t('Процент отчисления учителю')} rules={[{ required: true }]}>
                <InputNumber prefix="%" style={{ width: '100%' }} />
              </FormItem>
            </Form>
          )
        }
        {
          mode === 'fixed' && (
            <Form
              form={form}
              onFinish={() => {
                console.log('finish');
              }}
              initialValues={initialValues}
              disabled={isSetTutorPriceLoading || isSetTutorPriceFetching}
              layout="vertical"
              name="tutor_price_form"
            >
              <FormItem name="pricePerLesson" label={t('Цена за час')} rules={[{ required: true }]}>
                <InputNumber prefix="so'm" style={{ width: '100%' }} />
              </FormItem>
              <FormItem name="tutorFixIncomePerLesson" label={t('Фикса отчисления учителю')} rules={[{ required: true }]}>
                <InputNumber prefix="сум" style={{ width: '100%' }} />
              </FormItem>
            </Form>
          )
        }
      </Modal>
    </>
  );
};

export default TeacherPriceForm;
