import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './EducationTeacherForm.module.scss';
import { Button, DatePicker, Form, Input, InputNumber, message, Modal, Select, Switch } from 'antd';
import { dictionaryToSelect, normFile } from '../../../utils';
import Uploader from '../../shared/Uploader';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import dayjs from 'dayjs';
import LanguageInput from '../../../components/LanguagesInput';
import { useGetEducationLevelsQuery } from '../../../store/apis/dictionaries/education-levels';
import { useGetEducationInstitutionsQuery } from '../../../store/apis/dictionaries/education-institution';
import {
  useCreateTutorEducationMutation,
  useEditTutorEducationMutation
} from '../../../store/apis/administratives/tutors';
import { EditOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

/**
 *
 * @param tutorId
 * @param initialValues
 * @param {('create'|'edit')} mode
 * @returns {JSX.Element}
 * @constructor
 */
const EducationTeacherForm = ({
  tutorId,
  initialValues = {},
  mode = 'create',
}) => {
  const [t] = useTranslation();
  const [isModalOpen, showModal, hideModal] = useToggle();
  const [form] = Form.useForm();

  const { data: educationLevels, isLoading: isEducationLevelsLoading, isFetching: isEducationLevelsFetching } = useGetEducationLevelsQuery({ page: -1 });
  const { data: educationInstitutions, isLoading: isEducationInstitutionsLoading, isFetching: isEducationInstitutionsFetching } = useGetEducationInstitutionsQuery({ page: -1 });

  const actionHooks = {
    create: useCreateTutorEducationMutation,
    edit: useEditTutorEducationMutation,
  };

  const [operateEducation, { isLoading: isOperationLoading, isFetching: isOperationFetching }] = actionHooks[mode]();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();

      const body = {
        ...values,
        fromYear: values.fromYear ? values.fromYear.year() : null,
        toYear: values.toYear ? values.toYear.year() : null
      };
      await operateEducation(body).unwrap();
      message.success(mode === 'create' ? t('Образование добавлено') : t('Образование изменено'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, operateEducation, mode, t]);

  const actualInitialValues = {
    ...initialValues,
    id: initialValues.id || null,
    fromYear: initialValues.fromYear ? dayjs().year(initialValues.fromYear) : null,
    toYear: initialValues.toYear ? dayjs().year(initialValues.toYear) : null,
    educationLevel: initialValues.educationLevel?.id,
    educationInstitution: initialValues.educationInstitution?.id,
    tutorId,
  };

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button block type="link" onClick={showModal}>
              {t('Добавить образование')}
            </Button>
          ) : (
            <Button block onClick={showModal} type="link" size="small" icon={<EditOutlined />} />
          )
      }
      <Modal
        open={isModalOpen}
        title={mode === 'create' ? t('Добавить образование') : t('Изменить образование')}
        okText={mode === 'create' ? t('Добавить') : t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isOperationLoading || isOperationFetching}
        width={700}
      >
        <Form
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={isOperationLoading || isOperationFetching}
          layout="vertical"
          name="education_teacher_form"
          initialValues={actualInitialValues}
        >
          <FormItem hidden name="tutorId" />
          <FormItem hidden name="id" />
          <FormItem label={t('Специальность')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="speciality" required type="textarea" rows={3} />
          </FormItem>
          <FormItem label={t('Год начала обучения')} name="fromYear">
            <DatePicker picker="year" placeholder={t('Выберите год')} style={{ width: '100%' }} />
          </FormItem>
          <FormItem label={t('Год окончания обучения')} name="toYear">
            <DatePicker picker="year" placeholder={t('Выберите год')} style={{ width: '100%' }} />
          </FormItem>
          <FormItem label={t('Уровень')} name="educationLevel">
            <Select
              showSearch
              placeholder={t('Выберите уровень')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isEducationLevelsFetching || isEducationLevelsLoading}
              options={dictionaryToSelect(educationLevels?.results)}
            />
          </FormItem>
          <FormItem label={t('ВУЗ')} name="educationInstitution">
            <Select
              showSearch
              placeholder={t('Выберите ВУЗ')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isEducationInstitutionsLoading || isEducationInstitutionsFetching}
              options={dictionaryToSelect(educationInstitutions?.results)}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default EducationTeacherForm;
