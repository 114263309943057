import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Col, Row, Table, Tag, Typography } from 'antd';
import ContentHeader from '../../../components/ContentHeader';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import { joinExisting } from '../../../utils';
import PersonalTeacherDataForm from '../../../containers/teacher/PersonalTeacherDataForm';
import ListFilters from '../ListFilters';

const List = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const { data: tutors, isLoading, isFetching } = useGetTutorsQuery({ page, pageSize, ...restQuery });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const columns = useMemo(() => [
    {
      title: t('ФИО'),
      dataIndex: 'id',
      key: 'name',
      render: (id, record) => <Link to={routes.teachers(id)}>
        {joinExisting([
          record.lastName?.[i18n.language],
          record.firstName?.[i18n.language],
          record.secondName?.[i18n.language]
        ], ' ')}
      </Link>
    },
    {
      title: t('Предметы'),
      dataIndex: 'subjects',
      key: 'subjects',
      render: (subjects) => subjects.map((subject) => <Tag color="blue" key={subject.id}>{subject.name[i18n.language]}</Tag>),
    },
    {
      title: t('Продвигается'),
      dataIndex: 'isPromote',
      key: 'isPromote',
      render: (isPromote, record) => isPromote ? '✅' : '❌'
    },
    {
      title: t('Состояние'),
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (isPublished, record) => (
        <Badge color={isPublished ? 'green' : 'red'} text={isPublished ? t('Активный') : t('Неактивный')} />
      )
    },
  ], [i18n.language, t]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Учителя')}>
          <PersonalTeacherDataForm
            initialValues={{}}
            mode="create"
          />
          <ListFilters />
        </ContentHeader>
      </div>
      <Row>
        <Col md={24}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={tutors?.results}
            rowKey="id"
            loading={isLoading || isFetching}
            onChange={handlePageChange}
            pagination={{
              total: tutors?.count,
              current: page,
              locale: { items_per_page: `/ ${t('на странице')}` },
              pageSize,
              showSizeChanger: true,
              showTitle: true,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;
