import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useGetStatsByTutorQuery } from '../../../store/apis/reports/dashboard';
import { Col, DatePicker, Row, Select, Skeleton, Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { formatNumber, joinExisting } from '../../../utils';
import usePermissions from '../../../hooks/usePermissions';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';

const { Title, Text } = Typography;

const StatsByTutor = () => {
  const [t, i18n] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);
  const [tutorId, setTutorId] = useState(null);
  const hasAccess = usePermissions();

  const {
    data: stat,
    isLoading,
    isFetching,
  } = useGetStatsByTutorQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
    tutorId: tutorId ?? '',
  });

  const {
    data: tutors,
    isFetching: isTutorsFetching,
  } = useGetTutorsQuery({ page: -1 });

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const columns = useMemo(() => [
    {
      title: t('Учитель'),
      dataIndex: 'id',
      key: 'id',
      render: (id, raw) => hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])
        ? <Link to={routes.teachers(id)}>{joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')}</Link>
        : joinExisting([raw.lastName, raw.firstName, raw.secondName], ' '),
    },
    {
      title: t('Текущий баланс (сум)'),
      dataIndex: 'balanceAmount',
      key: 'balanceAmount',
      render: (data) => formatNumber(data),
    },
    {
      title: t('Активных студентов'),
      dataIndex: 'activeStudentCount',
      key: 'activeStudentCount',
      render: (data) => data ?? 0,
    },
    {
      title: t('Уроков проведено'),
      dataIndex: 'finishedLessonCount',
      key: 'finishedLessonCount',
      render: (data) => data ?? 0,
    },
    {
      title: t('Бесплатных уроков проведено'),
      dataIndex: 'freeLessonCount',
      key: 'freeLessonCount',
      render: (data) => data ?? 0,
    },
    {
      title: t('Отмененных уроков'),
      dataIndex: 'cancelledLessonsCount',
      key: 'cancelledLessonsCount',
      render: (data) => formatNumber(data) ?? 0,
    },
    {
      title: t('Заработано (сум)'),
      dataIndex: 'totalTutorIncomeAmount',
      key: 'totalTutorIncomeAmount',
      render: (data) => formatNumber(data) ?? 0,
    },
  ], [hasAccess, t]);

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  const changeFilterTutor = (v) => {
    setTutorId(v)
  };

  if (isLoading || isFetching) return <Skeleton loading />

  return (
    <div>
      <Row style={{ marginBottom: 32 }}>
        <Col>
          <Title level={3}>{t('Статистика по учителям')}</Title>
          <Space>
            <Text>{t('Статистика за период')}</Text>
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              onChange={changeFilterDate}
              value={lessonDateDiapason}
              allowClear={false}
            />
            <Text>{t('Статистика по учителю')}</Text>
            <Select
              value={tutorId}
              loading={isTutorsFetching}
              options={tutors?.results.map((tutor) => ({
                value: tutor.id,
                label: tutor.fullName[i18n.language]
              }))}
              style={{ width: 260 }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              placeholder={t('Выберите учителя')}
              onChange={changeFilterTutor}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={stat.rows}
            rowKey="id"
            pagination={{
              pageSize: 50,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StatsByTutor;
