import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Skeleton, Typography } from 'antd';
import { useParams } from 'react-router';
import { useEditTariffMutation, useGetTariffQuery } from '../../../store/apis/administratives/tariffs';
import TariffForm from '../Form';

const Details = () => {
  const { id: tariffId } = useParams();
  const [t] = useTranslation();

  const { data: tariff, isLoading: isTariffLoading, isFetching: isTariffFetching } = useGetTariffQuery(tariffId);
  const [editTariff, { isLoading: isEditLoading, isFetching: isEditFetching }] = useEditTariffMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await editTariff({ id: tariffId, body: values }).unwrap();
      message.success(t('Тариф сохранен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [editTariff, t, tariffId]);


  if (isTariffFetching || isTariffLoading) return <Skeleton loading />

  const initialValues = {
    ...tariff,
    payDiscount: tariff.tutorSalePercentage > 0 ? 'teacher' : 'platform',
  };

  return (
    <div>
      <Typography.Title level={4}>{tariff.sysName}</Typography.Title>
      <TariffForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={isEditLoading || isEditFetching}
        disabledDangerField
      />
    </div>
  );
};


export default Details;
