import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, Input, message, Modal, Switch } from 'antd';
import useToggle from '../../../hooks/useToggle';
import {
  useCreateTutorCertificateMutation,
  useEditTutorCertificateMutation
} from '../../../store/apis/administratives/tutors';
import LanguageInput from '../../../components/LanguagesInput';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const CertificatesTeacherForm = ({ tutorId, initialValues = {}, mode = 'create' }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const actionHooks = {
    create: useCreateTutorCertificateMutation,
    edit: useEditTutorCertificateMutation,
  }
  const [operateCertificate, { isLoading: isOperateCertificateLoading, isFetching: isOperateCertificateFetching }] = actionHooks[mode]();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
        year: values.year ? values.year.year() : null,
      }
      await operateCertificate(body).unwrap();
      message.success(mode === 'create' ? t('Сертификат добавлен') : t('Сертификат изменен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, operateCertificate, mode, t]);

  const actualInitialValues = {
    ...initialValues,
    id: initialValues.id || null,
    tutorId,
    year: initialValues.year ? dayjs().year(initialValues.year) : null,
  };

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button
              block
              type="link"
              onClick={showModal}
            >
              {t('Добавить сертификаты')}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={showModal}
              icon={<EditOutlined />}
              size="small"
            />
          )
      }
      <Modal
        open={isModalOpen}
        title={mode === 'create' ? t('Добавить сертификаты') : t('Изменить сертификат')}
        okText={mode === 'create' ? t('Добавить') : t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        width={900}
        confirmLoading={isOperateCertificateLoading || isOperateCertificateFetching}
      >
        <Form
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={isOperateCertificateLoading || isOperateCertificateFetching}
          layout="vertical"
          name="tutor_certificates_form"
          initialValues={actualInitialValues}
        >
          <FormItem hidden name="tutorId" />
          <FormItem hidden name="id" />
          <FormItem label={t('Наименование')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="name" required type="textarea" rows={2} />
          </FormItem>
          <FormItem label={t('Выдал')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="issuer" required type="textarea" rows={2} />
          </FormItem>
          <FormItem label={t('Подтвержденный')} name="isVerified" valuePropName="checked">
            <Switch checkedChildren={t('Да')} unCheckedChildren={t('Нет')} />
          </FormItem>
          <FormItem label={t('Год выдачи')} name="year">
            <DatePicker picker="year" placeholder={t('Выберите год')} style={{ width: '100%' }} />
          </FormItem>
          <FormItem label={t('Балл или уровень')} name="score">
            <Input />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default CertificatesTeacherForm;
