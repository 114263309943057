import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTutorWorkTime: build.query({
      query: (id) => ({
        url: apiRoutes.tutorWorkTimes(id),
      }),
      providesTags: ['TUTOR_WORK_TIME'],
    }),
  })
});

export const {
  useLazyGetTutorWorkTimeQuery,
  useGetTutorWorkTimeQuery,
} = rootApi;
