import React, { useCallback, useMemo } from 'react';

import styles from './DictionaryList.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useQueryString from '../../../hooks/useQueryString';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Col, Row, Skeleton, Table } from 'antd';

const DictionaryList = ({
  data,
  dataCount,
  loading,
  detailRoute,
  title,
  additionalColumns = [],
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20 } = useQueryString();

  const columns = useMemo(() => [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Системное имя'),
      dataIndex: 'sysName',
      key: 'sysName',
      render: (data, raw) => <Link to={detailRoute(raw.id)}>{data}</Link>
    },
    {
      title: t('Название'),
      dataIndex: 'name',
      key: 'name',
      render: (data) => data.ru,
    },
    ...additionalColumns,
    {
      title: t('Активный?'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (data) => data ? '✅' : '❌',
    },
  ], [detailRoute, t]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize }),
    })
  }, [navigate]);

  const handleNavigateToCreate = useCallback(() => {
    navigate(detailRoute('create'));
  }, [detailRoute, navigate]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={title}>
          <Button type="primary" onClick={handleNavigateToCreate}>
            {t('Создать')}
          </Button>
        </ContentHeader>
      </div>
      <Row>
        <Col md={24}>
          {
            (loading)
              ? <Skeleton loading />
              : (
                <Table
                  loading={loading}
                  columns={columns}
                  rowKey="id"
                  dataSource={data}
                  rootClassName="overflow-scroll"
                  onChange={handlePageChange}
                  pagination={{
                    total: dataCount,
                    current: page,
                    locale: { items_per_page: `/ ${t('на странице')}` },
                    pageSize,
                    showSizeChanger: true,
                    showTitle: true,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              )
          }
        </Col>
      </Row>
    </div>
  );
};

export default DictionaryList;
