import React, { useCallback } from 'react';
import FaqForm from '../Form';
import { message, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEditFaqMutation, useGetFaqDetailsQuery } from '../../../store/apis/administratives/faqs';
import { useParams } from 'react-router';

const Details = () => {
  const [t] = useTranslation();
  const { id } = useParams();

  const { data: faq, isLoading, isFetching } = useGetFaqDetailsQuery(id);
  const [editFaq, { isLoading: isEditLoading, isFetching: isEditFetching }] = useEditFaqMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await editFaq({ id, body: values }).unwrap();
      message.success(t('ВиО сохранен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [editFaq, id, t]);

  if (isLoading || isFetching) return <Skeleton loading />

  return (
    <div>
      <Typography.Title level={4}>{t('Вопрос')}</Typography.Title>
      <FaqForm
        initialValues={{
          ...faq,
          tags: faq?.tags.map(({ id }) => id),
        }}
        onSubmit={handleSubmit}
        loading={isEditLoading || isEditFetching}
      />
    </div>
  );
};

export default Details;
