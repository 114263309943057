import React, { useCallback } from 'react';
import { useGetSettingsQuery, usePatchSettingsMutation } from '../../../store/apis/settings';
import { Button, Form, InputNumber, message, Skeleton, Typography } from 'antd';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../../hooks/usePermissions';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

const FormItem = Form.Item;

const List = () => {
  const [t] = useTranslation();
  const hasAccess = usePermissions();
  const {
    data: settings,
    isLoading: isSettingsLoading,
    isFetching: isSettingsFetching,
    error: settingsError,
  } = useGetSettingsQuery();

  const [
    patchSettings,
    {
      isLoading: isPatchSettingsLoading,
      isFetching: isPatchSettingsFetching,
    }
  ] = usePatchSettingsMutation();

  const onFinish = useCallback(async (values) => {
    if (!hasAccess(['SUPER_ADMIN'])) return;
    try {
      await patchSettings(values).unwrap();
      message.success(t('Настройки сохранены'));
    } catch (e) {
      console.log(e);
    }
  }, [patchSettings]);

  if (isSettingsLoading) return <Skeleton />;
  if (settingsError) return (
    <div>
      <Title level={3}>Ой, кажется произошла ошибка</Title>
      <pre>{JSON.stringify(settingsError, 0, 2)}</pre>
    </div>
  )

  if (!settings) return null;

  return (
    <div>
      <ContentHeader title={t('Настройки платформы')} />
      <Form
        onFinish={onFinish}
        layout="horizontal"
        labelCol={{ span: 15 }}
        wrapperCol={{ span: 9 }}
        initialValues={settings}
        disabled={
          isSettingsFetching
          || isPatchSettingsLoading
          || isPatchSettingsFetching
          || !hasAccess(['SUPER_ADMIN'])
        }
      >
        <FormItem
          name="newScheduleMinutesBeforeStart"
          label={t('СТУДЕНТ может забронировать урок за')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('минут')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="transferLessonMinutesBeforeStartForStudent"
          label={t('СТУДЕНТ может отменить или перенести урок за')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('минут')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="transferLessonMinutesBeforeStartForTutor"
          label={t('ПРЕПОДАВАТЕЛЬ может отменить или перенести урок за')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('минут')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="startLessonBeforeMinutes"
          label={t('УЧИТЕЛЬ может начать урок за')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('минут')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="autoDeleteNotPaidScheduleAfterMinutes"
          label={t('Если счет не был оплачен, то НОВАЯ бронь снимается через')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('минут')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="autoDeleteNotRenewedScheduleAfterMinutes"
          label={t('Если не оплатил ИМЕЮЩУЮСЯ бронь, то она отменится через')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('минут')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="lessonMovementLimitForStudent"
          label={t('Сколько СТУДЕНТ может отменять и переносить уроки за последние 30 дней')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('раз')} style={{ width: '100%' }} precision={0} />
        </FormItem>
        <FormItem
          name="tutorSalaryTaxPercent"
          label={t('Налог с отчислений преподавателю')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('%')} style={{ width: '100%' }} />
        </FormItem>
        <FormItem
          name="newSchedulesDailyLimitForStudent"
          label={t('Суточный лимит бронирование слотов для СТУДЕНТА')}
          rules={[{ required: true }]}
          required
        >
          <InputNumber addonAfter={t('слотов')} style={{ width: '100%' }} />
        </FormItem>
        <FormItem
          name="maxScheduleForLessonBalanceN"
          label={t('СТУДЕНТ может бронировать слоты сверх имеющегося количества на балансе')}
          rules={[{ required: true }]}
          required
          tooltip={{
            title:(
              <div>
                <p>Для определения количества слотов, которые может бронировать студент СВЕРХ купленных уроков используется условие:</p>
                <Typography.Text code style={{ color: 'white' }}>
                  A = K x L + N
                </Typography.Text>
                <p>Где,</p>
                <ul>
                  <li>A - Количество допустимых к бронированию уроков</li>
                  <li>K - Коэффициент умножения</li>
                  <li>L - Имеющихся УЖЕ оплаченных уроков</li>
                  <li>N - Количество для прибавления уроков к получившиеся сумме</li>
                </ul>
              </div>
            ),
            icon: <QuestionCircleOutlined />,
          }}
        >
          <InputNumber addonAfter={t('слотов')} style={{ width: '100%' }} />
        </FormItem>
        <FormItem
          name="maxScheduleForLessonBalanceK"
          label={t('СТУДЕНТ может бронировать слоты сверх имеющегося количества на балансе')}
          rules={[{ required: true }]}
          required
          tooltip={{
            title:(
              <div>
                <p>Для определения количества слотов, которые может бронировать студент СВЕРХ купленных уроков используется условие:</p>
                <Typography.Text code style={{ color: 'white' }}>
                  A = K x L + N
                </Typography.Text>
                <p>Где,</p>
                <ul>
                  <li>A - Количество допустимых к бронированию уроков</li>
                  <li>K - Коэффициент умножения</li>
                  <li>L - Имеющихся УЖЕ оплаченных уроков</li>
                  <li>N - Количество для прибавления уроков к получившиеся сумме</li>
                </ul>
              </div>
            ),
            icon: <QuestionCircleOutlined />,
          }}
        >
          <InputNumber addonAfter={t('коэффициент умножения')} style={{ width: '100%' }} />
        </FormItem>
        {
          hasAccess(['SUPER_ADMIN']) && (
            <FormItem wrapperCol={{ offset: 15 }}>
              <Button type="primary" htmlType="submit" block loading={isPatchSettingsLoading || isPatchSettingsFetching}>
                {t('Сохранить')}
              </Button>
            </FormItem>
          )
        }
      </Form>
    </div>
  );
};

export default List;
