import React from 'react';
import cn from 'classnames';

import styles from './ContentHeader.module.scss';
import { Typography } from 'antd';

const ContentHeader = ({
  title,
  children,
}) => {

  return (
    <div className={styles.header}>
      <Typography.Title level={3}>
        {title}
      </Typography.Title>
      <div className={styles.headerActions}>
        {children}
      </div>
    </div>
  );
};

export default ContentHeader;
