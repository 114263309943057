import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import { useGetVacanciesQuery } from '../../../store/apis/administratives/vacancies';
import queryString from 'query-string';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Table } from 'antd';
import routes from '../../../router/routes';
import { useGetTutorStoriesQuery } from '../../../store/apis/administratives/tutorStories';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page = 1, pageSize = 20, sort = '-id', ...restQuery } = useQueryString();
  const navigate = useNavigate();

  const {
    data: tutorStories,
    isLoading: isTutorStoriesLoading,
    isFetching: isTurorStoriesFetching,
  } = useGetTutorStoriesQuery({ page, pageSize, sort, ...restQuery });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const columns = useMemo(() => [
    {
      title: t('Порядковый номер'),
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: t('Изображение'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (data) => (
        <img src={data} alt="" style={{ width: 150 }}/>
      ),
    },
    {
      title: t('Предмет'),
      dataIndex: 'subject',
      key: 'subject',
      render: (data) => (
        data?.name[i18n.language]
      )
    },
    {
      title: t('ФИО учителя'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (data) => (
        data?.[i18n.language]
      )
    },
    {
      title: t('Заголовок'),
      dataIndex: 'header',
      key: 'header',
      render: (data) => (
        data?.[i18n.language]
      )
    },
    {
      title: t('Текст'),
      dataIndex: 'text',
      key: 'text',
      render: (data) => (
        data?.[i18n.language]
      )
    },
    {
      title: t('Опубликован?'),
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (isPublic) => (
        isPublic ? '✅' : '❌'
      )
    },
  ], [t, i18n]);

  return (
    <div>
      <ContentHeader title={t('Отзывы учителе о платформе')}>
        <Button
          type="primary"
          htmlType="button"
          onClick={() => {
            navigate(routes.tutorStories('create'));
          }}
        >
          {t('Создать')}
        </Button>
      </ContentHeader>

      <Table
        rootClassName="overflow-scroll"
        columns={columns}
        loading={isTutorStoriesLoading || isTurorStoriesFetching}
        dataSource={tutorStories?.results}
        rowKey="id"
        onChange={handlePageChange}
        onRow={(data) => ({
          onClick: () => {
            navigate(routes.tutorStories(data.id));
          }
        })}
        pagination={{
          total: tutorStories?.count,
          current: page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </div>
  );
};

export default List;
