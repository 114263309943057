import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getQualifications: build.query({
      query: (params) => ({
        url: apiRoutes.qualifications(),
        params,
      }),
      providesTags: ['QUALIFICATIONS'],
    }),
    getQualification: build.query({
      query: (langId) => ({
        url: apiRoutes.qualifications(langId),
      }),
      providesTags: ['QUALIFICATION'],
    }),
    editQualification: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.qualifications(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['QUALIFICATIONS', 'QUALIFICATION'] }),
    }),
    createQualification: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.qualifications(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['QUALIFICATIONS', 'QUALIFICATION'] }),
    }),
  })
});

export const {
  useGetQualificationsQuery,
  useGetQualificationQuery,
  useEditQualificationMutation,
  useCreateQualificationMutation,
} = rootApi;
