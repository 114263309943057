import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiRoutes, { baseUrl } from '../apiRoutes';
import localforage from 'localforage';
import i18n from '../localization/i18n';

export const baseQuery = (url) => fetchBaseQuery({
  baseUrl: url ?? baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    headers.set('Accept-Language', i18n.language);
    headers.set('X-Time-Span-Tz', 'Asia/Tashkent');
    const credentials = await localforage.getItem('credentials');
    if (credentials && credentials.accessToken) {
      headers.set('Authorization', `Bearer ${credentials.accessToken}`);
    }
  }
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery()(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const credentials = await localforage.getItem('credentials');
    if (credentials && credentials.refreshToken) {
      const queryParams = {
        url: apiRoutes.refreshToken(),
        method: 'POST',
        body: {
          refreshToken: credentials.refreshToken,
        },
      }
      try {
        const newCredentials = await baseQuery()(queryParams, api, extraOptions);
        if (newCredentials.error) {
          await localforage.clear();
          return result;
        }
        await localforage.setItem('credentials', newCredentials.data);
        result = await baseQuery()(args, api, extraOptions);
      } catch (e) {
        await localforage.clear();
      }
    }
  }
  return result;
};

export const invalidateOn = ({ success = [], error = [] }) => {
  return (result, err) => err ? error : success;
}
