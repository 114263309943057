import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './WithdrawModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row } from 'antd';
import useToggle from '../../../hooks/useToggle';
import { useWithdrawMutation } from '../../../store/apis/finances/withdrawals';

const WithdrawModal = ({ initialValues }) => {
  const [t] = useTranslation();
  const [open, show, hide] = useToggle();
  const [
    withdrawal,
    {
      isLoading,
      isFetching,
    }
  ] = useWithdrawMutation();

  const submitForm = useCallback(async (values) => {
    try {
      const normalizedValues = {
        ...values,
        amount: values.amount * 100,
        amountWithoutTaxes: values.amountWithoutTaxes * 100,
      };

      await withdrawal(normalizedValues).unwrap();
      message.success(t('Данные сохранены'));
      hide();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [hide, t, withdrawal]);

  return (
    <>
      <Button
        type="link"
        onClick={show}
      >
        {t('Выплатить')}
      </Button>
      <Modal
        title={t('Выплатить')}
        open={open}
        onCancel={hide}
        cancelText={t('Отменить')}
        okText={t('Выплатить')}
        destroyOnClose
        footer={false}
        width={800}
      >
        <Form
          layout="horizontal"
          name="withdrawal_form"
          initialValues={{
            ...initialValues,
            amount: initialValues.amount / 100,
            amountWithoutTaxes: initialValues.amountWithoutTaxes / 100,
          }}
          labelCol={{
            lg: 10,
          }}
          onFinish={submitForm}
          disabled={isLoading || isFetching}
        >
          <Form.Item hidden name="tutorId" />
          <Form.Item label={t('Сумма (до вычета налогов)')} name="amount" required rules={[{ required: true }]}>
            <InputNumber
              controls={false}
              addonAfter="UZS"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              parser={(value) => value.replace(/\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item label={t('Сумма к выплате (после вычета налогов)')} name="amountWithoutTaxes" required rules={[{ required: true }]}>
            <InputNumber
              controls={false}
              addonAfter="UZS"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              parser={(value) => value.replace(/\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item label={t('Процент подоходного налога')} name="taxPercent" required rules={[{ required: true }]} hidden>
            <InputNumber
              controls={false}
              addonAfter="%"
              min={0}
              max={100}
            />
          </Form.Item>
          <Form.Item label={t('Комментарий')} name="comment" required rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Row align="end" gutter={16}>
            <Col>
              <Button
                onClick={hide}
              >
                {t('Отменить')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading || isFetching}
              >
                {t('Выплатить')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default WithdrawModal;
