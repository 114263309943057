// import cn from 'classnames';

import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePayAllByBookkeepingMutation } from '../../store/apis/finances/bookkeeping';

const PayAllBookkeeping = ({ id }) => {
  const [t] = useTranslation();

  const [
    payAllBookkeeping,
    { isLoading: isPaying },
  ] = usePayAllByBookkeepingMutation();

  const handlePayAll = async () => {
    try {
      await payAllBookkeeping(id).unwrap();
      message.success(t('Успешно'));
    } catch (e) {

    }
  }

  return (
    <Popconfirm
      title={t('Вы уверены?')}
      onConfirm={handlePayAll}
      okButtonProps={{
        loading: isPaying,
      }}
    >
      <Button
        type="primary"
      >
        {t('Оплатить всю ведомость')}
      </Button>
    </Popconfirm>
  );
};

export default PayAllBookkeeping;
