import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAgeCategories: build.query({
      query: (params) => ({
        url: apiRoutes.ageCategories(),
        params,
      }),
      providesTags: ['AGE_CATEGORIES'],
    }),
    getAgeCategory: build.query({
      query: (langId) => ({
        url: apiRoutes.ageCategories(langId),
      }),
      providesTags: ['AGE_CATEGORY'],
    }),
    editAgeCategory: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.ageCategories(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['AGE_CATEGORIES', 'AGE_CATEGORY'] }),
    }),
    createAgeCategory: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.ageCategories(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['AGE_CATEGORIES', 'AGE_CATEGORY'] }),
    }),
  })
});

export const {
  useGetAgeCategoriesQuery,
  useGetAgeCategoryQuery,
  useEditAgeCategoryMutation,
  useCreateAgeCategoryMutation,
} = rootApi;
