import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: (params) => ({
        url: apiRoutes.reviews(),
        params,
      }),
      providesTags: ['REVIEWS'],
    }),

    rejectReview: builder.mutation({
      query: (id) => ({
        url: apiRoutes.cancelReviews(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['REVIEWS'] }),
    }),
    approveReview: builder.mutation({
      query: (id) => ({
        url: apiRoutes.publishReviews(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['REVIEWS'] }),
    }),
  }),
});

export const {
  useGetReviewsQuery,
  useRejectReviewMutation,
  useApproveReviewMutation,
} = rootApi;
