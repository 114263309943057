import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLanguages: build.query({
      query: (params) => ({
        url: apiRoutes.languages(),
        params,
      }),
      providesTags: ['LANGUAGES'],
    }),
    getLanguage: build.query({
      query: (langId) => ({
        url: apiRoutes.languages(langId),
      }),
      providesTags: ['LANGUAGE'],
    }),
    editLanguage: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.languages(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['LANGUAGE', 'LANGUAGES'] }),
    }),
    createLanguage: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.languages(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['LANGUAGE', 'LANGUAGES'] }),
    }),
  })
});

export const {
  useGetLanguagesQuery,
  useGetLanguageQuery,
  useEditLanguageMutation,
  useCreateLanguageMutation,
} = rootApi;
