import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getSocialLinks: build.query({
      query: (params) => ({
        url: apiRoutes.socialLinks(),
        params,
      }),
      providesTags: ['SOCIAL_LINKS'],
    }),
    addSocialLinks: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.socialLinks(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SOCIAL_LINKS'] }),
    }),
    removeSocialLinks: build.mutation({
      query: (id) => ({
        url: apiRoutes.socialLinks(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['SOCIAL_LINKS'] }),
    }),
    updateSocialLinks: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.socialLinks(id),
        method: 'PUT',
        body
      }),
      invalidatesTags: invalidateOn({ success: ['SOCIAL_LINKS'] }),
    }),
  })
});

export const {
  useGetSocialLinksQuery,
  useAddSocialLinksMutation,
  useRemoveSocialLinksMutation,
  useUpdateSocialLinksMutation,
} = rootApi;
