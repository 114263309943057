// import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, Input, message, Modal, Tooltip } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useTransferLessonMutation } from '../../../store/apis/administratives/students';
import dayjs from 'dayjs';

const RescheduleLesson = ({
  id,
  lessonTime
}) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [
    transferLesson,
    { isLoading: isTransfering },
  ] = useTransferLessonMutation();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      await transferLesson({ id, body: values }).unwrap();
      message.success(t('Урок перенесен'));
      form.resetFields();
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Tooltip title={t('Перенести урок')}>
        <Button onClick={openModal} size="small" icon={<RollbackOutlined />} type="link" />
      </Tooltip>
      <Modal
        open={open}
        title={t('Перенести урок')}
        onOk={form.submit}
        onCancel={closeModal}
        okText={t('Перенести урок')}
        cancelText={t('Отменить')}
        confirmLoading={isTransfering}
      >
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            fromLessonTime: dayjs(lessonTime),
          }}
        >
          <Form.Item label={t('Текущая дата урока')} name="fromLessonTime">
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              allowClear
              disabled
            />
          </Form.Item>
          <Form.Item label={t('Дата нового урока')} name="toLessonTime">
            <DatePicker
              format="DD.MM.YYYY HH:mm"
              allowClear
              showTime
            />
          </Form.Item>
          <Form.Item label={t('Комментарий')} name="comment">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RescheduleLesson;
