import React, { useEffect } from 'react';
import cn from 'classnames';

import styles from './ChangeNameForm.module.scss';
import { Form as AntForm, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChangeStaffNameMutation } from '../../../store/apis/users';

const ChangeNameForm = ({
  open,
  initialValues,
  onClose,
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [
    changeName,
    { isLoading: isChanging }
  ] = useChangeStaffNameMutation();

  const handleFinish = async (values) => {
    try {
      await changeName({ id: initialValues.id, body: values }).unwrap();
      onClose();
      form.resetFields();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue(initialValues);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title={t('Редактировать ФИО пользователя') }
      onOk={form.submit}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      width={600}
      destroyOnClose
      confirmLoading={isChanging}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        wrapperCol={{ sm: 17 }}
        labelCol={{ sm: 7 }}
      >
        <AntForm.Item name="lastName" label={t('Фамилия')} required rules={[{ required: true }]}>
          <Input/>
        </AntForm.Item>
        <AntForm.Item name="firstName" label={t('Имя')} required rules={[{ required: true }]}>
          <Input/>
        </AntForm.Item>
        <AntForm.Item name="secondName" label={t('Отчество')}>
          <Input/>
        </AntForm.Item>
      </Form>
    </Modal>
  );
};

export default ChangeNameForm;
