import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import useQueryString from '../../../hooks/useQueryString';
import DictionaryList from '../../../containers/shared/DictionaryList';

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20 } = useQueryString();
  const { data: subject, isLoading: isSubjectLoading, isFetching: isSubjectFetching } = useGetSubjectsQuery({ page, pageSize });

  return (
    <DictionaryList
      title={t('Предмет')}
      data={subject?.results}
      dataCount={subject?.count}
      loading={isSubjectLoading || isSubjectFetching}
      detailRoute={routes.subjects}
    />
  );
};

export default List;
