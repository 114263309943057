import { joinExisting } from '../utils';

export default {
  root: () => '/',
  app: () => '/app',
  welcome: () => joinExisting(['/app/welcome']),
  teachers: (id) => joinExisting(['/app/teachers', id]),
  tariffs: (id) => joinExisting(['/app/tariffs', id]),
  reviews: (id) => joinExisting(['/app/reviews', id]),
  faqs: (id) => joinExisting(['/app/faqs', id]),
  subjects: (id) => joinExisting(['/app/subjects', id]),
  languages: (id) => joinExisting(['/app/languages', id]),
  languageLevels: (id) => joinExisting(['/app/languages-levels', id]),
  educationLevels: (id) => joinExisting(['/app/education-levels', id]),
  educationInstitution: (id) => joinExisting(['/app/education-institutions', id]),
  learningGoals: (id) => joinExisting(['/app/learning-goals', id]),
  qualifications: (id) => joinExisting(['/app/qualifications', id]),
  packageCancellationRequestsReasons: (id) => joinExisting(['/app/package-cancellation-request-reasons', id]),
  workExperienceCategories: (id) => joinExisting(['/app/work-experience-categories', id]),
  ageCategories: (id) => joinExisting(['/app/age-categories', id]),
  priceCategories: (id) => joinExisting(['/app/price-categories', id]),
  lessons: (id) => joinExisting(['/app/lessons', id]),
  students: (id) => joinExisting(['/app/students', id]),
  trialLessons: (id) => joinExisting(['/app/trial-lessons', id]),
  lessonSchedules: (id) => joinExisting(['/app/lesson-schedules', id]),
  tutorCv: () => joinExisting(['/app/tutor-cv']),
  withdrawals: (id) => joinExisting(['/app/withdrawals', id]),
  tutorBookkeeping: (id) => joinExisting(['/app/book-keeping', id]),
  tutorBookkeepingCreation: () => joinExisting(['/app/book-keeping-creation']),
  packageCancellation: () => joinExisting(['/app/package-cancellations']),
  dashboard: () => joinExisting(['/app/dashboard']),
  statsByTutor: () => joinExisting(['/app/stats-by-tutor']),
  statsByTariff: () => joinExisting(['/app/stats-by-tariff']),
  statsBySubject: () => joinExisting(['/app/stats-by-subject']),
  tutorsWithdrawals: () => joinExisting(['/app/tutors-withdrawals']),
  payments: () => joinExisting(['/app/payments']),
  successStories: () => joinExisting(['/app/success-stories']),
  socialLinks: () => joinExisting(['/app/social-links']),
  contacts: () => joinExisting(['/app/contacts']),
  legalDocuments: (id) => joinExisting(['/app/legal-documents', id]),
  notifications: (id) => joinExisting(['/app/notifications', id]),
  settings: (id) => joinExisting(['/app/settings', id]),
  accountSettings: (id) => joinExisting(['/app/account-settings', id]),
  statsBypackageCancellations: (id) => joinExisting(['/app/stats-by-package-cancellations', id]),
  debitsByTutor: (id) => joinExisting(['/app/debits-by-tutor', id]),
  lessonMovementsByTutor: (id) => joinExisting(['/app/lesson-movements-by-tutor', id]),
  vacancies: (id) => joinExisting(['/app/vacancies', id]),
  tutorStories: (id) => joinExisting(['/app/tutor-stories', id]),
  staff: (id) => joinExisting(['/app/staff', id]),
  tutorWorkTimes: (id) => joinExisting(['/app/tutor-work-times', id]),
  userNotFinishedRegistration: () => joinExisting(['/app/users-not-finished-registration']),
  videoAboutPlatform: () => joinExisting(['/app/video-about-platform']),
  smsToPlaymobile: () => joinExisting(['/app/sms-to-playmobile']),
  scheduleLogs: () => joinExisting(['/app/schedule-logs']),
};
