import React, { useCallback, useMemo } from 'react';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import { Space, Table, Tag } from 'antd';
import { formatDate, joinExisting } from '../../../utils';
import useQueryString from '../../../hooks/useQueryString';
import { useGetReviewsQuery } from '../../../store/apis/administratives/reviews';
import ApproveReview from '../../../containers/reviews/ApproveReview';
import RejectReview from '../../../containers/reviews/RejectReview';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import { useApiAliases } from '../../../apiAliases';

const List = () => {
  const [t, i18n] = useTranslation();
  const apiAliases = useApiAliases();
  const { page = 1, pageSize = 20, sort = '-id', ...restQuery } = useQueryString();
  const navigate = useNavigate();
  const {
    data: reviews,
    isLoading: isReviewsLoading,
    isFetching: isReviewsFetching,
  } = useGetReviewsQuery({ page, pageSize, sort, ...restQuery });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const columns = useMemo(() => [
    {
      title: t('Статус'),
      dataIndex: 'publicationStatus',
      key: 'publicationStatus',
      render: (status) => {
        const colors = ['gold', 'blue', 'green', 'red'];
        return (
          <Tag color={colors[status.id]}>{apiAliases[status.sysName]}</Tag>
        );
      },
    },
    {
      title: t('Дата отзыва'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('Оценка'),
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: t('Комментарий'),
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: t('От ученика'),
      dataIndex: 'student',
      key: 'student',
      render: (student) => (
        joinExisting([
          student.lastName?.[i18n.language],
          student.firstName?.[i18n.language],
          student.secondName?.[i18n.language],
        ], ' ')
      ),
    },
    {
      title: t('К учителю'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (tutor) => (
        joinExisting([
          tutor.lastName?.[i18n.language],
          tutor.firstName?.[i18n.language],
          tutor.secondName?.[i18n.language],
        ], ' ')
      ),
    },
    {
      title: t('Действия'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {
            (record.publicationStatus.sysName === 'new' || record.publicationStatus.sysName === 'cancelled')
              ? <ApproveReview reviewId={record.id}/>
              : null
          }
          {
            (record.publicationStatus.sysName === 'new' || record.publicationStatus.sysName === 'published')
              ? <RejectReview reviewId={record.id} />
              : null
          }
        </Space>
      ),
    },
  ], [i18n.language, t]);

  return (
    <div>
      <ContentHeader title={t('Отзывы к учителю')} />

      <Table
        rootClassName="overflow-scroll"
        columns={columns}
        loading={isReviewsLoading || isReviewsFetching}
        dataSource={reviews?.results}
        rowKey="id"
        onChange={handlePageChange}
        pagination={{
          total: reviews?.count,
          current: page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </div>
  );
};

export default List;
