import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Form, InputNumber, message, Select, Skeleton, Space, Typography } from 'antd';
import MainError from '../../errors/MainError';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import { useEditLearningGoalMutation, useGetLearningGoalQuery } from '../../../store/apis/dictionaries/learning-goals';
import { dictionaryToSelect } from '../../../utils';
import {
  useEditWorkExperienceCategoryMutation,
  useGetWorkExperienceCategoryQuery
} from '../../../store/apis/dictionaries/work-experience-categories';

const Details = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [edit, { isLoading }] = useEditWorkExperienceCategoryMutation();

  const {
    data: dictionaryData,
    isLoading: isDictionaryLoading,
    isFetching: isDictionaryFetching,
    error: dictionaryError,
  } = useGetWorkExperienceCategoryQuery(id);

  const handleSubmit = useCallback(async (body) => {
    try {
      await edit({ id, body }).unwrap();
      message.success(t('Категория сохранена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [edit, id, t]);

  const handleExit = useCallback(() => {
    navigate(routes.workExperienceCategories());
  }, [navigate]);

  if (isDictionaryLoading || isDictionaryFetching) return <Skeleton />
  if (dictionaryError) return <MainError error={dictionaryError} />

  return (
    <div>
      <ContentHeader title={dictionaryData.sysName} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{
          ...dictionaryData,
        }}
        loading={isLoading}
        saveTitle={t('Сохранить')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >
        <Typography.Paragraph italic type="secondary">
          {t('Далее необходимо заполнить значение от и до')}, {t('например')}:
          <ul>
            <li>
              {t('если название категории "до 1 года", то минимальное значение должно быть 0, а максимальное - 1')}
            </li>
            <li>
              {t('если название категории "от 2 до 5 лет", то минимальное значение должно быть 2, а максимальное - 5')}
            </li>
            <li>
              {t('если название категории "от 6 лет", то минимальное значение должно быть 6, а максимальное оставить пустым')}
            </li>
            <li>
              {t('если название категории "Не имеет значения", то оба поля оставить пустыми')}
            </li>
          </ul>
        </Typography.Paragraph>
        <Space size="large">
          <Form.Item name="minimum" label={t('Минимальное значение')}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="maximum" label={t('Максимальное значение')}>
            <InputNumber />
          </Form.Item>
        </Space>
      </DictionaryForm>
    </div>
  );
};

export default Details;
