import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getUsersNotFinishedRegistration: build.query({
      query: (params) => ({
        url: apiRoutes.usersNotFinishedRegistration(),
        params,
      }),
      providesTags: ['USERS_NOT_FINISHED_REGISTRATION'],
    }),
  })
});

export const {
  useGetUsersNotFinishedRegistrationQuery,
} = rootApi;
