// import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import { useGetScheduleLogsQuery, useLazyGetScheduleLogsQuery } from '../../../store/apis/reports/dashboard';
import React, { useCallback, useMemo, useState } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { useNavigate } from 'react-router';
import { Button, Modal, Skeleton, Space, Table, Typography } from 'antd';
import ContentHeader from '../../../components/ContentHeader';
import { identity, sort } from 'ramda';
import { formatDate } from '../../../utils';
import ScheduleLogsContextModal from '../ScheduleLogsContextModal';
import Filters from '../Filters';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const navigate = useNavigate();
  const [showDeveloperInfo, setShowDeveloperInfo] = useState(false);
  const [showContext, setShowContext] = useState(false);

  const {
    data: scheduleLogs,
    isLoading: isScheduleLogsLoading,
    isFetching: isScheduleLogsFetching,
  } = useGetScheduleLogsQuery({
    page,
    pageSize,
    ...restQuery,
  });

  const handlePageChange = useCallback((page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ ...restQuery, page: page.current, pageSize: page.pageSize }),
    });
  }, [navigate, restQuery]);

  const columns = [
    {
      title: t('Дата'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('Событие'),
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: t('Причина'),
      dataIndex: 'cause',
      key: 'cause',
    },
    {
      title: t('Студент'),
      dataIndex: 'student',
      key: 'student',
      render: (student) => (
        <Link to={routes.students(student.id)}>{student.lastName} {student.firstName}</Link>
      ),
    },
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (tutor) => (
        <Link to={routes.teachers(tutor.id)}>{tutor.lastName} {tutor.firstName}</Link>
      ),
    },
    showDeveloperInfo
      ? {
        title: t('Инфо для разработчиков'),
        dataIndex: 'developerDetails',
        key: 'developerDetails',
        render: (data, raw) => (
          <div>
            <Space>
              <Typography.Text>Сервис: </Typography.Text>
              <Typography.Text code>{raw.service}</Typography.Text>
            </Space>
            <pre style={{ width: 180 }}>
            {JSON.stringify(data, 0, 2)}
          </pre>
          </div>
        )
      } : null,
    {
      title: t('Действие'),
      dataIndex: 'causeId',
      key: 'causeId',
      render: (causeId) => (
        <Button type="link" size="small" onClick={() => setShowContext(causeId)}>{t('Показать контекст')}</Button>
      ),
    },
  ];

  return (
    <div>
      <div>
        <ContentHeader title={t('Логи отмен и переносов')}>
          <Filters />
        </ContentHeader>
      </div>
      <div style={{ marginBottom: 20 }}>
        <Button onClick={() => setShowDeveloperInfo(!showDeveloperInfo)}>
          {
            showDeveloperInfo
              ? t('Скрыть инфо для разработчиков')
              : t('Показать инфо для разработчиков')
          }
        </Button>
      </div>
      <div>
        <Table
          rootClassName="overflow-scroll"
          loading={isScheduleLogsLoading || isScheduleLogsFetching}
          rowKey="id"
          columns={columns.filter(identity)}
          dataSource={scheduleLogs?.results}
          onChange={handlePageChange}
          pagination={{
            total: scheduleLogs?.count,
            current: page,
            locale: { items_per_page: `/ ${t('на странице')}` },
            pageSize,
            showSizeChanger: true,
            showTitle: true,
            pageSizeOptions: [20, 50, 100],
          }}
        />
      </div>
      <ScheduleLogsContextModal
        causeId={showContext}
        onClose={() => {
          setShowContext(false);
        }}
      />
    </div>
  );
};

export default List;
