import { rootApi } from './index';
import apiRoutes from '../../apiRoutes';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getMe: build.query({
      query: (params) => ({
        url: apiRoutes.me(),
        params,
      }),
      providesTags: ['ME'],
    }),
  }),
});

export const {
  useGetMeQuery,
} = rootApi;
