import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { theme as antTheme } from 'antd';
import router from './router';

const theme = {
  primary: '#00008B',
  menuItemSelected: '#314b64',
  menuItemTextSelected: '#001529'
};

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.primary,
          // colorBgLayout: 'red', // Фон у Layout (как будто у боди)
        },
        components: {
          Menu: {
            colorItemBgSelected: theme.menuItemSelected,
            // colorItemTextSelected: theme.menuItemTextSelected,
          }
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
