import React from 'react';
import cn from 'classnames';

import styles from './StatsTabs.module.scss';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import ContactWithStudent from '../ContactWithStudent';
import LessonHistoryList from '../LessonHistoryList';
import Packages from '../Packages';
import Schedules from '../Schedules';
import StudentWithdrawals from '../StudentWithdrawals';

const StatsTabs = () => {
  const [t] = useTranslation();

  const items = [
    {
      key: 'contact',
      label: t('Контакт с пользователем'),
      children: <ContactWithStudent />,
    },
    {
      key: 'lessonHistory',
      label: t('История уроков'),
      children: <LessonHistoryList />,
    },
    {
      key: 'packages',
      label: t('Пакеты'),
      children: <Packages />,
    },
    {
      key: 'returns',
      label: t('Возвраты средств'),
      children: <StudentWithdrawals />,
    },
    {
      key: 'schedule',
      label: t('Текущее расписание'),
      children: <Schedules />,
    },
  ];

  return (
    <Tabs defailtActiveKey="contact" items={items} />
  );
};

export default StatsTabs;
