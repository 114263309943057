// import cn from 'classnames';

import { useParams } from 'react-router';
import { useGetBookkeepingDetailsQuery } from '../../../store/apis/finances/bookkeeping';
import { Col, Empty, Row, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import PayAllBookkeeping from '../../../containers/bookkeeping/PayAllBookkeeping';
import BookkeepingDetailsTable from '../../../containers/bookkeeping/BookkeepingDetailsTable';

const BookkeepingDetails = () => {
  const { id } = useParams();
  const [t] = useTranslation();

  const {
    data: bookkeeping,
    isLoading: isBookkeepingLoading,
  } = useGetBookkeepingDetailsQuery(id, { skip: !id });


  if (isBookkeepingLoading) return <Skeleton />
  if (!bookkeeping) return <Empty description={t('Не удалось получить данные, попробуйте позже')} />

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <Typography.Title level={4}>{t('Детали ведомости')} ({formatDate(bookkeeping.fromDate)} - {formatDate(bookkeeping.toDate)})</Typography.Title>
        </Col>
        <Col>
          <PayAllBookkeeping id={id} />
        </Col>
      </Row>
      <BookkeepingDetailsTable
        data={bookkeeping.paychecks}
      />
    </div>
  );
};

export default BookkeepingDetails;
