import { useTranslation } from 'react-i18next';

export const useApiAliases = () => {
  const [t] = useTranslation();

  return {
    sms: t('СМС'),
    mail: t('Электронная почта'),
    bot: t('Телеграм БОТ'),
    system: t('Системные'),
    declined: t('Отклонено'),
    approved: t('Одобрено'),
    new: t('Новое'),
    published: t('Опубликовано'),
    cancelled: t('Отменено'),
  };
};
