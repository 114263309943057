import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './EditNotification.module.scss';
import { Button, Form, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import LanguageInput from '../../../components/LanguagesInput';
import { useUpdateNotificationMutation } from '../../../store/apis/administratives/notifications';

const EditNotification = ({ id, initialValues }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [form] = Form.useForm();

  const [updateNotification] = useUpdateNotificationMutation();

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      await updateNotification({ id, body: values }).unwrap();
      message.success(t('Данные изменены'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, id, t, updateNotification]);

  return (
    <>
      <Button
        type="primary"
        size="small"
        onClick={open}
      >
        {t('Редактировать описание')}
      </Button>
      <Modal
        open={isOpen}
        onCancel={close}
        onOk={handleSubmit}
        title={t('Редактирование описание')}
        destroyOnClose
        width={800}
      >
        <Form
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item label={t('Название')} required rules={[{ required: true }]}>
            <LanguageInput name="name" />
          </Form.Item>
          <Form.Item label={t('Описание')}>
            <LanguageInput name="description" type="textarea" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditNotification;
