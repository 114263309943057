import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './Details.module.scss';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import {
  useAddChapterMutation, useDeleteChapterMutation,
  useGetLegalDocumentDetailsQuery
} from '../../../store/apis/administratives/legalDocuments';
import { Button, Col, Empty, Form, InputNumber, message, Popconfirm, Row, Skeleton, Tag, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import FetchErrorContent from '../../../containers/shared/FetchErrorContent';
import { formatDate } from '../../../utils';
import Edit from '../Edit';
import LanguageInput from '../../../components/LanguagesInput';
import ChapterEdit from '../ChapterEdit';

const { Text, Title } = Typography;

const Details = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const [showChapterForm, setShowChapterForm] = useState(true);

  const {
    data: legalDocument,
    isLoading: isLegalDocumentLoading,
    isFetching: isLegalDocumentFetching,
    error,
  } = useGetLegalDocumentDetailsQuery({ id });

  const [
    addChapter,
    {
      isLoading: isAddChapterLoading,
      isFetching: isAddChapterFetching,
    }
  ] = useAddChapterMutation();

  const [
    deleteChapter,
    {
      isLoading: isDeleteChapterLoading,
      isFetching: isDeleteChapterFetching,
    }
  ] = useDeleteChapterMutation();

  const handleAddChapter = useCallback(async (values) => {
    try {
      await addChapter(values).unwrap();
      message.success(t('Часть документа была добавлена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [addChapter, t]);

  const handleDeleteChapter = useCallback((chapterId) => async () => {
    try {
      await deleteChapter(chapterId).unwrap();
      message.success(t('Часть документа удалена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [deleteChapter, t]);

  if (isLegalDocumentLoading || isLegalDocumentFetching) return (
    <Skeleton loading />
  );

  if (error) return (
    <FetchErrorContent error={error} />
  );

  return (
    <div>
      <ContentHeader
        title={legalDocument.header[i18n.language]}
      >
        {t('Последнее изменение')}: {formatDate(legalDocument.editedTime, true)}
      </ContentHeader>

      <Row gutter={16} justify="center">
        <Col lg={6}>
          <Tag color={legalDocument.isPublic ? 'green' : 'red'}>{legalDocument.isPublic ? t('Опубликован') : t('Черновик')}</Tag>
        </Col>
        <Col lg={6}>
          <Text>{t('Системное название')}:</Text>
          {' '}
          <Text code>{legalDocument.alias}</Text>
        </Col>
        <Col lg={6}>
          <a href={legalDocument.pdfUrl} download>{t('Скачать PDF')}</a>
        </Col>
        <Col lg={6}>
          <a href={legalDocument.docxUrl} download>{t('Скачать DOCX')}</a>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <Edit
            id={legalDocument.id}
            initialValues={legalDocument}
          />
        </Col>
      </Row>


      <Row style={{ marginTop: 20 }} gutter={20} justify="center">
        <Col md={24}>
          <Title level={4}>{t('Части документа')}</Title>
        </Col>
        {
          (legalDocument.chapters && legalDocument.chapters.length > 0)
            ? (
              legalDocument.chapters.map(({ id, header, description, text, orderId }) => (
                <Col md={24} key={id} style={{ marginTop: 16, marginBottom: 16 }}>
                  <Row gutter={16}>
                    <Col md={1}>
                      {orderId}
                    </Col>
                    <Col md={8}>
                      <Title level={5}>{header[i18n.language]}</Title>
                      <Text italic>{description[i18n.language]}</Text>
                    </Col>
                    <Col md={14}>
                      <pre className="pre">{text[i18n.language]}</pre>
                    </Col>
                    <Col md={1}>
                      <ChapterEdit
                        id={id}
                        initialValues={{ orderId, text, description, header }}
                        legalDocumentId={legalDocument.id}
                      />
                      <Popconfirm
                        title={t('Вы уверены что хоитет удалить?')}
                        onConfirm={handleDeleteChapter(id)}
                      >
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          danger
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              ))
            ) : (
              <Empty description={t('Частей документа не найдено')} />
            )
        }
      </Row>

      <Row style={{ marginTop: 20 }} >
        <Col>
          <Title level={4}>{t('Добавить часть документа')}</Title>
        </Col>
        <Col md={24}>
          <Form
            layout="vertical"
            onFinish={handleAddChapter}
            disabled={isAddChapterLoading || isAddChapterFetching}
          >
            <Form.Item name="legalDocumentId" hidden initialValue={legalDocument.id} />
            <Form.Item name="orderId" label={t('Порядковый номер')}>
              <InputNumber />
            </Form.Item>
            <Form.Item name="header" label={t('Заголовок')} required rules={[{ required: true }]}>
              <LanguageInput name="header" />
            </Form.Item>
            <Form.Item name="description" label={t('Описание')}>
              <LanguageInput type="textarea" name="description" />
            </Form.Item>
            <Form.Item name="text" label={t('Текст')} required rules={[{ required: true }]}>
              <LanguageInput type="textarea" name="text" />
            </Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                >
                  {t('Добавить')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
