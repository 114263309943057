import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Form, InputNumber, message, Space, Typography } from 'antd';
import routes from '../../../router/routes';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useCreateLearningGoalMutation } from '../../../store/apis/dictionaries/learning-goals';
import { useCreateWorkExperienceCategoryMutation } from '../../../store/apis/dictionaries/work-experience-categories';
import { useCreateAgeCategoryMutation } from '../../../store/apis/dictionaries/age-categories';

const Create = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [create, { isFetching, isLoading }] = useCreateAgeCategoryMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await create({ body: values }).unwrap();
      message.success(t('Категория создана'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [create, t]);

  const handleExit = useCallback(() => {
    navigate(routes.ageCategories());
  }, [navigate]);

  return (
    <div>
      <ContentHeader title={t('Создать новую категорию возраста')} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{}}
        loading={isFetching || isLoading}
        saveTitle={t('Сохранить и создать еще')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >
        <Typography.Paragraph italic type="secondary">
          {t('Далее необходимо заполнить значение от и до')}, {t('например')}:
          <ul>
            <li>
              {t('если название категории "до 1 года", то минимальное значение должно быть 0, а максимальное - 1')}
            </li>
            <li>
              {t('если название категории "от 2 до 5 лет", то минимальное значение должно быть 2, а максимальное - 5')}
            </li>
            <li>
              {t('если название категории "от 6 лет", то минимальное значение должно быть 6, а максимальное оставить пустым')}
            </li>
            <li>
              {t('если название категории "Не имеет значения", то оба поля оставить пустыми')}
            </li>
          </ul>
        </Typography.Paragraph>
        <Space size="large">
          <Form.Item name="minimum" label={t('Минимальное значение')}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="maximum" label={t('Максимальное значение')}>
            <InputNumber />
          </Form.Item>
        </Space>
      </DictionaryForm>
    </div>
  );
};

export default Create;
