import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  accessToken: null,
  refreshToken: null,
  id: null,
  user: null,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const useCredentials = () => {
  return useSelector((state) => state.auth);
};

export const {
  setCredentials,
} = auth.actions;
