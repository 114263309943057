import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { Button, message, Popconfirm } from 'antd';
import { useMarkAsResolvedMutation } from '../../../store/apis/tutorCv';

/**
 *
 * @param id
 * @param {'approved'|'declined'} status
 * @returns {JSX.Element}
 * @constructor
 */
const MarkAsApproved = ({ id, status }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [markAsResolved, { isLoading, isFetching }] = useMarkAsResolvedMutation();

  const statuses = useMemo(() => ({
    approved: {
      id: 2,
      buttonText: t('Принять'),
    },
    declined: {
      id: 3,
      buttonText: t('Отказать'),
    },
  }), [t]);

  const currentStatus = statuses[status];

  const mark = useCallback(async () => {
    try {
      const body = {
        resolutionStatus: currentStatus.id,
      };
      await markAsResolved({ id, body }).unwrap();
      message.success(t('Успешно выполнено'));
      close();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [close, currentStatus.id, id, markAsResolved, t]);

  return (
    <Popconfirm
      open={isOpen}
      title={t('Вы уверены?')}
      okText={t('Да')}
      cancelText={t('Нет')}
      onConfirm={mark}
      onCancel={close}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
    >
      <Button
        size="small"
        type="link"
        danger={currentStatus.id === 3}
        onClick={open}
        disabled={!id}
      >
        {
          currentStatus.buttonText
        }
      </Button>
    </Popconfirm>
  );
};

export default MarkAsApproved;
