import React, { useMemo } from 'react';
import { Input, Space, Form } from 'antd';

const flagStyles = {
  position: 'absolute',
  top: 0,
  right: 8,
  zIndex: 1,
}

/**
 *
 * @param name
 * @param required
 * @param rows
 * @param {('input'|'textarea')} type
 * @returns {JSX.Element}
 * @constructor
 */
const LanguageInput = ({
  name,
  required = false,
  type = 'input',
  rows = 10,
}) => {

  const inputs = useMemo(() => ({
    input: Input,
    textarea: Input.TextArea,
  }), []);

  const InputComponent = inputs[type];

  return (
    <Space.Compact block>
      <div style={{ position: 'relative', width: '33.33333%' }}>
        {
          type === 'textarea' && (
            <span style={flagStyles}>🇷🇺</span>
          )
        }
        <Form.Item
          name={[name, 'ru']}
          rules={[{ required, }]}
        >
          <InputComponent suffix="🇷🇺" style={{ resize: 'none' }} rows={rows} />
        </Form.Item>
      </div>
      <div style={{ position: 'relative', width: '33.33333%' }}>
        {
          type === 'textarea' && (
            <span style={flagStyles}>🇺🇿</span>
          )
        }
        <Form.Item
          name={[name, 'uz']}
          rules={[{ required }]}
        >
          <InputComponent suffix="🇺🇿" style={{ resize: 'none' }} rows={rows} />
        </Form.Item>
      </div>
      <div style={{ position: 'relative', width: '33.33333%' }}>
        {
          type === 'textarea' && (
            <span style={flagStyles}>🇬🇧</span>
          )
        }
        <Form.Item
          name={[name, 'en']}
          rules={[{ required }]}
        >
          <InputComponent suffix="🇬🇧" style={{ resize: 'none' }} rows={rows} />
        </Form.Item>
      </div>
    </Space.Compact>
  );
};

export default LanguageInput;
