import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';

import styles from './Details.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useCreateContactsMutation,
  useGetContactsQuery, useUpdateContactsMutation,
} from '../../../store/apis/administratives/contacts';
import { Button, Col, Form, Input, message, Row, Space, Typography } from 'antd';
import { head } from 'ramda';
import LanguageInput from '../../../components/LanguagesInput';

const { Title, Text } = Typography;

const Details = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const {
    data: contacts,
    isLoading,
    isFetching,
  } = useGetContactsQuery();

  const [
    createContacts,
    {
      isLoading: isCreateContactsLoading,
      isFetching: isCreateContactsFetching,
    },
  ] = useCreateContactsMutation();
  const [
    updateContacts,
    {
      isLoading: isUpdateContactsLoading,
      isFetching: isUpdateContactsFetching,
    },
  ] = useUpdateContactsMutation();

  const handleSubmit = useCallback(async (values) => {
    const { id, ...restBody } = values;
    const body = {
      ...restBody,
      isPublic: true,
    };
    try {
      if (id) {
        await updateContacts({ id, body }).unwrap();
      } else {
        await createContacts(body).unwrap();
      }
      message.success(t('Данные сохранены'))
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [createContacts, t, updateContacts]);

  useEffect(() => {
    if (contacts && contacts.results) {
      const mainContacts = contacts?.results ? head(contacts.results) : {};
      form.setFieldsValue(mainContacts);
    }
  }, [contacts, form]);

  const isFormDisabled = isCreateContactsLoading
  || isCreateContactsFetching
  || isUpdateContactsLoading
  || isUpdateContactsFetching
  || isLoading
  || isFetching;

  return (
    <div>
      <Title level={3}>{t('Контакты')}</Title>
      <Form
        form={form}
        onFinish={handleSubmit}
        disabled={isFormDisabled}
        labelCol={{
          md: 7,
          lg: 7,
        }}
      >
        <Form.Item name="id" hidden />
        <Form.Item name="phoneNumber" label={t('Номер телефона')}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label={t('Электронная почта')}>
          <Input />
        </Form.Item>
        <Form.Item name="juridicName" label={t('Юридическое название')}>
          <Input />
        </Form.Item>
        <Form.Item name="tin" label={t('ИНН')}>
          <Input />
        </Form.Item>
        <Form.Item name="activityCode" label={t('ОКЭД')}>
          <Input />
        </Form.Item>
        <Form.Item name="bankCode" label={t('МФО')}>
          <Input />
        </Form.Item>
        <Form.Item label={t('Адрес')}>
          <LanguageInput name="address" />
        </Form.Item>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                block
              >
                {t('Сохранить')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Details;
