import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChangeStaffPasswordMutation } from '../../../store/apis/users';

const ChangePasswordForm = ({
  open,
  initialValues,
  onClose,
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [
    changePassword,
    { isLoading: isChanging }
  ] = useChangeStaffPasswordMutation();

  const handleFinish = async (values) => {
    try {
      await changePassword({ id: initialValues.id, body: values }).unwrap();
      onClose();
      form.resetFields();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue(initialValues);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title={t('Изменить пароль пользователя') }
      onOk={form.submit}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      width={600}
      destroyOnClose
      confirmLoading={isChanging}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        wrapperCol={{ sm: 17 }}
        labelCol={{ sm: 7 }}
      >
        <Form.Item name="password" label={t('Пароль')} required rules={[{ required: true }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          label={t('Повторите пароль')}
          required
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordForm;
