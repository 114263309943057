import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getCredentials } from '../utils';
import routes from './routes';
// import cn from 'classnames';

const ProtectRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    getCredentials()
      .then((credentials) => {
        if (!credentials || !credentials.accessToken) {
          navigate(routes.root(), { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
        navigate(routes.root(), { replace: true });
      })
  }, [navigate]);

  return children;
};

export default ProtectRoute;
