import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetWithdrawalsQuery } from '../../../store/apis/finances/withdrawals';
import { Avatar, Card, Col, Row, Skeleton, Space, Table, Tag, Typography } from 'antd';
import ContentHeader from '../../../components/ContentHeader';
import { formatDate, formatNumber } from '../../../utils';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import WithdrawModal from '../WithdrawModal';
import usePermissions from '../../../hooks/usePermissions';

const {
  Text,
  Title,
  Link: AntLink,
} = Typography;

const List = () => {
  const [t, i18n] = useTranslation();
  const hasAccess = usePermissions();

  const {
    data: withdrawals,
    isLoading,
    isFetching,
  } = useGetWithdrawalsQuery();

  const columns = useMemo(() => [
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (data) => (
        <Row align="middle" gutter={8} wrap={false}>
          <Col>
            <Avatar src={data.photoUrl} />
          </Col>
          <Col>
            <Space direction="vertical" size="small">
              {
                hasAccess(['ADMIN', 'SUPER_ADMIN'])
                  ? (
                    <Link to={routes.teachers(data.id)}>
                      {data.fullName?.[i18n.language]}
                    </Link>
                  ) : data.fullName?.[i18n.language]
              }
              <Text type="secondary">{data.shortInfo?.[i18n.language]}</Text>
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Сумма до вычетов налогов (сум)'),
      dataIndex: 'amount',
      key: 'amount',
      render: (data) => formatNumber(data / 100),
    },
    {
      title: t('Сумма после вычета налогов (сум)'),
      dataIndex: 'amountWithoutTaxes',
      key: 'amountWithoutTaxes',
      render: (data) => formatNumber(data / 100),
    },
    {
      title: t('Действия'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, raw) => (
        <WithdrawModal
          initialValues={{
            ...raw,
            tutorId: raw.tutor.id,
          }}
        />
      ),
    },
  ], [i18n.language, t]);

  if (isLoading || isFetching) return <Skeleton loading />

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Учителя, ожидающие выплат')}>

        </ContentHeader>
      </div>
      <div className={styles.body}>
        <Table
          rootClassName="overflow-scroll"
          columns={columns}
          dataSource={withdrawals}
          rowKey="id"
          rowClassName={styles.bodyRow}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default List;
