import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCancelLessonMutation } from '../../../store/apis/administratives/students';
import { CloseOutlined } from '@ant-design/icons';

const CancelLesson = ({ id, lessonTime }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [
    cancelLesson,
    { isLoading: isCancelling },
  ] = useCancelLessonMutation();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      await cancelLesson({ id, body: values }).unwrap();
      message.success(t('Урок отменен'));
      form.resetFields();
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Tooltip title={t('Отменить урок')}>
        <Button danger onClick={openModal} size="small" icon={<CloseOutlined />} type="link" />
      </Tooltip>
      <Modal
        open={open}
        title={t('Отменить урок')}
        onOk={form.submit}
        onCancel={closeModal}
        okText={t('Отменить урок')}
        cancelText={t('Отменить')}
        confirmLoading={isCancelling}
        destroyOnClose
      >
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item hidden name="lessonTime" initialValue={lessonTime} />
          <Form.Item label={t('Комментарий')} name="comment">
            <Input.TextArea rows={6} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CancelLesson;
