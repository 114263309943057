import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBookkeepings: builder.query({
      query: (params) => ({
        url: apiRoutes.bookkeeping(),
        params,
      }),
      providesTags: ['BOOKKEEPINGS'],
    }),
    getBookkeepingDetails: builder.query({
      query: (id) => ({
        url: apiRoutes.bookkeeping(id),
      }),
      providesTags: ['BOOKKEEPING_DETAILS'],
    }),
    createBookkeeping: builder.mutation({
      query: (body) => ({
        url: apiRoutes.bookkeeping(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['BOOKKEEPINGS'] }),
    }),
    payAllByBookkeeping: builder.mutation({
      query: (id) => ({
        url: apiRoutes.bookkeepingPayAll(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['BOOKKEEPINGS', 'BOOKKEEPING_DETAILS'] }),
    }),
    payOneByPaycheck: builder.mutation({
      query: (id) => ({
        url: apiRoutes.bookkeepingPayOne(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['BOOKKEEPINGS', 'BOOKKEEPING_DETAILS'] }),
    }),
    deleteBookkeeping: builder.mutation({
      query: (id) => ({
        url: apiRoutes.bookkeeping(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['BOOKKEEPINGS', 'BOOKKEEPING_DETAILS'] }),
    }),
    getBookkeepingDetailsByTutor: builder.query({
      query: (params) => ({
        url: apiRoutes.bookkeepingDetailsByTutor(),
        params,
      }),
      providesTags: ['BOOKKEEPING_DETAILS_BY_TUTOR'],
    }),
    bookkeepingOnFly: builder.mutation({
      query: (body) => ({
        url: apiRoutes.bookkeepingOnFly(),
        method: 'POST',
        body
      }),
    }),

  }),
});

export const {
  useGetBookkeepingsQuery,
  useGetBookkeepingDetailsQuery,
  useCreateBookkeepingMutation,
  usePayAllByBookkeepingMutation,
  usePayOneByPaycheckMutation,
  useDeleteBookkeepingMutation,
  useGetBookkeepingDetailsByTutorQuery,
  useBookkeepingOnFlyMutation,
} = rootApi;
