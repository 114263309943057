import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './Form.module.scss';
import { Button, Modal, Form as AntForm, Input, InputNumber, Checkbox, Space, message, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import { normFile } from '../../../utils';
import Uploader from '../../../containers/shared/Uploader';
import LanguageInput from '../../../components/LanguagesInput';
import {
  useAddSuccessStoryMutation,
  useUpdateSuccessStoryMutation
} from '../../../store/apis/administratives/successStories';
import { EditFilled } from '@ant-design/icons';

const FormItem = AntForm.Item;

const Form = ({
  mode = 'create',
  initialValues = {},
  id,
}) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [formInstance] = AntForm.useForm();

  const [
    createSuccessStory,
    {
      isLoading: isCreateSuccessStoryLoading,
      isFetching: isCreateSuccessStoryFetching,
    }
  ] = useAddSuccessStoryMutation();

  const [
    editSuccessStory,
    {
      isLoading: isEditSuccessStoryLoading,
      isFetching: isEditSuccessStoryFetching,
    }
  ] = useUpdateSuccessStoryMutation();

  const formOptions = useMemo(() => ({
    create: {
      title: t('Создать историю успеха'),
      okText: t('Создать'),
      successMessage: t('История успеха создана'),
      operateAction: createSuccessStory,
      isLoading: isCreateSuccessStoryLoading || isCreateSuccessStoryFetching,
    },
    edit: {
      title: t('Редактировать историю успеха'),
      okText: t('Редактировать'),
      successMessage: t('История успеха изменена'),
      operateAction: editSuccessStory,
      isLoading: isEditSuccessStoryLoading || isEditSuccessStoryFetching,
    },
  }), [createSuccessStory, editSuccessStory, isCreateSuccessStoryFetching, isCreateSuccessStoryLoading, isEditSuccessStoryFetching, isEditSuccessStoryLoading, t]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await formInstance.validateFields();
      const body = {
        ...values,
      }

      await formOptions[mode].operateAction({ body, id }).unwrap();
      message.success(formOptions[mode].successMessage);
      close();
      formInstance.resetFields();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [close, formInstance, formOptions, id, mode]);

  const handleCancel = useCallback(() => {
    close();
    formInstance.resetFields();
  }, [close, formInstance]);

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button
              type="primary"
              onClick={open}
            >
              {t('Создать')}
            </Button>
          ) : (
            <Button
              htmlType="button"
              onClick={open}
              type="link"
              icon={<EditFilled />}
            />
          )
      }

      <Modal
        open={isOpen}
        title={formOptions[mode].title}
        okText={formOptions[mode].okText}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={handleCancel}
        maskClosable={false}
        confirmLoading={formOptions[mode].isLoading}
        destroyOnClose
        width={1200}
      >
        <AntForm
          form={formInstance}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={formOptions[mode].isLoading}
          layout="vertical"
          // name="success_story_form"
          initialValues={initialValues}
        >
          <Row>
            <Col xs={8}>
              <FormItem
                label={t('Фото обложки (RU)')}
                name={['imagePath', 'ru']}
                getValueFromEvent={normFile}
              >
                <Uploader
                  maxCount={1}
                  multiple={false}
                  group="success_story_covers"
                  existedFileUrl={initialValues.imageUrl?.ru ?? null}
                />
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                label={t('Фото обложки (UZ)')}
                name={['imagePath', 'uz']}
                getValueFromEvent={normFile}
              >
                <Uploader
                  maxCount={1}
                  multiple={false}
                  group="success_story_covers"
                  existedFileUrl={initialValues.imageUrl?.uz ?? null}
                />
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                label={t('Фото обложки (EN)')}
                name={['imagePath', 'en']}
                getValueFromEvent={normFile}
              >
                <Uploader
                  maxCount={1}
                  multiple={false}
                  group="success_story_covers"
                  existedFileUrl={initialValues.imageUrl?.en ?? null}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem label={t('Ссылка на презентационное видео (YouTube)')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="videoUrl" />
          </FormItem>
          <FormItem name="orderId" label={t('Порядковый номер')} rules={[{ required: true }]}>
            <InputNumber />
          </FormItem>
          <FormItem name="isPublic" valuePropName="checked" >
            <Checkbox>{t('Опубликован')}</Checkbox>
          </FormItem>
          <FormItem label={t('Фамилия')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="lastName" />
          </FormItem>
          <FormItem label={t('Имя')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="firstName" />
          </FormItem>
          <FormItem label={t('Описание')} required style={{ marginBottom: 0 }}>
            <LanguageInput name="description" />
          </FormItem>
        </AntForm>
      </Modal>
    </>
  );
};

export default Form;
