import React, { useCallback } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, message, Modal, Select, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../hooks/useToggle';
import dayjs from 'dayjs';
import Uploader from '../../shared/Uploader';
import { dictionaryToSelect, normFile } from '../../../utils';
import { useGetLearningGoalsQuery } from '../../../store/apis/dictionaries/learning-goals';
import { useCreateTutorMutation, useEditTutorPersonalMutation } from '../../../store/apis/administratives/tutors';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';
import { head } from 'ramda';
import LanguageInput from '../../../components/LanguagesInput';

const FormItem = Form.Item;

const PersonalTeacherDataForm = ({
  initialValues = {},
  mode = 'create',
}) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, showModal, hideModal] = useToggle();

  const actionHooks = {
    create: useCreateTutorMutation,
    edit: useEditTutorPersonalMutation,
  };
  const [operateAction, { isLoading: isOperationLoading, isFetching: isOperationFetching }] = actionHooks[mode]();
  const { data: learningGoals, isLoading: isLearningGoalsLoading, isFetching: isLearningGoalsFetching } = useGetLearningGoalsQuery({ page: -1 });
  const { data: subjects, isLoading: isSubjectsLoading, isFetching: isSubjectsFetching } = useGetSubjectsQuery({ page: -1 });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
        birthdate: values.birthdate ? values.birthdate.format('YYYY-MM-DD') : null,
        teachingSinceDate: values.teachingSinceDate ? values.teachingSinceDate.format('YYYY-MM-DD') : null,
        subjects: [values.subjects],
      };

      await operateAction({ id: initialValues.id, body }).unwrap();
      message.success(mode === 'create' ? t('Учитель создан') : t('Данные изменены'));
      form.resetFields();
      hideModal();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [form, operateAction, initialValues.id, mode, t]);

  const actualInitialValues = {
    photoPath: initialValues.photoPath,
    isActive: initialValues.isActive,
    lastName: initialValues.lastName,
    firstName: initialValues.firstName,
    secondName: initialValues.secondName,
    isPromote: initialValues.isPromote,
    birthdate: initialValues.birthdate ? dayjs(initialValues.birthdate) : null,
    price: initialValues.price,
    teachingSinceDate: initialValues.teachingSinceDate ? dayjs(initialValues.teachingSinceDate) : null,
    introductionVideoUrl: initialValues.introductionVideoUrl,
    introductionText: initialValues.introductionText,
    learningGoals: initialValues.learningGoals ? initialValues.learningGoals.map((goal) => goal.id) : [],
    subjects: initialValues.subjects ? head(initialValues.subjects)?.id : undefined,
  };

  return (
    <>
      {
        mode === 'create'
          ? (
            <Button
              type="primary"
              onClick={showModal}
            >
              {t('Создать учителя')}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={showModal}
            >
              {t('Изменить презентационные данные')}
            </Button>
          )
      }
      <Modal
        open={isModalOpen}
        title={mode === 'create' ? t('Создать учителя') : t('Изменить презентационные данные')}
        okText={mode === 'create' ? t('Создать') : t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={hideModal}
        maskClosable={false}
        confirmLoading={isOperationLoading || isOperationFetching}
        width="90%"
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={() => {
            console.log('finish');
          }}
          disabled={isOperationLoading || isOperationFetching}
          layout="vertical"
          name="personal_teacher_data_form"
          initialValues={actualInitialValues}
        >
          <FormItem
            label={t('Фотография учителя')}
            name="photoPath"
            getValueFromEvent={normFile}
          >
            <Uploader
              maxCount={1}
              multiple={false}
              group="tutor_photos"
              existedFileUrl={null}
            />
          </FormItem>
          <FormItem name="isPromote" valuePropName="checked">
            <Checkbox>{t('Продвигать учителя на главной')}</Checkbox>
          </FormItem>
          {
            mode === 'create' && (
              <>
                <FormItem name="phoneNumber" label={t('Номер телефона (будет использоваться в качестве логина)')} rules={[{ required: true }]}>
                  <Input addonBefore="+" maxLength={12} />
                </FormItem>
                <FormItem name="password" label={t('Пароль')} rules={[{ required: true }]} hasFeedback>
                  <Input.Password />
                </FormItem>
                <FormItem
                  name="confirmPassword"
                  label={t('Подтвердите пароль')}
                  hasFeedback
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('Пароли не совпадают')));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </FormItem>
              </>
            )
          }
          <FormItem label={t('Фамилия')} rules={[{ required: true }]} style={{ marginBottom: 0 }} required>
            <LanguageInput name="lastName" required />
          </FormItem>
          <FormItem label={t('Имя')} rules={[{ required: true }]} style={{ marginBottom: 0 }} required>
            <LanguageInput name="firstName" required />
          </FormItem>
          <FormItem label={t('Отчество')} style={{ marginBottom: 0 }} required>
            <LanguageInput name="secondName" required />
          </FormItem>
          <FormItem name="birthdate" label={t('Дата рождения')} rules={[{ required: true }]} >
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              placeholder={t('Выберите дату')}
              disabledDate={(current) => {
                const today = dayjs();
                return current > today.subtract(18, 'year');
              }}
            />
          </FormItem>
          <FormItem name="teachingSinceDate" label={t('Обучает с')} rules={[{ required: true }]}>
            <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} placeholder={t('Выберите дату')} />
          </FormItem>
          <FormItem name="introductionVideoUrl" label={t('Ссылка на презентационное видео (YouTube)')} rules={[{ required: true }]}>
            <Input />
          </FormItem>
          <FormItem label={t('Презентационный текст')} rules={[{ required: true }]} style={{ marginBottom: 0 }}>
            <LanguageInput name="introductionText" required type="textarea" />
          </FormItem>
          <FormItem name="learningGoals" label={t('Цели обучения')}>
            <Select
              showSearch
              mode="multiple"
              placeholder={t('Выберите цели')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isLearningGoalsLoading || isLearningGoalsFetching}
              options={dictionaryToSelect(learningGoals?.results)}
            />
          </FormItem>
          <FormItem name="subjects" label={t('Обучаемые предметы')} rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder={t('Выберите предметы')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              loading={isSubjectsLoading || isSubjectsFetching}
              options={dictionaryToSelect(subjects?.results)}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default PersonalTeacherDataForm;
