import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Row, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { identity } from 'ramda';
import { formatDate } from '../../../utils';

const FormItem = Form.Item;

const ListFilters = () => {
  const [t] = useTranslation();
  const [isOpen, show, hide] = useToggle();
  const navigate = useNavigate();

  const submitForm = useCallback((values) => {

    const {
      lastName,
      firstName,
      phoneNumber,
      isActive,
      nextContactDate
    } = values;
    /**
     *  Это нужно для передачи в квери как нужно отображать выбранные поисковые фильтры
     */
    const view = [
      lastName
        ? `${t('Фамилия')}: ${lastName}`
        : null,
      firstName
        ? `${t('Имя')}: ${firstName}`
        : null,
      phoneNumber
        ? `${t('Телефон')}: ${phoneNumber}`
        : null,
      isActive
        ? `${t('Только активные')}`
        : null,
      nextContactDate
        ? `${t('Контакт в период')} ${formatDate(nextContactDate[0])} - ${formatDate(nextContactDate[1])}`
        : null
    ].filter(identity);

    const query = {
      ...values,
      nextContactDate: null,
      nextContactDateFrom: nextContactDate ? nextContactDate[0].format('YYYY-MM-DD') : null,
      nextContactDateTo: nextContactDate ? nextContactDate[1].format('YYYY-MM-DD') : null,
      // view,
    };

    navigate({
      pathname: '',
      search: queryString.stringify(query, { skipNull: true, skipEmptyString: true }),
    });
    hide();
  }, [t, navigate, hide]);

  const reset = useCallback(() => {
    navigate({ pathname: '' });
    hide();
  }, [hide, navigate]);

  return (
    <>
      <Tooltip title={t('Фильтры')}>
        <Button
          type="primary"
          shape="circle"
          icon={<FilterOutlined />}
          onClick={show}
        />
      </Tooltip>
      <Drawer
        title={t('Фильтры')}
        placement="right"
        onClose={hide}
        open={isOpen}
      >
        <Form
          onFinish={submitForm}
          layout="vertical"
        >
          <FormItem label={t('Фамилия')} name="lastName">
            <Input allowClear />
          </FormItem>
          <FormItem label={t('Имя')} name="firstName">
            <Input allowClear />
          </FormItem>
          <FormItem label={t('Номер телефона')} name="phoneNumber">
            <Input allowClear />
          </FormItem>
          <FormItem label={t('Контакт в период')} name="nextContactDate">
            <DatePicker.RangePicker />
          </FormItem>
          <FormItem name="isActive" valuePropName="checked">
            <Checkbox>{t('Только активные')}</Checkbox>
          </FormItem>

          <Row justify="space-between">
            <Col>
              <Button onClick={reset} htmlType="reset">
                {t('Сбросить')}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                {t('Показать')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ListFilters;
