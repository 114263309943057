import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPriceCategories: build.query({
      query: (params) => ({
        url: apiRoutes.priceCategories(),
        params,
      }),
      providesTags: ['PRICE_CATEGORIES'],
    }),
    getPriceCategory: build.query({
      query: (langId) => ({
        url: apiRoutes.priceCategories(langId),
      }),
      providesTags: ['PRICE_CATEGORY'],
    }),
    editPriceCategory: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.priceCategories(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['PRICE_CATEGORIES', 'PRICE_CATEGORY'] }),
    }),
    createPriceCategory: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.priceCategories(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['PRICE_CATEGORIES', 'PRICE_CATEGORY'] }),
    }),
  })
});

export const {
  useGetPriceCategoriesQuery,
  useGetPriceCategoryQuery,
  useEditPriceCategoryMutation,
  useCreatePriceCategoryMutation,
} = rootApi;
