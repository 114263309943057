import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPackageCancellationRequestReasons: build.query({
      query: (params) => ({
        url: apiRoutes.packageCancellationRequestsReasons(),
        params,
      }),
      providesTags: ['PACKAGE_CANCELLATION_REQUEST_REASONS'],
    }),
    getPackageCancellationRequestReasonDetails: build.query({
      query: ({ id, params }) => ({
        url: apiRoutes.packageCancellationRequestsReasons(id),
        params,
      }),
      providesTags: ['PACKAGE_CANCELLATION_REQUEST_REASON_DETAILS'],
    }),
    editPackageCancellationRequestReason: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.packageCancellationRequestsReasons(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['PACKAGE_CANCELLATION_REQUEST_REASON_DETAILS', 'PACKAGE_CANCELLATION_REQUEST_REASONS'] })
    }),
    createPackageCancellationRequestReason: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.packageCancellationRequestsReasons(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['PACKAGE_CANCELLATION_REQUEST_REASONS'] })
    }),
  })
});

export const {
  useGetPackageCancellationRequestReasonsQuery,
  useGetPackageCancellationRequestReasonDetailsQuery,
  useEditPackageCancellationRequestReasonMutation,
  useCreatePackageCancellationRequestReasonMutation,
} = rootApi;
