// import cn from 'classnames';

import styles from './ScheduleLogsContextModal.module.scss';
import { Button, Modal, Skeleton, Space, Timeline, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyGetScheduleLogsQuery } from '../../../store/apis/reports/dashboard';
import { useTranslation } from 'react-i18next';
import { map, pipe, sort } from 'ramda';
import { formatDate } from '../../../utils';

const ScheduleLogsContextModal = ({
  causeId,
  onClose,
}) => {
  const [t] = useTranslation();
  const [
    getScheduleLogsContext,
    {
      data: scheduleLogs = { results: [] },
      isFetching: isGetScheduleLogsContextFetching,
    }
  ] = useLazyGetScheduleLogsQuery();
  const [showDeveloperInfo, setShowDeveloperInfo] = useState(false);

  useEffect(() => {
    if (causeId) {
      getScheduleLogsContext({ page: -1, causeId });
    }
  }, [causeId]);

  const timelineItems = pipe(
    sort((a, b) => a.id - b.id),
    map((item) => ({
      children: (
        <Space direction="vertical">
          <Space align="center">
            <Typography.Title level={5} style={{ margin: 0 }}>
              {formatDate(item.createdTime, true)}
            </Typography.Title>
            <Typography.Text code>{item.service}</Typography.Text>
          </Space>
          <Typography.Text strong>{item.action}</Typography.Text>
          <Typography.Text italic>{item.cause}</Typography.Text>
          {
            showDeveloperInfo && (
              <pre>
                {JSON.stringify(item.developerDetails, 0, 2)}
              </pre>
            )
          }
        </Space>
      ),
    }))
  )(scheduleLogs.results)

  return (
    <Modal
      open={causeId}
      destroyOnClose
      onOk={onClose}
      onCancel={onClose}
      title={t('Контекст события')}
      width="60%"
    >
      {
        isGetScheduleLogsContextFetching
          ? <Skeleton />
          : (
            <div style={{ margin: '40px auto' }}>
              <Timeline
                mode="left"
                items={timelineItems}
              />
            </div>
          )
      }
      <Button
        onClick={() => {
          setShowDeveloperInfo(!showDeveloperInfo);
        }}
      >
        {
          showDeveloperInfo
            ? t('Скрыть техническую информацию')
            : t('Показать техническую информацию')
        }
      </Button>
    </Modal>
  );
};

export default ScheduleLogsContextModal;
