import React, { useState } from 'react';
import cn from 'classnames';

import styles from './Packages.module.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetPackagesQuery } from '../../../store/apis/administratives/packages';
import { Radio, Space, Spin, Table } from 'antd';
import { formatDate, formatNumber } from '../../../utils';
import CancelLesson from '../CancelLesson';
import RescheduleLesson from '../RescheduleLesson';
import usePermissions from '../../../hooks/usePermissions';
import TransferPackage from '../TransferPackage';
import { identity } from 'ramda';

const defaultPagination = {
  page: 1,
  pageSize: 20,
};

const Packages = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const [lessonType, setLessonType] = useState('trial');
  const [pagination, setPagination] = useState(defaultPagination);

  const {
    data: packages,
    isFetching: isPackagesFetching,
  } = useGetPackagesQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
    tariffGroup: lessonType,
    studentId: id,
  }, { skip: !id });

  const checkPermissions = usePermissions();

  const columns = [
    {
      title: t('Время оплаты'),
      dataIndex: 'paidAtTime',
      key: 'paidAtTime',
      render: (paidAtTime) => formatDate(paidAtTime, true),
    },
    {
      title: t('Тариф'),
      dataIndex: 'tariff',
      key: 'tariff',
      render: (tariff) => tariff.name[i18n.language],
    },
    {
      title: t('Цена тарифа'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => formatNumber(price),
    },
    {
      title: t('Уроков в тарифе'),
      dataIndex: 'lessonCount',
      key: 'lessonCount',
      render: (count) => count,
    },
    {
      title: t('Израсходовано уроков'),
      dataIndex: 'consumedLessonCount',
      key: 'consumedLessonCount',
      render: (count) => count,
    },
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (tutor) => tutor.fullName[i18n.language],
    },
    lessonType === 'other' ? {
      title: t('Действия'),
      dataIndex: 'id',
      key: 'actions',
      render: (id, raw) => (
        checkPermissions(['SUPER_ADMIN'])
        && (
          <Space>
            <TransferPackage
              packageId={id}
            />
          </Space>
        )
      ),
    } : null,
  ].filter(identity);

  const handlePageChange = (pagination) => {
    setPagination({
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <Spin spinning={isPackagesFetching}>
      <Radio.Group
        value={lessonType}
        onChange={(e) => {
          setLessonType(e.target.value);
          setPagination(defaultPagination);
        }}
        style={{ marginBottom: 12 }}
      >
        <Radio.Button value="trial">{t('Пробные')}</Radio.Button>
        <Radio.Button value="bonus">{t('Промо')}</Radio.Button>
        <Radio.Button value="other">{t('Платные')}</Radio.Button>
      </Radio.Group>
      <Table
        columns={columns}
        dataSource={packages?.results}
        onChange={handlePageChange}
        pagination={{
          total: packages?.count,
          current: pagination.page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </Spin>
  );
};

export default Packages;
