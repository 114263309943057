import React, { useCallback } from 'react';
// import cn from 'classnames';

import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRemoveTutorEducationMutation } from '../../store/apis/administratives/tutors';
import useToggle from '../../hooks/useToggle';

const RemoveEducation = ({ id }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();
  const [remove, { isLoading, isFetching }] = useRemoveTutorEducationMutation();

  const removeEducation = useCallback(async () => {
    try {
      await remove(id).unwrap();
      message.success(t('Образование удалено'));
      close();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [close, id, remove, t]);

  return (
    <Popconfirm
      open={isOpen}
      title={t('Вы действительно хотите удалить образование?')}
      description={t('Данное действие необратимо')}
      okText={t('Да, удалить')}
      cancelText={t('Нет')}
      onConfirm={removeEducation}
      onCancel={close}
      okButtonProps={{
        loading: isLoading || isFetching,
      }}
    >
      <Button type="link" danger icon={<DeleteOutlined />} size="small" onClick={open}/>
    </Popconfirm>
  );
};

export default RemoveEducation;
