// import cn from 'classnames';

import styles from './List.module.scss';
import { useGetLessonSchedulesQuery } from '../../../store/apis/administratives/lessonSchedules';
import ContentHeader from '../../../components/ContentHeader';
import { Badge, Button, Card, Col, Divider, Pagination, Row, Skeleton, Space, Tag, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { formatDate } from '../../../utils';
import { useLazyGetStudentDetailsQuery } from '../../../store/apis/administratives/students';
import { useLazyGetTutorDetailsQuery } from '../../../store/apis/administratives/tutors';
import queryString from 'query-string';
import { useNavigate } from 'react-router';

const List = () => {
  const [t, i18n] = useTranslation();
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [phones, setPhones] = useState({});

  const navigate = useNavigate();

  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const {
    data: schedules,
    isFetching,
  } = useGetLessonSchedulesQuery({
    sort: '-createdTime',
    isActive: true,
    page,
    pageSize,
    ...restQuery,
  });

  const [
    getStudentDetails,
  ] = useLazyGetStudentDetailsQuery();

  const [
    getTutorDetails,
  ] = useLazyGetTutorDetailsQuery()

  const getPhoneNumbers = (studentId, tutorId, scheduleId) => async () => {
    try {
      setPhoneLoading(true);
      const studentDetails = await getStudentDetails(studentId).unwrap();
      const tutorDetails = await getTutorDetails(tutorId).unwrap();
      setPhones({
        ...phones,
        [scheduleId]: {
          tutor: tutorDetails,
          student: studentDetails,
        },
      });
      setPhoneLoading(false);
    } catch (e) {
      setPhoneLoading(false);
    }
  };

  const handlePageChange = useCallback((nextPage, nextPageSize) => {
    console.log(nextPage, nextPageSize);
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: nextPage,
        pageSize: nextPageSize ?? pageSize,
        ...restQuery
      }),
    })
  }, [navigate, pageSize, restQuery]);


  if (isFetching) {
    return <Skeleton loading />;
  }

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Расписание студентов')}>

        </ContentHeader>
      </div>
      <Row gutter={[24, 24]}>
        {schedules?.results.map(({
          id,
          createdTime,
          isActive,
          deactivateTime,
          tutor,
          student,
          subject,
          type,
          startAtDate,
          timeSpan,
          singleLessonTime,
        }) => (
          <Col md={12} key={id}>
            <Badge.Ribbon color="purple" text={subject.name[i18n.language] || '???'}>
              <Card
                size="small"
                style={{ boxShadow: '0px 0px 40px -10px rgba(92,92,92,.4)' }}
                title={`${type.name[i18n.language]} ${singleLessonTime ? '' : `(${timeSpan.name[i18n.language]})`}`}
                actions={[
                  <Button
                    type="link"
                    onClick={getPhoneNumbers(student.id, tutor.id, id)}
                    loading={phoneLoading}
                  >
                    {t('Показать номера телефонов')}
                  </Button>,
                ]}
              >
                <Row justify="space-between" gutter={[12, 12]}>
                  <Col>
                    <Space size={0} direction="vertical">
                      <Typography.Text color="secondary">
                        {t('Дата создания')}
                      </Typography.Text>
                      <Typography.Text strong>
                        {formatDate(createdTime, true)}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col>
                    <Space size={0} direction="vertical" style={{ textAlign: 'right' }}>
                      <Typography.Text color="secondary">
                        {
                          singleLessonTime
                            ? t('Дата занятия')
                            : t('Первое занятие')
                        }
                      </Typography.Text>
                      <Typography.Text strong>
                        {
                          singleLessonTime
                            ? formatDate(singleLessonTime, true)
                            : formatDate(startAtDate)
                        }
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Divider />
                <Row justify="space-between" gutter={[12, 12]}>
                  <Col>
                    <Space size={0} direction="vertical">
                      <Typography.Text color="secondary">{t('Учитель')}</Typography.Text>
                      <Typography.Text strong>
                        <Link to={routes.teachers(tutor.id)}>{tutor.fullName[i18n.language] || '???'}</Link>
                      </Typography.Text>
                      <Typography.Text strong type="warning">
                        {
                          phones[id]
                            ? phones[id].tutor.phoneNumber
                            : null
                        }
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col>
                    <Space size={0} direction="vertical" style={{ textAlign: 'right' }}>
                      <Typography.Text color="secondary">{t('Ученик')}</Typography.Text>
                      <Typography.Text strong>
                        <Link to={routes.students(tutor.id)}>{student.fullName[i18n.language] || '???'}</Link>
                      </Typography.Text>
                      <Typography.Text strong type="warning">
                        {
                          phones[id]
                            ? phones[id].student.phoneNumber
                            : null
                        }
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>

      <Divider />

      <Row justify="end">
        <Col>
          <Pagination
            pageSize={pageSize}
            current={page}
            total={schedules?.count}
            locale={{
              items_per_page: `/ ${t('на странице')}`
            }}
            pageSizeOptions={[1, 20, 50, 100]}
            showSizeChanger
            showTitle
            onChange={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;
