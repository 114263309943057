import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './WorkTimeWeekly.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tag, Typography } from 'antd';
import { getTimeStringByHourId } from '../../../utils';
import { map, times } from 'ramda';
import SelectionArea from '@viselect/react';
import { useUserTimezone } from '../../../store/slicers/user';

const { Text } = Typography;

const WorkTimeWeekly = ({ value = [], onChange, readOnly = false }) => {
  const [t] = useTranslation();
  const { timezone, hourShift } = useUserTimezone();

  const weekNames = useMemo(() => [
    { name: t('Понедельник'), id: 1 },
    { name: t('Вторник'), id: 2 },
    { name: t('Среда'), id: 3 },
    { name: t('Четверг'), id: 4 },
    { name: t('Пятница'), id: 5 },
    { name: t('Суббота'), id: 6 },
    { name: t('Воскресение'), id: 7 },
  ], []);
  const hours = useMemo(() => times((i) => ({
    id: i,
    timeLabel: getTimeStringByHourId(i),
  }), 24), []);

  const [selectedItemsByWeek, setSelectedItemsByWeek] = useState(() =>{
    const root = {
      1: new Set(),
      2: new Set(),
      3: new Set(),
      4: new Set(),
      5: new Set(),
      6: new Set(),
      7: new Set(),
    };

    const initialState = value.reduce((acc, { weekDayId, hourId }) => {
      return {
        ...acc,
        [weekDayId]: acc[weekDayId].add(hourId),
      };
    }, root);

    return initialState;
  });

  useEffect(() => {
    if (onChange) {
      onChange(map((timeSpanSet) => Array.from(timeSpanSet), selectedItemsByWeek));
    }
  }, [selectedItemsByWeek]);

  const selectNewTimes = (weekId) => ({ store: { changed: { added, removed } } }) => {
    setSelectedItemsByWeek((prev) => {
      const addedTimes = added.map((htmlElement) => {
        return Number(htmlElement.getAttribute('data-time-id'));
      });
      const removedTimes = removed.map((htmlElement) => {
        return Number(htmlElement.getAttribute('data-time-id'));
      });

      const currentTimes = new Set(prev[weekId]);

      addedTimes.forEach((time) => currentTimes.add(time));
      removedTimes.forEach((time) => currentTimes.delete(time));

      const newState = { ...prev, [weekId]: currentTimes };

      return newState;
    });
  };

  // const renderHours = (weekId) => {
  //   return
  // };

  return (
    <div>
      <Text italic>
        {t('Время показывается в часовом поясе - ')}
        <Text strong>{timezone} {hourShift}</Text>
      </Text>
      <Row>
        {
          weekNames.map(({
            name: weekName,
            id: weekId,
          }) => (
            <Col key={weekId} flex="1 1 13%" className={styles.column}>
              <div className={styles.columnHeader}>
                {weekName}
              </div>
              <div className={styles.timespanContainer}>
                {
                  readOnly
                    ? hours.map(({ id, timeLabel }) => {
                      return (
                        <div
                          key={id}
                          className={styles.timespan}
                          data-time-id={id}
                        >
                          <Tag
                            color={selectedItemsByWeek[weekId].has(id) ? '#f50' : 'default'}
                            key={id}
                          >
                            {timeLabel}
                          </Tag>
                        </div>
                      )
                    }) : (
                      <SelectionArea
                        onMove={selectNewTimes(weekId)}
                        selectables={`.${styles.timespan}`}
                      >
                        {
                          hours.map(({ id, timeLabel }) => {
                            return (
                              <div
                                key={id}
                                className={cn(styles.timespan, styles.timespanSelectable)}
                                data-time-id={id}
                              >
                                <Tag
                                  color={selectedItemsByWeek[weekId].has(id) ? '#f50' : 'default'}
                                  key={id}
                                >
                                  {timeLabel}
                                </Tag>
                              </div>
                            )
                          })
                        }
                      </SelectionArea>
                    )
                }
              </div>
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

export default WorkTimeWeekly;
