import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { rtkQueryErrorLogger } from './middlewares/handleError';
// slicers
import { auth as authSlice } from './slicers/auth';
import { user as timezoneSlice } from './slicers/user';

// apis
import { authApi } from './apis/auth';
import { rootApi } from './apis';

const rootReducer = combineReducers({
  user: authSlice.reducer,
  timeZone: timezoneSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [rootApi.reducerPath]: rootApi.reducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
    .concat(rtkQueryErrorLogger)
    .concat(authApi.middleware)
    .concat(rootApi.middleware),
});
