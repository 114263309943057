import React from 'react';
import cn from 'classnames';

import styles from './Details.module.scss';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEditVacancyMutation, useGetVacancyDetailsQuery } from '../../../store/apis/administratives/vacancies';
import { message, Typography } from 'antd';
import Form from '../Form';

const Details = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const {
    data: vacancyDetails,
    isLoading: isVacancyDetailsLoading,
    isFetching: isVacancyDetailsFetching,
  } = useGetVacancyDetailsQuery(id, { skip: !id });

  const [
    editVacancy,
    {
      isLoading: isEditVacancyLoading,
      isFetching: isEditVacancyFetching,
    }
  ] = useEditVacancyMutation();

  const handleSubmit = async (values) => {
    try {
      await editVacancy({ body: values, id });
      message.success(t('Предмет преподавания изменен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  return (
    <div>
      <Typography.Title level={4}>
        {t('Предмет преподавания')} - {vacancyDetails?.subject.name[i18n.language]}
      </Typography.Title>
      <Form
        initialValues={vacancyDetails}
        onSubmit={handleSubmit}
        loading={
          isVacancyDetailsLoading
           || isVacancyDetailsFetching
           || isEditVacancyLoading
           || isEditVacancyFetching
        }
      />
    </div>
  );
};

export default Details;
