import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLanguageLevels: build.query({
      query: (params) => ({
        url: apiRoutes.languageLevels(),
        params,
      }),
      providesTags: ['LANGUAGE_LEVELS'],
    }),
    getLanguageLevel: build.query({
      query: (langId) => ({
        url: apiRoutes.languageLevels(langId),
      }),
      providesTags: ['LANGUAGE_LEVEL'],
    }),
    editLanguageLevel: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.languageLevels(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['LANGUAGE_LEVELS', 'LANGUAGE_LEVEL'] }),
    }),
    createLanguageLevel: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.languageLevels(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['LANGUAGE_LEVELS', 'LANGUAGE_LEVEL'] }),
    }),
  })
});

export const {
  useGetLanguageLevelsQuery,
  useGetLanguageLevelQuery,
  useEditLanguageLevelMutation,
  useCreateLanguageLevelMutation,
} = rootApi;
