import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import 'antd/dist/reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './localization/i18n';
import './index.scss';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, useLocation, useNavigationType, matchRoutes } from 'react-router-dom';
import { CaptureConsole, HttpClient } from '@sentry/integrations';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/uz';

dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);

Sentry.init({
  dsn: "https://7b29f31ac483198256311b4eff6a7979@o4505743067840512.ingest.sentry.io/4505754442792960",
  integrations: [
    new HttpClient({
      failedRequestStatusCodes: [[500, 505], 507, 404],
      failedRequestTargets: [
        'https://api.beshplus.uz',
        'https://administrator.beshplus.uz',
      ],
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https:api.beshplus.uz"],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  enabled: process.env.REACT_APP_ENV === 'production',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
