import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPackageCancellationRequests: builder.query({
      query: (params) => ({
        url: apiRoutes.packageCancellationRequests(),
        params,
      }),
      providesTags: ['PACKAGE_CANCELLATION_REQUESTS'],
    }),
    rejectPackageCancellationRequest: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.rejectPackageCancellationRequest(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['PACKAGE_CANCELLATION_REQUESTS'] }),
    }),
    approvePackageCancellationRequest: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.approvePackageCancellationRequest(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['PACKAGE_CANCELLATION_REQUESTS'] }),
    }),
  }),
});

export const {
  useGetPackageCancellationRequestsQuery,
  useRejectPackageCancellationRequestMutation,
  useApprovePackageCancellationRequestMutation,
} = rootApi;
