import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { DatePicker, Space, Spin, Table } from 'antd';
import { useGetStudentSchedulesQuery } from '../../../store/apis/administratives/students';
import { formatDate } from '../../../utils';
import CancelLesson from '../CancelLesson';
import usePermissions from '../../../hooks/usePermissions';
import RescheduleLesson from '../RescheduleLesson';

const defaultPagination = {
  page: 1,
  pageSize: 20,
};

const Schedules = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();
  const [scheduleDates, setScheduleDates] = useState([]);

  const checkPermissions = usePermissions();

  const {
    data: schedules,
    isFetching: isSchedulesFetching,
  } = useGetStudentSchedulesQuery({
    studentId: id,
    params: {
      fromTime: scheduleDates[0]?.format('YYYY-MM-DD\T00:00:00.000\Z'),
      toTime: scheduleDates[1]?.format('YYYY-MM-DD\T23:59:59.000\Z'),
    },
  }, { skip: !id });

  const columns = [
    {
      title: t('Время урока'),
      dataIndex: 'lessonTime',
      key: 'lessonTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('Учитель'),
      dataIndex: 'schedule',
      key: 'scheduleTutor',
      render: (schedule) => schedule?.tutor?.fullName[i18n.language],
    },
    {
      title: t('Предмет'),
      dataIndex: 'schedule',
      key: 'scheduleSubject',
      render: (schedule) => schedule?.subject?.name[i18n.language],
    },
    {
      title: t('Тип расписания'),
      dataIndex: 'schedule',
      key: 'scheduleType',
      render: (schedule) => schedule?.type?.name[i18n.language],
    },
    {
      title: t('Действия'),
      dataIndex: 'schedule',
      key: 'scheduleId',
      render: (schedule, raw) => (
        checkPermissions(['SUPER_ADMIN'])
          && (
            <Space>
              <CancelLesson id={schedule.id} lessonTime={raw.lessonTime} />
              <RescheduleLesson id={schedule.id} lessonTime={raw.lessonTime} />
            </Space>
        )
      ),
    },
  ];

  return (
    <Spin spinning={isSchedulesFetching}>
      <div style={{ marginBottom: 12 }}>
        <DatePicker.RangePicker
          value={scheduleDates}
          onChange={(dates) => {
            setScheduleDates(dates);
          }}
          format="DD.MM.YYYY"
        />
      </div>
      <Table
        columns={columns}
        dataSource={schedules}
        pagination={false}
        rowKey="id"
      />
    </Spin>
  );
};

export default Schedules;
