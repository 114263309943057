import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Details.module.scss';
import { useNavigate, useParams } from 'react-router';
import { useGetLessonDetailsQuery } from '../../../store/apis/finances/lessons';
import { Alert, Button, Card, Col, Modal, Row, Skeleton, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import LabelData from '../../../components/LabelData';
import routes from '../../../router/routes';
import { formatDate } from '../../../utils';
import useToggle from '../../../hooks/useToggle';
import AnnulLessonModal from '../../../containers/lessons/AnnulLessonModal';
import usePermissions from '../../../hooks/usePermissions';

const { Title, Text } = Typography;

const Details = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation();
  const [isOpenAnnuledLessonDetails, showAnnuledLessonDetails, hideAnnuledLessonDetails] = useToggle();
  const navigate = useNavigate();
  const hasAccess = usePermissions();

  const {
    data: lessonData,
    isLoading: isLessonDataLoading,
    isFetching: isLessonDataFetching,
  } = useGetLessonDetailsQuery(id);

  const goToTutorProfile = useCallback(() => {
    if (!lessonData || !lessonData.tutor || !hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])) return;
    navigate(routes.teachers(lessonData.tutor.id));
  }, [lessonData, hasAccess, navigate]);

  const goToStudentProfile = useCallback(() => {
    if (!lessonData || !lessonData.student || !hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])) return;
    navigate(routes.students(lessonData.student.id));
  }, [lessonData, hasAccess, navigate]);

  return (
    <div>

      <Skeleton loading={isLessonDataLoading || isLessonDataFetching}>
        {
          lessonData && (
            <Row gutter={15}>
              {
                lessonData.annulledUserId ? (
                  <Col xs={24} style={{ marginBottom: 16 }}>
                    <Alert
                      message={t('Урок аннулирован')}
                      description={t('Урок был аннулирован и деньги возвращены студенту')}
                      action={
                        <>
                          <Button
                            size="small"
                            danger
                            onClick={showAnnuledLessonDetails}
                          >
                            {t('Детали')}
                          </Button>
                          <Modal
                            title={t('Детали аннулирования')}
                            open={isOpenAnnuledLessonDetails}
                            onCancel={hideAnnuledLessonDetails}
                            footer={[
                              <Button
                                type="primary"
                                key="confirm"
                                onClick={hideAnnuledLessonDetails}
                              >
                                {t('Понятно')}
                              </Button>
                            ]}
                          >
                            <LabelData label={t('Время отмены')} data={formatDate(lessonData.annulledTime, true)} />
                            <LabelData label={t('Отменил')} data={lessonData.annulledAutorType?.name[i18n.language]} />
                            <LabelData label={t('ID пользователя')} data={lessonData.annulledUserId} />
                          </Modal>
                        </>
                      }
                      type="error"
                    />
                  </Col>
                ) : null
              }
              <Col lg={4}>
                <Card
                  hoverable
                  cover={<img src={lessonData.tutor.photoUrl} alt="" />}
                  onClick={goToTutorProfile}
                >
                  <Card.Meta title={lessonData.tutor.fullName?.[i18n.language]} description={lessonData.tutor.shortInfo[i18n.language]} />
                </Card>
              </Col>
              <Col lg={16}>
                <Title level={2} style={{ textAlign: 'center' }}>{lessonData.subject?.name[i18n.language]}</Title>
                <Row>
                  <Col lg={12}>
                    <LabelData
                      label={t('Начало урока')}
                      data={
                        <>
                          <div>{formatDate(lessonData.lessonTime, true)} - {t('по расписанию')}</div>
                          <div>{formatDate(lessonData.startedTime, true)} - {t('фактическое')}</div>
                        </>
                      }
                    />
                    <LabelData
                      label={t('Начал урок')}
                      data={lessonData.startAutorType?.name[i18n.language]}
                    />
                    <LabelData
                      label={t('Пакет')}
                      data={lessonData.package.tariff.name?.[i18n.language]}
                    />
                    <LabelData
                      label={t('Сумма пакета')}
                      data={lessonData.package.price}
                    />
                  </Col>
                  <Col lg={12}>
                    <LabelData
                      label={t('Конец урока')}
                      data={
                        <>
                          <div>{formatDate(lessonData.finishedTime, true)}</div>
                          <div>{lessonData.state.name?.[i18n.language]}</div>
                        </>
                      }
                    />
                    <LabelData
                      label={t('Закончил урок')}
                      data={lessonData.finishAutorType?.name[i18n.language]}
                    />
                    <LabelData
                      label={t('Сумма за урок')}
                      data={lessonData.package.pricePerLesson}
                    />
                    <LabelData
                      label={t('Время списания с баланса')}
                      data={formatDate(lessonData.withdrawalTime, true)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Card
                  hoverable
                  cover={<img src={lessonData.student.photoUrl} alt="" />}
                  onClick={goToStudentProfile}
                >
                  <Card.Meta title={lessonData.student.fullName?.[i18n.language]} />
                </Card>
              </Col>
              {
                !lessonData.annulledUserId && hasAccess(['ADMIN', 'SUPER_ADMIN']) && (
                  <Col xs={24} style={{ marginTop: '2em' }}>
                    <Title level={3}>{t('Действия')}</Title>
                    <Space>
                      <AnnulLessonModal lessonId={lessonData.id} />
                    </Space>
                  </Col>
                )
              }
            </Row>
          )
        }
      </Skeleton>
    </div>
  );
};

export default Details;
