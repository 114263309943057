import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTutorsWithdrawalsQuery } from '../../../store/apis/reports/dashboard';
import dayjs from 'dayjs';
import { Col, DatePicker, Row, Skeleton, Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { formatNumber, joinExisting } from '../../../utils';
import usePermissions from '../../../hooks/usePermissions';

const { Text, Title } = Typography;

const TutorsWithdrawals = () => {
  const [t] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);
  const hasAccess = usePermissions();

  const {
    data: stat,
    isLoading,
    isFetching
  } = useGetTutorsWithdrawalsQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const columns = useMemo(() => [
    {
      title: t('Учитель'),
      dataIndex: 'id',
      key: 'id',
      render: (id, raw) => hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])
        ? <Link to={routes.teachers(id)}>{joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')}</Link>
        : joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')
    },
    {
      title: t('Выплачено'),
      dataIndex: 'totalWithdrawalAmount',
      key: 'totalWithdrawalAmount',
      render: (data) => formatNumber(data) ?? 0,
    },
  ], [hasAccess, t]);

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  if (isLoading || isFetching) return <Skeleton loading />

  return (
    <div>
      <Row style={{ marginBottom: 32 }}>
        <Col>
          <Title level={3}>{t('Статистика по выплатам')}</Title>
          <Space>
            <Text>{t('Статистика за период')}</Text>
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              onChange={changeFilterDate}
              value={lessonDateDiapason}
              allowClear={false}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={stat.rows}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
};

export default TutorsWithdrawals;
