import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ChapterEdit.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputNumber, message, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import LanguageInput from '../../../components/LanguagesInput';
import { useUpdateChapterMutation } from '../../../store/apis/administratives/legalDocuments';

const ChapterEdit = ({
  initialValues,
  id,
  legalDocumentId,
}) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();
  const [form] = Form.useForm();

  const [
    updateChapter,
    {
      isLoading: isUpdateChapterLoading,
      isFetching: isUpdateChapterFetching,
    }
  ] = useUpdateChapterMutation();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      await updateChapter({ id, body: values }).unwrap();
      close();
      message.success(t('Часть документа изменена'))
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [close, form, id, t, updateChapter]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    close();
  }, [close, form]);

  return (
    <>
      <Button
        icon={<EditOutlined />}
        type="link"
        onClick={open}
      />
      <Modal
        open={isOpen}
        title={t('Редактировать часть документа')}
        okText={t('Изменить')}
        cancelText={t('Отменить')}
        onOk={handleSubmit}
        onCancel={handleCancel}
        width={1200}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          disabled={isUpdateChapterLoading || isUpdateChapterFetching}
        >
          <Form.Item name="legalDocumentId" hidden initialValue={legalDocumentId} />
          <Form.Item name="orderId" label={t('Порядковый номер')}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="header" label={t('Заголовок')} required rules={[{ required: true }]}>
            <LanguageInput name="header" />
          </Form.Item>
          <Form.Item name="description" label={t('Описание')}>
            <LanguageInput type="textarea" name="description" />
          </Form.Item>
          <Form.Item name="text" label={t('Текст')} required rules={[{ required: true }]}>
            <LanguageInput type="textarea" name="text" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChapterEdit;
