import React, { useCallback, useMemo } from 'react';

import styles from './TutorWorkTimesList.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useQueryString from '../../../hooks/useQueryString';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import queryString from 'query-string';
import { joinExisting } from '../../../utils';
import { Badge, Col, Row, Table, Tag, Typography } from 'antd';
import ContentHeader from '../../../components/ContentHeader';
import ListFilters from '../../teachers/ListFilters';
import TutorWorkTimeSlots from '../TutorWorkTimeSlots';

const { Title } = Typography;

const TutorWorkTimesList = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const { data: tutors, isLoading, isFetching } = useGetTutorsQuery({ page, pageSize, ...restQuery });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const columns = useMemo(() => [
    {
      title: t('ФИО'),
      dataIndex: 'id',
      key: 'name',
      render: (id, record) => <div>
        {joinExisting([
          record.lastName?.[i18n.language],
          record.firstName?.[i18n.language],
          record.secondName?.[i18n.language]
        ], ' ')}
      </div>
    },
    {
      title: t('Предметы'),
      dataIndex: 'subjects',
      key: 'subjects',
      render: (subjects) => subjects.map((subject) => <Tag color="blue" key={subject.id}>{subject.name[i18n.language]}</Tag>),
    },
    {
      title: t('Состояние'),
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (isPublished, record) => (
        <Badge color={isPublished ? 'green' : 'red'} text={isPublished ? t('Активный') : t('Неактивный')} />
      )
    },
  ], [i18n.language, t]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Выставленное время учителей')}>
          <ListFilters />
        </ContentHeader>
      </div>
      <Row>
        <Col md={24}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={tutors?.results}
            rowKey="id"
            loading={isLoading || isFetching}
            onChange={handlePageChange}
            expandable={{
              expandedRowRender: (record) => (
                <TutorWorkTimeSlots tutorId={record.id} tutorName={record.fullName?.[i18n.language]} />
              ),
              expandRowByClick: true,
            }}
            pagination={{
              total: tutors?.count,
              current: page,
              locale: { items_per_page: `/ ${t('на странице')}` },
              pageSize,
              showSizeChanger: true,
              showTitle: true,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TutorWorkTimesList;
