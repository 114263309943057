import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getSuccessStories: build.query({
      query: (params) => ({
        url: apiRoutes.successStories(),
        params,
      }),
      providesTags: ['SUCCESS_STORIES'],
    }),
    addSuccessStory: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.successStories(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SUCCESS_STORIES'] }),
    }),
    removeSuccessStory: build.mutation({
      query: (id) => ({
        url: apiRoutes.successStories(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['SUCCESS_STORIES'] }),
    }),
    updateSuccessStory: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.successStories(id),
        method: 'PUT',
        body
      }),
      invalidatesTags: invalidateOn({ success: ['SUCCESS_STORIES'] }),
    }),
  })
});

export const {
  useGetSuccessStoriesQuery,
  useAddSuccessStoryMutation,
  useRemoveSuccessStoryMutation,
  useUpdateSuccessStoryMutation,
} = rootApi;
