import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Radio, Space, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetLessonsQuery } from '../../../store/apis/finances/lessons';
import { formatDate } from '../../../utils';
import queryString from 'query-string';

const defaultPagination = {
  page: 1,
  pageSize: 20,
};

const LessonHistoryList = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation();
  const [lessonType, setLessonType] = useState('trial');
  const [pagination, setPagination] = useState(defaultPagination);

  const {
    data: packages,
    isFetching: isPackagesFetching,
  } = useGetLessonsQuery({
    studentId: id,
    tariffGroup: lessonType,
    page: pagination.page,
    pageSize: pagination.pageSize,
  });

  const columns = [
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (tutor) => tutor.fullName[i18n.language],
    },
    {
      title: t('Предмет'),
      dataIndex: 'subject',
      key: 'subject',
      render: (subject) => subject.name[i18n.language],
    },
    {
      title: t('Начат'),
      dataIndex: 'startedTime',
      key: 'startedTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('Закончен'),
      dataIndex: 'finishedTime',
      key: 'finishedTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('Состояние'),
      dataIndex: 'state',
      key: 'state',
      render: (state) => state.name[i18n.language],
    },
  ];

  const handlePageChange = (pagination) => {
    setPagination({
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <Spin spinning={isPackagesFetching}>
      <Radio.Group
        value={lessonType}
        onChange={(e) => {
          setLessonType(e.target.value);
          setPagination(defaultPagination);
        }}
        style={{ marginBottom: 12 }}
      >
        <Radio.Button value="trial">{t('Пробные')}</Radio.Button>
        <Radio.Button value="bonus">{t('Промо')}</Radio.Button>
        <Radio.Button value="other">{t('Платные')}</Radio.Button>
      </Radio.Group>
      <Table
        columns={columns}
        dataSource={packages?.results}
        onChange={handlePageChange}
        pagination={{
          total: packages?.count,
          current: pagination.page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </Spin>
  );
};

export default LessonHistoryList;
