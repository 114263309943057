import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Radio, Row, Select, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { identity } from 'ramda';
import { formatDate } from '../../../utils';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';

const FormItem = Form.Item;

const ListFilters = () => {
  const [t] = useTranslation();
  const [isOpen, show, hide] = useToggle();
  const navigate = useNavigate();

  const { data: subjects } = useGetSubjectsQuery({ page: -1 });

  const submitForm = useCallback((values) => {
    navigate({
      pathname: '',
      search: queryString.stringify(values, { skipNull: true, skipEmptyString: true }),
    });
    hide();
  }, [t, navigate, hide]);

  const reset = useCallback(() => {
    navigate({ pathname: '' });
    hide();
  }, [hide, navigate]);

  return (
    <>
      <Tooltip title={t('Фильтры')}>
        <Button
          type="primary"
          shape="circle"
          icon={<FilterOutlined />}
          onClick={show}
        />
      </Tooltip>
      <Drawer
        title={t('Фильтры')}
        placement="right"
        onClose={hide}
        open={isOpen}
      >
        <Form
          onFinish={submitForm}
          layout="vertical"
        >
          <FormItem label={t('Фамилия')} name="lastName">
            <Input allowClear />
          </FormItem>
          <FormItem label={t('Имя')} name="firstName">
            <Input allowClear />
          </FormItem>
          <FormItem label={t('Отчество')} name="secondName">
            <Input allowClear />
          </FormItem>
          <FormItem label={t('Номер телефона')} name="phoneNumber">
            <Input allowClear />
          </FormItem>
          <FormItem name="isPublished">
            <Radio.Group
              size="small"
              options={[
                { value: true, label: t('Только активные') },
                { value: false, label: t('Только неактивные') },
                { value: 'null', label: t('Все') },
              ]}
            />
          </FormItem>
          <FormItem name="subject" label={t('Предмет')}>
            <Select
              allowClear
              options={subjects?.results.map(({ id, sysName }) => ({
                value: id, label: sysName,
              })) ?? []}
            />
          </FormItem>

          <Row justify="space-between">
            <Col>
              <Button onClick={reset} htmlType="reset">
                {t('Сбросить')}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                {t('Показать')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ListFilters;
