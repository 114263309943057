import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import useQueryString from '../../../hooks/useQueryString';
import DictionaryList from '../../../containers/shared/DictionaryList';
import { useGetEducationInstitutionsQuery } from '../../../store/apis/dictionaries/education-institution';
import { useGetLearningGoalsQuery } from '../../../store/apis/dictionaries/learning-goals';
import { useGetQualificationsQuery } from '../../../store/apis/dictionaries/qualifications';
import { Link } from 'react-router-dom';

const List = () => {
  const [t, i18n] = useTranslation();
  const { page = 1, pageSize = 20 } = useQueryString();
  const { data, isLoading, isFetching } = useGetQualificationsQuery({ page, pageSize });

  const additionalColumns = useMemo(() => [
    {
      title: t('По предмету'),
      dataIndex: 'subject',
      key: 'subject',
      render: (data, raw) => data.name[i18n.language],
    },
  ], [i18n.language, t]);

  return (
    <DictionaryList
      title={t('Квалификации')}
      data={data?.results}
      dataCount={data?.count}
      loading={isLoading || isFetching}
      detailRoute={routes.qualifications}
      additionalColumns={additionalColumns}
    />
  );
};

export default List;
