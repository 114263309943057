import React from 'react';
import cn from 'classnames';

import styles from './Form.module.scss';
import { Form as AntForm, Input, message, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCreateStaffMutation } from '../../../store/apis/users';


const Form = ({
  open,
  mode = 'CREATE',
  onFinish,
}) => {
  const [form] = AntForm.useForm();
  const [t] = useTranslation();

  const [
    createStaff,
    {
      isLoading: isCreating,
    }
  ] = useCreateStaffMutation();

  const handleFinish = async (values) => {
    await createStaff({
      ...values,
      roles: [values.role],
    }).unwrap();
    message.success(t('Пользователь создан'));
    onFinish();
  };

  return (
    <Modal
      open={open}
      title={mode === 'CREATE' ? t('Создать пользователя') : t('Редактировать пользователя') }
      onOk={form.submit}
      onCancel={onFinish}
      width={600}
      destroyOnClose
      confirmLoading={isCreating}
    >
      <AntForm
        form={form}
        onFinish={handleFinish}
        wrapperCol={{ sm: 17 }}
        labelCol={{ sm: 7 }}
      >
        <AntForm.Item name="username" label={t('Логин')} required rules={[{ required: true }, { min: 6 }]} >
          <Input placeholder="Минимум 6 символов" />
        </AntForm.Item>
        <AntForm.Item name="password" label={t('Пароль')} required rules={[{ required: true }]}>
          <Input.Password />
        </AntForm.Item>
        <AntForm.Item
          name="passwordConfirm"
          label={t('Повторите пароль')}
          required
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </AntForm.Item>
        <AntForm.Item name="lastName" label={t('Фамилия')} required rules={[{ required: true }]}>
          <Input/>
        </AntForm.Item>
        <AntForm.Item name="firstName" label={t('Имя')} required rules={[{ required: true }]}>
          <Input/>
        </AntForm.Item>
        <AntForm.Item name="secondName" label={t('Отчество')}>
          <Input/>
        </AntForm.Item>
        <AntForm.Item name="role" label={t('Роль')} required rules={[{ required: true }]}>
          <Select
            options={[
              { label: 'Менеджер', value: 'MANAGER' },
              { label: 'HR-менеджер', value: 'HR' },
              { label: 'Бухгалтер', value: 'ACCOUNTANT' },
            ]}
          />
        </AntForm.Item>
      </AntForm>
    </Modal>
  );
};

export default Form;
