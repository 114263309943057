// import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import ContentHeader from '../../../components/ContentHeader';
import Form from '../../socialsLinks/Form';
import { Button, Col, Input, Row, Skeleton, Space, Table, Tag, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import useQueryString from '../../../hooks/useQueryString';
import { useGetSmsToPlaymobileQuery } from '../../../store/apis/reports/dashboard';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { formatDate } from '../../../utils';

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const navigate = useNavigate();
  const [phoneValue, setPhoneValue] = useState('');

  const {
    data: sms,
    isLoading: isSmsLoading,
    isFetching: isSmsFetching,
  } = useGetSmsToPlaymobileQuery({
    page,
    pageSize,
    ...restQuery,
  });

  const columns = [
    {
      title: t('Originator'),
      dataIndex: 'originator',
      key: 'originator',
    },
    {
      title: t('Телефон'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('Дата отправки в Play Mobile'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('Сообщение об ошибке'),
      dataIndex: 'errorMessage',
      key: 'errorMessage',
      render: (text) => (
        text
          ? (
            <Typography.Text
              ellipsis={{
                tooltip: text,
              }}
              style={{ width: 100 }}
            >
              {text}
            </Typography.Text>
          ) : '-'
      )
    },
    {
      title: t('Непредвиденная ошибка'),
      dataIndex: 'isException',
      key: 'isException',
      render: (isException) => (
        <Tag color={isException ? 'red' : 'green'}>{isException ? 'ДА' : 'НЕТ'}</Tag>
      )
    },
    {
      title: t('Успешно отправлено'),
      dataIndex: 'isSentSuccessfully',
      key: 'isSentSuccessfully',
      render: (isSentSuccessfully) => (
        <Tag color={isSentSuccessfully ? 'green' : 'red'}>{isSentSuccessfully ? 'ДА' : 'НЕТ'}</Tag>
      )
    },
    {
      title: t('Запрос'),
      dataIndex: 'requestBodyText',
      key: 'requestBodyText',
      render: (requestBodyText) => (
        <Typography.Text
          ellipsis={{
            tooltip: requestBodyText,
          }}
          style={{ width: 100 }}
        >
          {requestBodyText}
        </Typography.Text>
      )
    },
    {
      title: t('Текст СМС'),
      dataIndex: 'smsText',
      key: 'smsText',
      render: (smsText) => (
        <Typography.Text
          ellipsis={{
            tooltip: smsText,
          }}
          style={{ width: 100 }}
        >
          {smsText}
        </Typography.Text>
      )
    },
    {
      title: t('Ответ'),
      dataIndex: 'responseBodyText',
      key: 'responseBodyText',
      render: (responseBodyText) => (
        <Typography.Text
          ellipsis={{
            tooltip: responseBodyText,
          }}
          style={{ width: 100 }}
        >
          {responseBodyText}
        </Typography.Text>
      )
    },
    {
      title: t('Статус ответа'),
      dataIndex: 'responseStatusCode',
      key: 'responseStatusCode',
      render: (responseStatusCode) => (
        <Tag color={(responseStatusCode >= 200 && responseStatusCode <= 299) ? 'green' : 'red'}>
          {responseStatusCode}
        </Tag>
      )
    },
  ];

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const handleSearch = () => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        ...restQuery,
        phone: phoneValue,
      }),
    })
  };

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Взаимодействие с Play Mobile')} />
      </div>
      <div style={{ margin: '20px 0' }}>
        <Space.Compact>
          <Input
            value={phoneValue}
            onChange={(e) => setPhoneValue(e.target.value)}
            placeholder={t('Поиск по номеру телефона')}
          />
          <Button
            type="primary"
            onClick={handleSearch}
          >
            {t('Поиск')}
          </Button>
        </Space.Compact>
      </div>
      <Row>
        <Col md={24}>
          {
            (isSmsLoading || isSmsFetching)
              ? <Skeleton loading/>
              : (
                <Table
                  rootClassName="overflow-scroll"
                  loading={isSmsLoading || isSmsFetching}
                  columns={columns}
                  rowKey="id"
                  dataSource={sms?.results}
                  onChange={handlePageChange}
                  pagination={{
                    total: sms?.count,
                    current: page,
                    locale: { items_per_page: `/ ${t('на странице')}` },
                    pageSize,
                    showSizeChanger: true,
                    showTitle: true,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              )
          }
        </Col>
      </Row>
    </div>
  );
};

export default List;
