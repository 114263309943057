import React, { useCallback } from 'react';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Card, Col, message, Popconfirm, Row, Skeleton, Tag, Typography } from 'antd';
import {
  useGetSuccessStoriesQuery,
  useRemoveSuccessStoryMutation
} from '../../../store/apis/administratives/successStories';
import ReactPlayer from 'react-player';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import Form from '../Form';

const { Title, Text } = Typography;

const List = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const {
    data: successStories,
    isFetching: isSuccessStoriesFetching,
    isLoading: isSuccessStoriesLoading,
  } = useGetSuccessStoriesQuery({ page: -1 });

  const [
    removeSuccessStory,
    {
      isLoading: isRemoveSuccessStoryLoading,
      isFetching: isRemoveSuccessStoryFetching,
    }
  ] = useRemoveSuccessStoryMutation();

  const handleRemoveSuccessStory = useCallback((id) => async () => {
    try {
      await removeSuccessStory(id).unwrap();
      message.success(t('История успеха удалена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [removeSuccessStory, t]);

  // const handleNavigateToCreate = useCallback(() => {
  //   navigate(routes.faqs('create'));
  // }, [navigate]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Истории успеха')}>
          <Form mode="create" />
        </ContentHeader>
      </div>
      {
        (isSuccessStoriesLoading || isSuccessStoriesFetching)
          ? <Skeleton loading />
          : (
            <Row gutter={16}>
              {
                successStories?.results.map((story) => (
                  <Col md={24} key={story.id} style={{ marginBottom: 16 }}>
                    <Card>
                      <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col md={24} style={{ marginBottom: 10, textAlign: 'right' }}>
                          <Form mode="edit" id={story.id} initialValues={story} />
                          <Popconfirm
                            title={t('Вы уверены что хотите удалить?')}
                            okText={t('Да, удалить')}
                            cancelText={t('Нет, отменить')}
                            onConfirm={handleRemoveSuccessStory(story.id)}
                          >
                            <Button
                              htmlType="button"
                              danger
                              type="text"
                              icon={<DeleteFilled />}
                            />
                          </Popconfirm>
                        </Col>
                        <Col md={12}>
                          <Tag color={story.isPublic ? 'green' : 'red'} style={{ marginBottom: 10 }}>{story.isPublic ? t('Опубликован') : t('Не опубликован')}</Tag>
                          <Title level={5}>{story.lastName?.[i18n.language]} {story.firstName?.[i18n.language]}</Title>
                          <Text>{story.description?.[i18n.language]}</Text>
                        </Col>
                        <Col md={6}>
                          <Text>{t('Обложка')}</Text>
                          {
                            story.imageUrl
                              ? <img src={story.imageUrl[i18n.language]} alt="" style={{ width: '100%' }} />
                              : t('Нет')
                          }
                        </Col>
                        <Col md={6}>
                          <Text>{t('Видео')}</Text>
                          <ReactPlayer
                            controls
                            url={story.videoUrl[i18n.language]}
                            width="100%"
                            height="180px"
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
              }
            </Row>
          )
      }
    </div>
  );
};

export default List;
