import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLegalDocuments: build.query({
      query: (params) => ({
        url: apiRoutes.legalDocuments(),
        params,
      }),
      providesTags: ['LEGAL_DOCUMENTS'],
    }),
    getLegalDocumentDetails: build.query({
      query: ({ id, params }) => ({
        url: apiRoutes.legalDocuments(id),
        params,
      }),
      providesTags: ['LEGAL_DOCUMENT_DETAILS'],
    }),

    createLegalDocuments: build.mutation({
      query: (body) => ({
        url: apiRoutes.legalDocuments(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LEGAL_DOCUMENTS'], }),
    }),
    updateLegalDocuments: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.legalDocuments(id),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LEGAL_DOCUMENTS', 'LEGAL_DOCUMENT_DETAILS'], }),
    }),

    addChapter: build.mutation({
      query: (body) => ({
        url: apiRoutes.chapters(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LEGAL_DOCUMENTS', 'LEGAL_DOCUMENT_DETAILS'], }),
    }),
    updateChapter: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.chapters(id),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LEGAL_DOCUMENTS', 'LEGAL_DOCUMENT_DETAILS'], }),
    }),
    deleteChapter: build.mutation({
      query: (id) => ({
        url: apiRoutes.chapters(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LEGAL_DOCUMENTS', 'LEGAL_DOCUMENT_DETAILS'], }),
    }),
  })
});

export const {
  useGetLegalDocumentsQuery,
  useCreateLegalDocumentsMutation,
  useGetLegalDocumentDetailsQuery,
  useUpdateLegalDocumentsMutation,
  useAddChapterMutation,
  useUpdateChapterMutation,
  useDeleteChapterMutation,
} = rootApi;
