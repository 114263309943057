import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { message, Skeleton } from 'antd';
import MainError from '../../errors/MainError';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import {
  useEditEducationLevelMutation,
  useGetEducationLevelQuery
} from '../../../store/apis/dictionaries/education-levels';

const Details = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [edit, { isLoading }] = useEditEducationLevelMutation();

  const {
    data: educationLevel,
    isLoading: isEducationLevelLoading,
    isFetching: isEducationLevelFetching,
    error: educationLevelError,
  } = useGetEducationLevelQuery(id);

  const handleSubmit = useCallback(async (body) => {
    try {
      await edit({ id, body }).unwrap();
      message.success(t('Степень сохранена'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [edit, id, t]);

  const handleExit = useCallback(() => {
    navigate(routes.educationLevels());
  }, [navigate]);

  if (isEducationLevelLoading || isEducationLevelFetching) return <Skeleton />
  if (educationLevelError) return <MainError error={educationLevelError} />

  return (
    <div>
      <ContentHeader title={educationLevel.sysName} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={educationLevel}
        loading={isLoading}
        saveTitle={t('Сохранить')}
        saveAndExitTitle={t('Сохранить и выйти')}
      />
    </div>
  );
};

export default Details;
