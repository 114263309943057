import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getLessons: builder.query({
      query: (params) => ({
        url: apiRoutes.lessons(),
        params,
      }),
      providesTags: ['LESSONS'],
    }),
    getLessonDetails: builder.query({
      query: (id) => ({
        url: apiRoutes.lessons(id),
      }),
      providesTags: ['LESSON_DETAILS'],
    }),
    annulLesson: builder.mutation({
      query: (id) => ({
        url: apiRoutes.annulLesson(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS', 'LESSONS'] }),
    }),
  }),
});

export const {
  useGetLessonsQuery,
  useGetLessonDetailsQuery,
  useAnnulLessonMutation,
} = rootApi;
