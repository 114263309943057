import { rootApi } from './index';
import apiRoutes from '../../apiRoutes';
import { invalidateOn } from '../utils';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query({
      query: (params) => ({
        url: apiRoutes.settings(),
        params,
      }),
      providesTags: ['SETTINGS'],
    }),
    patchSettings: build.mutation({
      query: (body) => ({
        url: apiRoutes.settings(),
        body,
        method: 'PATCH',
      }),
      invalidatesTags: invalidateOn({ success: ['SETTINGS'] })
    }),

    getAccountSettings: build.query({
      query: (params) => ({
        url: apiRoutes.accountSettings(),
        params,
      }),
      providesTags: ['ACCOUNT_SETTINGS'],
    }),
    patchAccountSettings: build.mutation({
      query: (body) => ({
        url: apiRoutes.accountSettings(),
        body,
        method: 'PATCH',
      }),
      invalidatesTags: invalidateOn({ success: ['ACCOUNT_SETTINGS'] })
    }),
  }),
});

export const {
  useGetSettingsQuery,
  usePatchSettingsMutation,
  useGetAccountSettingsQuery,
  usePatchAccountSettingsMutation,
} = rootApi;
