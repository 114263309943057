import apiRoutes from '../../apiRoutes';
import { invalidateOn } from '../utils';
import { rootApi } from './index';


const users = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    staffList: builder.query({
      query: (params) => ({
        url: apiRoutes.staff(),
        params,
      }),
      providesTags: ['STAFF_LIST'],
    }),
    staffDetails: builder.query({
      query: (id) => ({
        url: apiRoutes.staff(id),
      }),
      providesTags: ['STAFF_DETAILS'],
    }),
    createStaff: builder.mutation({
      query: (body) => ({
        url: apiRoutes.staff(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['STAFF_LIST'] })
    }),
    changeStaffName: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.staff(id),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['STAFF_LIST'] })
    }),
    changeStaffPassword: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.staffChangePassword(id),
        body,
        method: 'PUT',
      }),
    }),
    changeStaffRole: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.staffChangeRoles(id),
        body,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['STAFF_DETAILS'] })
    }),
  })
});

export const {
  useStaffListQuery,
  useStaffDetailsQuery,
  useCreateStaffMutation,
  useChangeStaffNameMutation,
  useChangeStaffPasswordMutation,
  useChangeStaffRoleMutation,
} = users;
