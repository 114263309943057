import React, { useState } from 'react';
import cn from 'classnames';

import styles from './StudentWithdrawals.module.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { formatNumber } from '../../../utils';
import { useGetPackagesQuery } from '../../../store/apis/administratives/packages';
import { useGetWithdrawalsQuery } from '../../../store/apis/finances/withdrawals';
import { useGetPackageCancellationRequestsQuery } from '../../../store/apis/finances/packageCancellations';
import { Table, Tag } from 'antd';

const defaultPagination = {
  page: 1,
  pageSize: 20,
};

const StudentWithdrawals = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const [pagination, setPagination] = useState(defaultPagination);

  const {
    data: packages,
    isFetching: isPackagesFetching,
  } = useGetPackageCancellationRequestsQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
    studentId: id,
  }, { skip: !id });

  const columns = [
    {
      title: t('Сумма возврата'),
      dataIndex: 'amountToRefund',
      key: 'amountToRefund',
      render: (amount) => formatNumber(amount),
    },
    {
      title: t('Кол-во уроков в пакете'),
      dataIndex: 'package',
      key: 'packageLessonCount',
      render: (pack) => pack.lessonCount,
    },
    {
      title: t('Пройдено уроков'),
      dataIndex: 'package',
      key: 'packageConsumed',
      render: (pack) => pack.consumedLessonCount,
    },
    {
      title: t('Учитель'),
      dataIndex: 'package',
      key: 'packageTutor',
      render: (pack) => pack.tutor?.fullName[i18n.language],
    },
    {
      title: t('Причина'),
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => reason.name?.[i18n.language],
    },
    {
      title: t('Статус'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const colors = ['gold', 'blue', 'green', 'red'];
        return <Tag color={colors[status.id]}>{status.name?.[i18n.language]}</Tag>
      }
    },
  ];

  const handlePageChange = (pagination) => {
    setPagination({
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <Table
      columns={columns}
      dataSource={packages?.results}
      onChange={handlePageChange}
      loading={isPackagesFetching}
      pagination={{
        total: packages?.count,
        current: pagination.page,
        locale: { items_per_page: `/ ${t('на странице')}` },
        pageSize: pagination.pageSize,
        showSizeChanger: true,
        showTitle: true,
        pageSizeOptions: [20, 50, 100],
      }}
    />
  );
};

export default StudentWithdrawals;
