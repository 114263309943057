import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getFaqs: build.query({
      query: (params) => ({
        url: apiRoutes.faqs(),
        params,
      }),
      providesTags: ['FAQS'],
    }),
    getFaqDetails: build.query({
      query: (id) => ({
        url: apiRoutes.faqs(id),
      }),
      providesTags: ['FAQ_DETAILS'],
    }),
    editFaq: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.faqs(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['FAQ_DETAILS', 'FAQS'] }),
    }),
    createFaq: build.mutation({
      query: (body) => ({
        url: apiRoutes.faqs(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['FAQS'] }),
    }),
    getFaqTags: build.query({
      query: (params) => ({
        url: apiRoutes.faqTags(),
        params,
      }),
      providesTags: ['FAQ_TAGS'],
    }),
  })
});

export const {
  useGetFaqsQuery,
  useGetFaqDetailsQuery,
  useEditFaqMutation,
  useCreateFaqMutation,
  useGetFaqTagsQuery,
} = rootApi;
