import React, { useCallback, useEffect, useMemo } from 'react';

import styles from './Application.module.scss';
import { Button, Layout, Menu, Select, Spin, theme } from 'antd';

import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routes from '../../router/routes';
import localforage from 'localforage';
import { useDispatch } from 'react-redux';
import { authApi } from '../../store/apis/auth';
import { rootApi } from '../../store/apis';
import { useGetMeQuery } from '../../store/apis/profile';
import usePermissions from '../../hooks/usePermissions';
import { filter, map } from 'ramda';
import dayjs from 'dayjs';
import ScrollToTop from '../../components/ScrollToTop';

const { Header, Content, Footer, Sider } = Layout;

const Application = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const hasAccess = usePermissions();

  const {
    data: me,
    isFetching: isMeFetching,
  } = useGetMeQuery();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, []);

  const menuItems = useMemo(() => {
     const rootMenuList = [
      {
        key: routes.welcome(),
        label: t('Главная'),
        icon: <span>🫡 </span>,
        hasChildren: false,
        permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
      },
      {
        key: 'admin',
        label: t('Администрирование'),
        icon: <span>🧑‍💼 </span>,
        hasChildren: true,
        permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
        children: [
          {
            key: routes.staff(),
            label: t('Пользователи админ панели'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN'],
          },
          {
            key: routes.teachers(),
            label: t('Учителя'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.students(),
            label: t('Студенты'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.trialLessons(),
            label: t('Пробные уроки'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.lessonSchedules(),
            label: t('Расписания студентов'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.userNotFinishedRegistration(),
            label: t('Не закончившие регистрацию'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.reviews(),
            label: t('Отзывы'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.tariffs(),
            label: t('Тарифы'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'ACCOUNTANT'],
          },
          {
            key: routes.faqs(),
            label: t('ВиО'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.tutorCv(),
            label: t('Резюме учителей (заявки)'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'HR'],
          },
          {
            key: routes.successStories(),
            label: t('Истории успеха'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.socialLinks(),
            label: t('Ссылки на социальные сети'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.contacts(),
            label: t('Контактные данные'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.videoAboutPlatform(),
            label: t('Видео о платформе'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.vacancies(),
            label: t('Предметы преподавания'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.tutorStories(),
            label: t('Отзывы учителей о платформе'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER'],
          },
          {
            key: routes.legalDocuments(),
            label: t('Правовые документы'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN'],
          },
          {
            key: routes.notifications(),
            label: t('Уведомления'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN'],
          },
          {
            key: routes.settings(),
            label: t('Настройки платформы'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN'],
          },
          {
            key: routes.accountSettings(),
            label: t('Настройки доступа'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN'],
          },
        ],
      },
      {
        key: 'money',
        label: t('Финансы'),
        icon: <span>💰 </span>,
        hasChildren: true,
        permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'ACCOUNTANT', 'HR'],
        children: [
          {
            key: routes.lessons(),
            label: t('Проведенные уроки'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.tutorBookkeeping(),
            label: t('Ведомости (выплаты)'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'ACCOUNTANT'],
          },
          {
            key: routes.packageCancellation(),
            label: t('Запросы на возврат средств'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'ACCOUNTANT'],
          },
        ],
      },
      {
        key: 'reports',
        label: t('Отчеты'),
        icon: <span>📊 </span>,
        hasChildren: true,
        permissions: ['SUPER_ADMIN', 'ADMIN', 'HR', 'ACCOUNTANT'],
        children: [
          {
            key: routes.dashboard(),
            label: t('Основная статистика'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'ACCOUNTANT'],
          },
          {
            key: routes.statsByTutor(),
            label: t('Статистика по учителям'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.statsByTariff(),
            label: t('Статистика по тарифам'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'ACCOUNTANT'],
          },
          {
            key: routes.statsBySubject(),
            label: t('Статистика по предметам'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.tutorsWithdrawals(),
            label: t('Статистика по выплатам'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.statsBypackageCancellations(),
            label: t('Статистика по возвратам'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.lessonMovementsByTutor(),
            label: t('Статистика по отменам'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.debitsByTutor(),
            label: t('Дебеты'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR', 'ACCOUNTANT'],
          },
          {
            key: routes.payments(),
            label: t('Оплаты'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'ACCOUNTANT'],
          },
          {
            key: routes.tutorWorkTimes(),
            label: t('Выставленное время учителей'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'HR'],
          },
          {
            key: routes.smsToPlaymobile(),
            label: t('Play Mobile взаимодействие'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'HR'],
          },
          {
            key: routes.scheduleLogs(),
            label: t('Логи отмен и переносов'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'HR'],
          },
        ],
      },
      {
        key: 'catalogs',
        label: t('Справочники'),
        icon: <span>🗂️ </span>,
        hasChildren: true,
        permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
        children: [
          {
            key: routes.subjects(),
            label: t('Предметы'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.languages(),
            label: t('Языки (владение)'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.languageLevels(),
            label: t('Языки (уровни владения)'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.educationLevels(),
            label: t('Степени образования'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.educationInstitution(),
            label: t('Образовательные учреждения'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.learningGoals(),
            label: t('Цели обучения'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.qualifications(),
            label: t('Квалификация'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          {
            key: routes.packageCancellationRequestsReasons(),
            label: t('Причины отмены пакета'),
            icon: <span>⚪ </span>,
            hasChildren: false,
            permissions: ['SUPER_ADMIN', 'ADMIN', 'MANAGER', 'HR'],
          },
          // Пока скрыты, т.к. фильтры переехали на диапазон.

          // {
          //   key: routes.workExperienceCategories(),
          //   label: t('Категории опыта работы'),
          //   icon: <span>⚪ </span>,
          // },
          // {
          //   key: routes.ageCategories(),
          //   label: t('Категории возрастов'),
          //   icon: <span>⚪ </span>,
          // },
          // {
          //   key: routes.priceCategories(),
          //   label: t('Категории цен'),
          //   icon: <span>⚪ </span>,
          // },
        ],
      },
    ];
     const checkHasAccessByPermissions = ({ permissions }) => hasAccess(permissions);
     const filteredParents = filter(checkHasAccessByPermissions)(rootMenuList);
     const filteredChildren = map((parentNode) => {
       if (!parentNode.hasChildren) return parentNode;

       return {
         ...parentNode,
         children: filter(checkHasAccessByPermissions)(parentNode.children),
       };
     })(filteredParents);
     const filteredEmptyChildrenParents = filter(({ hasChildren, children }) => {
       if (!hasChildren) return false;
       return children.length > 0;
     })(filteredChildren);
     return filteredEmptyChildrenParents;
  }, [t, hasAccess]);

  const handleChangeMenu = useCallback((item) => {
    navigate(item.key);
  }, [navigate]);

  const handleExit = useCallback(async (e) => {
    e.preventDefault();
    await localforage.clear();
    dispatch(authApi.util.resetApiState());
    dispatch(rootApi.util.resetApiState());
    navigate(routes.root());
  }, [dispatch, navigate]);

  const langs = useMemo(() => [
    {
      label: t('РУ'), value: 'ru',
    },
    {
      label: t('EN'), value: 'en',
    },
    {
      label: t('UZ'), value: 'uz',
    }
  ], [t]);

  const changeLanguage = useCallback((value) => {
    i18n.changeLanguage(value);
    dayjs.locale(value);
  }, [i18n]);

  return (
    <Layout hasSider>
      <ScrollToTop />
      <Sider
        className={styles.sider}
        collapsedWidth="0"
        breakpoint="lg"
        width={300}
      >
        <div className={styles.logo}>
          <img src="/images/logo-rus-white.png" alt=""/>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={menuItems}
          onClick={handleChangeMenu}
        />
      </Sider>
      <Layout className={styles.layout}>
        <Header
          className={styles.header}
          style={{ backgroundColor: colorBgContainer }}
        >
          <div className={styles.headerUser}>
            {
              isMeFetching
                ? <Spin size="small" />
                : (
                  <div className={styles.headerUserName}>
                    {me?.lastName} {me?.firstName} {me?.secondName} ({me?.roles[0]?.name})
                  </div>
                )
            }
          </div>
          <div className={styles.headerWrapper}>
            <Select
              defaultValue="ru"
              onChange={changeLanguage}
              options={langs}
            />
            <Button type="text" onClick={handleExit}>
              🚪 {t('Выход')}
            </Button>
          </div>
        </Header>
        <Content className={styles.content} style={{ backgroundColor: colorBgContainer }}>
          <Outlet />
        </Content>
        <Footer className={styles.footer}>
          Beshplus ©2023 Created by Endpoint with ❤️️
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Application;
