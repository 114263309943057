import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Details.module.scss';
import { useNavigate, useParams } from 'react-router';
import { useEditSubjectMutation, useGetSubjectQuery } from '../../../store/apis/dictionaries/subjects';
import { Form as AntForm, Input, InputNumber, message, Skeleton } from 'antd';
import MainError from '../../errors/MainError';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import Uploader from '../../../containers/shared/Uploader';
import { normFile } from '../../../utils';

const { Item: FormItem } = AntForm;

const Details = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editSubject, { isLoading: isEditingSubject }] = useEditSubjectMutation();

  const {
    data: subject,
    isLoading: isSubjectLoading,
    isFetching: isSubjectFetching,
    error: subjectError,
  } = useGetSubjectQuery(id);

  const handleSubmit = useCallback(async (body) => {
    try {
      await editSubject({ id, body }).unwrap();
      message.success(t('Предмет сохранен'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [editSubject, id, t]);

  const handleExit = useCallback(() => {
    navigate(routes.subjects());
  }, [navigate]);

  if (isSubjectLoading || isSubjectFetching) return <Skeleton />
  if (subjectError) return <MainError error={subjectError} />

  return (
    <div>
      <ContentHeader title={subject.sysName} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={subject}
        loading={isEditingSubject}
        saveTitle={t('Сохранить')}
        saveAndExitTitle={t('Сохранить и выйти')}
      >
        <FormItem
          label={t('Порядковый номер')}
          name="orderId"
        >
          <InputNumber />
        </FormItem>
        <FormItem
          label={t('Картинка предмета')}
          name="picturePath"
          getValueFromEvent={normFile}
          required
        >
          <Uploader
            maxCount={1}
            multiple={false}
            group="subjects"
            existedFileUrl={subject.pictureUrl}
          />
        </FormItem>
      </DictionaryForm>
    </div>
  );
};

export default Details;
