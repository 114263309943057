import React, { useCallback, useMemo, useState } from 'react';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import { Button, Skeleton, Space, Table } from 'antd';
import queryString from 'query-string';
import ContentHeader from '../../../components/ContentHeader';
import { useStaffListQuery } from '../../../store/apis/users';
import Form from '../Form';
import ChangeNameForm from '../ChangeNameForm';
import { sortBy, compose, toLower, prop } from 'ramda';
import ChangePasswordForm from '../ChangePasswordForm';
import ChangeRoleForm from '../ChangeRoleForm';

const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('fullName')));

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, ...query } = useQueryString();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ open: false, mode: null, initialValues: {} });
  const [changeNameFormState, setChangeNameFormState] = useState({ open: false, initialValues: {} });
  const [changePasswordFormState, setChangePasswordFormState] = useState({ open: false, initialValues: {} });
  const [changeRoleFormState, setChangeRoleFormState] = useState({ open: false, initialValues: {} });

  const {
    data: staffData,
    isLoading,
    isFetching,
  } = useStaffListQuery({ page, pageSize, ...query });

  const columns = useMemo(() => [
    {
      title: t('ФИО'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (data, record) => `${record.lastName} ${record.firstName} ${record.secondName}`,
    },
    {
      title: t('Действия'),
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setChangeNameFormState({ open: true, initialValues: record });
            }}
          >
            {t('Изменить ФИО')}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setChangePasswordFormState({ open: true, initialValues: record });
            }}
          >
            {t('Изменить пароль')}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setChangeRoleFormState({ open: true, initialValues: record });
            }}
          >
            {t('Изменить роль')}
          </Button>
        </Space>
      ),
    },
  ], [t]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...query }),
    })
  }, [navigate, query]);

  const openCreateForm = () => {
    setFormState({
      open: true,
      mode: 'CREATE',
      initialValues: {},
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Пользователи админ панели')}>
          <Button type="primary" onClick={openCreateForm}>
            {t('Создать')}
          </Button>
        </ContentHeader>
      </div>
      <div className={styles.body}>
        <Skeleton loading={isLoading || isFetching}>
          <Table
            rootClassName="overflow-scroll"
            columns={columns}
            dataSource={
              staffData?.results
               ? sortByNameCaseInsensitive(staffData?.results)
               : []
            }
            rowKey="id"
            pagination={{
              total: staffData?.count,
              current: page,
              locale: { items_per_page: `/ ${t('на странице')}` },
              pageSize,
              showSizeChanger: true,
              showTitle: true,
              pageSizeOptions: [20, 50, 100],
            }}
            onChange={handlePageChange}
            rowClassName={styles.bodyRow}
          />

          <Form
            open={formState.open}
            mode={formState.mode}
            initialValues={formState.initialValues}
            onFinish={() => {
              setFormState({ open: false, mode: null, initialValues: {} });
            }}
          />
          <ChangeNameForm
            open={changeNameFormState.open}
            initialValues={changeNameFormState.initialValues}
            onClose={() => {
              setChangeNameFormState({ open: false, initialValues: {} });
            }}
          />
          <ChangePasswordForm
            open={changePasswordFormState.open}
            initialValues={changePasswordFormState.initialValues}
            onClose={() => {
              setChangePasswordFormState({ open: false, initialValues: {} });
            }}
          />
          <ChangeRoleForm
            open={changeRoleFormState.open}
            id={changeRoleFormState.initialValues.id}
            onClose={() => {
              setChangeRoleFormState({ open: false, initialValues: {} });
            }}
          />
        </Skeleton>
      </div>
    </div>
  );
};

export default List;
