import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getVacancies: build.query({
      query: (params) => ({
        url: apiRoutes.vacancies(),
        params,
      }),
      providesTags: ['VACANCIES'],
    }),
    getVacancyDetails: build.query({
      query: (id) => ({
        url: apiRoutes.vacancies(id),
      }),
      providesTags: ['VACANCY_DETAILS'],
    }),
    editVacancy: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.vacancies(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['VACANCY_DETAILS', 'VACANCIES'] }),
    }),
    createVacancy: build.mutation({
      query: (body) => ({
        url: apiRoutes.vacancies(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['VACANCY_DETAILS', 'VACANCIES'] }),
    }),
  })
});

export const {
  useGetVacanciesQuery,
  useGetVacancyDetailsQuery,
  useEditVacancyMutation,
  useCreateVacancyMutation,
} = rootApi;
