import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getWithdrawals: builder.query({
      query: (params) => ({
        url: apiRoutes.withdrawals(),
        params,
      }),
      providesTags: ['WITHDRAWALS'],
    }),

    withdraw: builder.mutation({
      query: (body) => ({
        url: apiRoutes.withdraw(),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['WITHDRAWALS'] }),
    }),
  }),
});

export const {
  useGetWithdrawalsQuery,
  useWithdrawMutation,
} = rootApi;
