import React, { useCallback, useMemo } from 'react';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetTariffsQuery } from '../../../store/apis/administratives/tariffs';
import routes from '../../../router/routes';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Col, Row, Skeleton, Table } from 'antd';
import usePermissions from '../../../hooks/usePermissions';

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20 } = useQueryString();
  const { data: tariffs, isLoading: isTariffsLoading, isFetching: isTariffsFetching } = useGetTariffsQuery({ page, pageSize });
  const navigate = useNavigate();
  const hasAccess = usePermissions();

  const columns = useMemo(() => [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Порядковый номер'),
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: t('Системное имя'),
      dataIndex: 'sysName',
      key: 'sysName',
      render: (data, raw) => <Link to={routes.tariffs(raw.id)}>{data}</Link>
    },
    {
      title: t('Название'),
      dataIndex: 'name',
      key: 'name',
      render: (data) => data.ru,
    },
    {
      title: t('Процент скидки'),
      dataIndex: 'salePercentage',
      key: 'salePercentage',
    },
    {
      title: t('Количество уроков в пакете'),
      dataIndex: 'lessonCount',
      key: 'lessonCount',
    },
    {
      title: t('Длительность действия (месяцев)'),
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
    },
    {
      title: t('Активный?'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (data) => data ? '✅' : '❌',
    },
    {
      title: t('Опубликован?'),
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (data) => data ? '✅' : '❌',
    },
  ], [t]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize }),
    })
  }, [navigate]);

  const handleNavigateToCreate = useCallback(() => {
    navigate(routes.tariffs('create'));
  }, [navigate]);

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Тарифы')}>
          {
            hasAccess(['ADMIN', 'SUPER_ADMIN']) && (
              <Button type="primary" onClick={handleNavigateToCreate}>
                {t('Создать')}
              </Button>
            )
          }
        </ContentHeader>
      </div>
      <Row>
        <Col md={24}>
          {
            (isTariffsFetching || isTariffsLoading)
              ? <Skeleton loading />
              : (
                <Table
                  rootClassName="overflow-scroll"
                  loading={isTariffsFetching || isTariffsLoading}
                  columns={columns}
                  rowKey="id"
                  dataSource={tariffs?.results}
                  onChange={handlePageChange}
                  pagination={{
                    total: tariffs?.count,
                    current: page,
                    locale: { items_per_page: `/ ${t('на странице')}` },
                    pageSize,
                    showSizeChanger: true,
                    showTitle: true,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              )
          }
        </Col>
      </Row>
    </div>
  );
};

export default List;
