// import cn from 'classnames';

import styles from './BookkeepingCreation.module.scss';
import { useBookkeepingOnFlyMutation, useCreateBookkeepingMutation } from '../../../store/apis/finances/bookkeeping';
import { useEffect } from 'react';
import { Button, Col, Empty, message, Popconfirm, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import BookkeepingDetailsTable from '../../../containers/bookkeeping/BookkeepingDetailsTable';
import { formatDate } from '../../../utils';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import dayjs from 'dayjs';

const BookkeepingCreation = () => {
  const [t] = useTranslation();
  const { datetime } = useQueryString();
  const navigate = useNavigate();

  const [
    generateBookkeepingOnFly,
    {
      isLoading: isGenerating,
      data: bookkeepingDetails,
    },
  ] = useBookkeepingOnFlyMutation();

  const [
    createBookeeping,
    { isLoading: isCreating },
  ] = useCreateBookkeepingMutation();

  const handleCreateBookkeeping = async () => {
    try {
      await createBookeeping({ toDate: dayjs(datetime).format('YYYY-MM-DD') }).unwrap();
      message.success(t('Ведомость сформирована'));
      navigate(routes.tutorBookkeeping());
    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    if (!datetime) return;
    generateBookkeepingOnFly({
      toDatetime: datetime,
    });
  }, [datetime, generateBookkeepingOnFly]);

  if (!datetime) {
    return (
      <Empty description={t('Как Вы сюда попали без указания даты? Вы что хакер?')} />
    );
  }

  if (isGenerating) {
    return (
      <Row justify="center" style={{ padding: 40 }}>
        <Col>
          <Spin spinning size="large" tip={t('Этот процесс может занять немного времени, пожалуйста подождите')} />
        </Col>
      </Row>
    );
  }

  if (!bookkeepingDetails) {
    return (
      <Empty description={t('Не удалось получить данные, попробуйте снова')} />
    );
  }

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <Typography.Title level={4}>
            {t('Ведомость для формирования')} ({t('по')} {formatDate(datetime)} включительно)
          </Typography.Title>
          <Typography.Text>
            {t('Пожалуйста, проверьте все детали внимательно')}.
          </Typography.Text>
        </Col>
        <Col>
          <Popconfirm
            title={t('Вы уверены?')}
            onConfirm={handleCreateBookkeeping}
            okButtonProps={{
              loading: isCreating,
            }}
          >
            <Button
              type="primary"
            >
              {t('Сформировать ведомость')}
            </Button>
          </Popconfirm>
        </Col>
      </Row>
      <BookkeepingDetailsTable
        data={bookkeepingDetails.paychecks}
        hasPayAction={false}
      />
    </div>
  );
};

export default BookkeepingCreation;
