import { rootApi } from '../index';
import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';

rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getStudents: builder.query({
      query: (params) => ({
        url: apiRoutes.students(),
        params,
      }),
      providesTags: ['STUDENTS'],
    }),

    getStudentDetails: builder.query({
      query: (id) => ({
        url: apiRoutes.students(id),
      }),
      providesTags: ['STUDENT_DETAILS'],
    }),

    deactivateStudent: builder.mutation({
      query: (id) => ({
        url: apiRoutes.deactivateStudent(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['STUDENT_DETAILS', 'STUDENTS'] }),
    }),
    activateStudent: builder.mutation({
      query: (id) => ({
        url: apiRoutes.activateStudent(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['STUDENT_DETAILS', 'STUDENTS'] }),
    }),

    getStudentClientCardComments: builder.query({
      query: ({ id, params }) => ({
        url: apiRoutes.clientCardComments(id),
        params,
      }),
      providesTags: ['CLIENT_CARD_COMMENTS'],
    }),
    postClientCardComment: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.clientCardComments(id),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['CLIENT_CARD_COMMENTS'] }),
    }),
    updateClientCard: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.clientCard(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['STUDENT_DETAILS'] }),
    }),

    getStudentSchedules: builder.query({
      query: ({ studentId, params }) => ({
        url: apiRoutes.studentSchedules(studentId),
        params,
      }),
      providesTags: ['STUDENT_SCHEDULES'],
    }),
    cancelLesson: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.cancelLesson(id),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['STUDENT_SCHEDULES'] }),
    }),
    transferLesson: builder.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.transferLesson(id),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['STUDENT_SCHEDULES'] }),
    }),
  }),
});

export const {
  useGetStudentsQuery,
  useLazyGetStudentsQuery,
  useGetStudentDetailsQuery,
  useLazyGetStudentDetailsQuery,
  useDeactivateStudentMutation,
  useActivateStudentMutation,
  useGetStudentClientCardCommentsQuery,
  usePostClientCardCommentMutation,
  useUpdateClientCardMutation,
  useGetStudentSchedulesQuery,
  useCancelLessonMutation,
  useTransferLessonMutation,
} = rootApi;
