import { joinExisting } from './utils';

export const baseUrl = process.env.REACT_APP_HOST;

export default {
  login: () => joinExisting(['/account/staff/login']),
  me: () => joinExisting(['/account/staff/me']),
  refreshToken: () => joinExisting(['/account/public/tokens/refresh']),

  // dictionaries
  subjects: (id) => joinExisting(['/search/staff/dicts/subjects', id]),
  languages: (id) => joinExisting(['/search/staff/dicts/languages', id]),
  languageLevels: (id) => joinExisting(['/search/staff/dicts/language-skill-levels', id]),
  educationLevels: (id) => joinExisting(['/search/staff/dicts/education-levels', id]),
  educationInstitution: (id) => joinExisting(['/search/staff/dicts/education-institutions', id]),
  learningGoals: (id) => joinExisting(['/search/staff/dicts/learning-goals', id]),
  qualifications: (id) => joinExisting(['/search/staff/dicts/qualifications', id]),
  workExperienceCategories: (id) => joinExisting(['/search/staff/dicts/work-experience-categories', id]),
  ageCategories: (id) => joinExisting(['/search/staff/dicts/age-categories', id]),
  priceCategories: (id) => joinExisting(['/search/staff/dicts/price-categories', id]),
  packageCancellationRequestsReasons: (id) => joinExisting(['/learning/staff/package-cancellation-request-reasons', id]),

  //administratives
  tariffs: (id) => joinExisting(['/learning/staff/tariffs', id]),
  promoTariffs: () => joinExisting(['/student-promotion/staff/bonus-packages/available-tariffs']),
  addPromoTariffToStudent: () => joinExisting(['/student-promotion/staff/bonus-packages']),
  faqs: (id) => joinExisting(['/search/staff/faq', id]),
  faqTags: () => joinExisting(['/search/staff/faq-tags']),
  tutors: (id) => joinExisting(['/search/staff/tutor-profiles', id]),
  tutorsEducation: (id) => joinExisting(['/search/staff/tutor-profile-educations', id]),
  tutorsCertificates: (id) => joinExisting(['/search/staff/tutor-profile-certificates', id]),
  tutorsWorkExperience: (id) => joinExisting(['/search/staff/tutor-profile-work-experiences', id]),
  tutorsLanguageSkill: (id) => joinExisting(['/search/staff/tutor-profile-language-skills', id]),
  tutorWorkTimes: (tutorId) => joinExisting(['/learning/staff/as-tutor', tutorId, 'work-schedules']),
  reviews: (id) => joinExisting(['/search/staff/tutor-reviews', id]),
  learningTutorProfile: (tutorId) => joinExisting(['/learning/staff/tutors', tutorId]),
  initialTutorRate: (tutorId) => joinExisting(['/search/staff/tutor-profiles', tutorId, 'rating']),
  makeTutorPublic: (tutorId) => joinExisting(['/search/staff/tutor-profiles', tutorId, 'publish']),
  cancelTutorPublic: (tutorId) => joinExisting(['/search/staff/tutor-profiles', tutorId, 'cancel-publication']),
  changeTutorPassword: (tutorId) => joinExisting(['account/staff/tutors', tutorId, 'change-password']),
  changeTutorPhone: (tutorId) => joinExisting(['account/staff/tutors', tutorId, 'change-phone']),
  publishReviews: (id) => joinExisting(['/search/staff/tutor-reviews', id, 'publish']),
  cancelReviews: (id) => joinExisting(['/search/staff/tutor-reviews', id, 'cancel']),
  students: (id) => joinExisting(['/search/staff/student-profiles', id]),
  activateStudent: (id) => joinExisting(['/search/staff/student-profiles', id, 'activate']),
  deactivateStudent: (id) => joinExisting(['/search/staff/student-profiles', id, 'deactivate']),
  successStories: (id) => joinExisting(['/content/staff/success-stories', id]),
  socialLinks: (id) => joinExisting(['/content/staff/social-network-pages', id]),
  contacts: (id) => joinExisting(['/content/staff/contact-info', id]),
  usersNotFinishedRegistration: (id) => joinExisting(['/account/staff/reports/not-finished-registration-attempt', id]),
  legalDocuments: (id) => joinExisting(['/content/staff/legal-documents', id]),
  chapters: (id) => joinExisting(['/content/staff/legal-document-chapters', id]),
  notifications: (id) => joinExisting(['/user-notification/notification-types', id]),
  deliveryMethods: () => joinExisting(['/user-notification/delivery-methods']),
  activateDeliveryMethodForNotification: (id) => joinExisting(['/user-notification/notification-types', id, 'activate-delivery-method']),
  deactivateDeliveryMethodForNotification: (id) => joinExisting(['/user-notification/notification-types', id, 'deactivate-delivery-method']),
  activateDeliveryMethodGlobally: (id) => joinExisting(['/user-notification/delivery-methods', id, 'activate']),
  deactivateDeliveryMethodGlobally: (id) => joinExisting(['/user-notification/delivery-methods', id, 'deactivate']),
  settings: (id) => joinExisting(['/learning/staff/service-configs']),
  accountSettings: (id) => joinExisting(['/account/staff/service-configs']),
  vacancies: (id) => joinExisting(['/content/staff/vacancies', id]),
  tutorStory: (id) => joinExisting(['/content/staff/tutor-stories', id]),
  clientCard: (id) => joinExisting(['/search/staff/student-profiles', id, 'client-card']),
  clientCardComments: (id) => joinExisting(['/search/staff/student-profiles', id, 'client-card/comments']),
  packages: () => joinExisting(['/learning/staff/packages']),
  transferPackage: (id) => joinExisting(['/learning/staff/packages', id, 'change-tutor']),
  studentSchedules: (id) => joinExisting(['/learning/staff/as-student', id, 'lesson-schedules/lessons']),
  cancelLesson: (id) => joinExisting(['/learning/staff/lesson-schedules', id, 'cancel-lesson']),
  transferLesson: (id) => joinExisting(['/learning/staff/lesson-schedules', id, 'transfer-lesson']),
  lessonSchedules: (id) => joinExisting(['/learning/staff/lesson-schedules', id]),
  videoAboutPlatform: () => joinExisting(['/content/staff/constructs/main-page-banner']),

  tutorCv: (id) => joinExisting(['/cv/staff/tutor-employment-offers', id]),

  uploadFile: () => joinExisting(['/static-content/staff/files']),

  // finances
  lessons: (id) => joinExisting(['/learning/staff/lessons', id]),
  withdrawals: () => joinExisting(['/learning/staff/tutors-funds-withdrawals/candidates']),

  bookkeepingOnFly: () => joinExisting(['/learning/staff/tutor-bookkeeping/wage-payments/calculate-preliminary']),
  bookkeeping: (id) => joinExisting(['/learning/staff/tutor-bookkeeping/wage-payments', id]),
  bookkeepingPayAll: (id) => joinExisting(['/learning/staff/tutor-bookkeeping/wage-payments', id, 'make-paid']),
  bookkeepingPayOne: (id) => joinExisting(['/learning/staff/tutor-bookkeeping/paychecks', id, 'make-paid']),
  bookkeepingDetailsByTutor: () => joinExisting(['/learning/staff/tutor-bookkeeping/paychecks']),

  withdraw: () => joinExisting(['/learning/staff/tutors-funds-withdrawals']),
  packageCancellationRequests: () => joinExisting(['/learning/staff/package-cancellation-requests']),
  rejectPackageCancellationRequest: (id) => joinExisting(['/learning/staff/package-cancellation-requests', id, 'reject']),
  approvePackageCancellationRequest: (id) => joinExisting(['/learning/staff/package-cancellation-requests', id, 'approve']),
  annulLesson: (id) => joinExisting(['/learning/staff/lessons', id, 'annul']),

  // reports
  dashboard: () => joinExisting(['/report/staff/dashboards/main']),
  statsByTutor: () => joinExisting(['/report/staff/reports/tutors-statistics']),
  statsByTariff: () => joinExisting(['/report/staff/reports/tariffs-statistics']),
  statsBySubject: () => joinExisting(['/report/staff/reports/subjects-statistics']),
  tutorsWithdrawals: () => joinExisting(['/report/staff/reports/tutors-total-withdrawal']),
  paymentStats: () => joinExisting(['/report/staff/lists/payments']),
  packageCancellationDashboard: () => joinExisting(['/report/staff/dashboards/package-cancellation-requests']),
  packageCancellationByTutor: () => joinExisting(['/report/staff/reports/package-cancellation-requests-by-tutors']),
  debitsByTutor: () => joinExisting(['/report/staff/reports/system-debits-by-tutors']),
  movementsByTutor: () => joinExisting(['/report/staff/reports/lesson-movement-by-tutors']),
  smsToPlaymobile: () => joinExisting(['/sms-to-playmobile/staff/request-response-logs']),
  scheduleLogs: () => joinExisting(['/action-log/staff/staff-logs']),

  // users
  staff: (id) => joinExisting(['/account/staff/staffs', id]),
  staffChangePassword: (id) => joinExisting(['/account/staff/staffs', id, 'change-password']),
  staffChangeRoles: (id) => joinExisting(['/account/staff/staffs', id, 'change-roles']),
};
