import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Row, Select, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { identity } from 'ramda';
import { formatDate } from '../../../utils';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';

const FormItem = Form.Item;

const TrialLessonsListFilters = () => {
  const [t, i18n] = useTranslation();
  const [isOpen, show, hide] = useToggle();
  const navigate = useNavigate();

  const { data: tutors, isFetching: isTeachersFetching } = useGetTutorsQuery({ page: -1 });
  const { data: subjects, isFetching: isSubjectsFetching } = useGetSubjectsQuery({ page: -1 });

  const submitForm = useCallback((values) => {

    const query = {
      ...values,
      lessonTimeAt: values.lessonTimeAt ? values.lessonTimeAt.startOf('day').local().toISOString() : null,
    };

    navigate({
      pathname: '',
      search: queryString.stringify(query, { skipNull: true, skipEmptyString: true }),
    });
    hide();
  }, [t, navigate, hide]);

  const reset = useCallback(() => {
    navigate({ pathname: '' });
    hide();
  }, [hide, navigate]);

  const tutorOptions = (tutors && tutors.results)
    ? tutors.results.map((tutor) => ({
      value: tutor.id,
      label: `${tutor.fullName[i18n.language]} - ${tutor.subjects[0]?.name[i18n.language]}`,
    }))
    : [];

  const subjectOptions = (subjects && subjects.results)
    ? subjects.results.map((subject) => ({
      value: subject.id,
      label: subject.name[i18n.language],
    }))
    : [];


  return (
    <>
      <Tooltip title={t('Фильтры')}>
        <Button
          type="primary"
          shape="circle"
          icon={<FilterOutlined />}
          onClick={show}
        />
      </Tooltip>
      <Drawer
        title={t('Фильтры')}
        placement="right"
        onClose={hide}
        open={isOpen}
      >
        <Form
          onFinish={submitForm}
          layout="vertical"
        >
          <FormItem label={t('Учитель')} name="tutorId">
            <Select
              options={tutorOptions}
              loading={isTeachersFetching}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </FormItem>
          <FormItem label={t('Предмет')} name="subject">
            <Select
              options={subjectOptions}
              loading={isSubjectsFetching}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </FormItem>

          <FormItem label={t('Показать уроки за день')} name="lessonTimeAt">
            <DatePicker
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              placeholder={t('Выберите дату')}
              allowClear
            />
          </FormItem>

          <Row justify="space-between">
            <Col>
              <Button onClick={reset} htmlType="reset">
                {t('Сбросить')}
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                {t('Показать')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default TrialLessonsListFilters;
