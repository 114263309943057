import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './UsersNotFinishedRegistrationList.module.scss';
import {
  useGetUsersNotFinishedRegistrationQuery
} from '../../../store/apis/administratives/usersNotFinishedREgistration';
import { Col, DatePicker, Row, Space, Table, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import routes from '../../../router/routes';
import queryString from 'query-string';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import ContentHeader from '../../../components/ContentHeader';
import dayjs from 'dayjs';

const UsersNotFinishedRegistrationList = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, sort = '-id', ...restQuery } = useQueryString();
  const navigate = useNavigate();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);

  const {
    data: usersList,
    isLoading: isUsersListLoading,
    isFetching: isUsersListFetching,
  } = useGetUsersNotFinishedRegistrationQuery({
    page,
    pageSize,
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  const tags = {
    'student-registration': {
      color: 'blue',
      text: t('Регистрация'),
    },
    'student-password-recovery': {
      color: 'red',
      text: t('Восстановление пароля'),
    },
  };

  const columns = [
    {
      title: t('Номер телефона'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('Количество СМС'),
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: t('Типы СМС'),
      dataIndex: 'attempts',
      key: 'attempts',
      render: (attempts) => (
        <div>
          {attempts.map(({ otpType, createdTime }, i) => (
            <Tag key={i} color={tags[otpType].color}>
              {tags[otpType].text}
            </Tag>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ContentHeader title={t('Пользователи не воспользовались СМС кодом')} />
      <Typography.Text>{t('Данные пользователи запросили СМС код, но не воспользовались им')}</Typography.Text>
      <Row style={{ marginBottom: 32, marginTop: 32 }}>
        <Col>
          <Space>
            <Typography.Text>{t('Выберите период')}</Typography.Text>
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              onChange={changeFilterDate}
              value={lessonDateDiapason}
              allowClear={false}
            />

          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={usersList?.results}
        loading={isUsersListFetching || isUsersListLoading}
        rowKey="lastId"
        onChange={handlePageChange}
        pagination={{
          total: usersList?.count,
          current: page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </div>
  );
};

export default UsersNotFinishedRegistrationList;
