import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './StatsByPackageCancellations.module.scss';
import {
  useGetPackageCancellationByTutorQuery,
  useGetPackageCancellationDashboardQuery
} from '../../../store/apis/reports/dashboard';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import { Col, DatePicker, Row, Skeleton, Space, Spin, Statistic, Table, Tag, Typography } from 'antd';
import { blue, green, red } from '@ant-design/colors';
import dayjs from 'dayjs';
import { formatNumber, joinExisting } from '../../../utils';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import usePermissions from '../../../hooks/usePermissions';

const { Title, Text } = Typography;

const StatsByPackageCancellations = () => {
  const [t] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);
  const hasAccess = usePermissions();

  const {
    data: dashboard,
    isLoading: isDashboardLoading,
    isFetching: isDashboardFetching,
    error: dashboardError,
  } = useGetPackageCancellationDashboardQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const {
    data: byTutor,
    isLoading: isByTutorLoading,
    isFetching: isByTutorFetching,
    error: byTutorError,
  } = useGetPackageCancellationByTutorQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const columns = [
    {
      title: t('Учитель'),
      dataIndex: 'id',
      key: 'id',
      render: (id, raw) => hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])
        ? <Link to={routes.teachers(id)}>{joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')}</Link>
        : joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')
    },
    {
      title: t('Количество новых запросов'),
      dataIndex: 'newCountTotal',
      key: 'newCountTotal',
      render: (count) => <Tag color="blue">{count}</Tag>,
    },
    {
      title: t('Сумма новых запросов'),
      dataIndex: 'newAmountTotal',
      key: 'newAmountTotal',
      render: (count) => <Tag color="blue">{formatNumber(count)}</Tag>,
    },
    {
      title: t('Количество одобренных запросов'),
      dataIndex: 'approvedCount',
      key: 'approvedCount',
      render: (count) => <Tag color="green">{count}</Tag>,
    },
    {
      title: t('Сумма одобренных запросов'),
      dataIndex: 'approvedAmount',
      key: 'approvedAmount',
      render: (count) => <Tag color="green">{formatNumber(count)}</Tag>,
    },
    {
      title: t('Количество отклоненных запросов'),
      dataIndex: 'rejectedCount',
      key: 'rejectedCount',
      render: (count) => <Tag color="red">{count}</Tag>,
    },
    {
      title: t('Сумма отклоненных запросов'),
      dataIndex: 'rejectedAmount',
      key: 'rejectedAmount',
      render: (count) => <Tag color="red">{formatNumber(count)}</Tag>,
    },
  ];

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  if (isDashboardLoading || isByTutorLoading) return <Skeleton />
  if (dashboardError || byTutorError) return (
    <div>
      <Title level={3}>Ой, кажется произошла ошибка</Title>
      <pre>{JSON.stringify(dashboardError)}</pre>
      <pre>{JSON.stringify(byTutorError)}</pre>
    </div>
  );
  if (!dashboard || !byTutor) return null;

  return (
    <div>
      <ContentHeader title={t('Статистика по возвратам средств')}/>
      <Space>
        <Text>{t('Статистика за период')}</Text>
        <DatePicker.RangePicker
          presets={rangePresets}
          format="DD.MM.YYYY"
          onChange={changeFilterDate}
          value={lessonDateDiapason}
          allowClear={false}
        />
      </Space>

      <Spin tip={t('Подождите')} spinning={isDashboardFetching || isByTutorFetching}>
        <Row style={{ marginTop: 16 }}>
          <Col md={8}>
            <Statistic
              title={t('Количество новых запросов')}
              value={dashboard.data.newCount}
              groupSeparator="'"
              valueStyle={{ color: blue.primary }}
            />
            <Statistic
              title={t('Сумма новых запросов')}
              value={dashboard.data.newAmount}
              groupSeparator="'"
              valueStyle={{ color: blue.primary }}
              suffix={t('сум')}
              style={{ marginTop: 20 }}
            />
          </Col>
          <Col md={8}>
            <Statistic
              title={t('Количество одобренных запросов')}
              value={dashboard.data.approvedCount}
              groupSeparator="'"
              valueStyle={{ color: green.primary }}
            />
            <Statistic
              title={t('Сумма одобренных запросов')}
              value={dashboard.data.approvedAmount}
              groupSeparator="'"
              valueStyle={{ color: green.primary }}
              suffix={t('сум')}
              style={{ marginTop: 20 }}
            />
          </Col>
          <Col md={8}>
            <Statistic
              title={t('Количество отклоненных запросов')}
              value={dashboard.data.rejectedCount}
              groupSeparator="'"
              valueStyle={{ color: red.primary }}
            />
            <Statistic
              title={t('Сумма отклоненных запросов')}
              value={dashboard.data.rejectedAmount}
              groupSeparator="'"
              valueStyle={{ color: red.primary }}
              suffix={t('сум')}
              style={{ marginTop: 20 }}
            />
          </Col>
        </Row>
        <Title level={3} style={{ marginTop: 50 }}>{t('По учителям')}</Title>
        <Table
          columns={columns}
          rootClassName="overflow-scroll"
          rowKey="id"
          dataSource={byTutor.rows}
          style={{ marginTop: 16 }}
          pagination={{
            pageSize: 20,
          }}
        />
      </Spin>
    </div>
  );
};

export default StatsByPackageCancellations;
