import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils';


export const rootApi = createApi({
  reducerPath: 'root',
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: () => ({}),
});
