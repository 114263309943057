import React, { useCallback } from 'react';
import { message, Typography } from 'antd';
import TariffForm from '../Form';
import { useTranslation } from 'react-i18next';
import { useCreateTariffMutation } from '../../../store/apis/administratives/tariffs';

const Create = () => {
  const [t] = useTranslation();
  const [createTariff, { isLoading: isCreateLoading, isFetching: isCreateFetching }] = useCreateTariffMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await createTariff(values).unwrap();
      message.success(t('Тариф создан'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [createTariff, t]);

  return (
    <div>
      <Typography.Title level={4}>{t('Создание нового тарифа')}</Typography.Title>
      <TariffForm
        initialValues={{}}
        onSubmit={handleSubmit}
        loading={isCreateLoading || isCreateFetching}
        saveAndResetLabel={t('Сохранить и создать еще')}
      />
    </div>
  )
};

export default Create;
