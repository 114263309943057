import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTariffs: build.query({
      query: (params) => ({
        url: apiRoutes.tariffs(),
        params,
      }),
      providesTags: ['TARIFFS'],
    }),
    getTariff: build.query({
      query: (id) => ({
        url: apiRoutes.tariffs(id),
      }),
      providesTags: ['TARIFF'],
    }),
    editTariff: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.tariffs(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFF', 'TARIFFS'] }),
    }),
    createTariff: build.mutation({
      query: (body) => ({
        url: apiRoutes.tariffs(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFF', 'TARIFFS'] }),
    }),

    getPromoTariffs: build.query({
      query: (params) => ({
        url: apiRoutes.promoTariffs(),
        params,
      }),
      providesTags: ['PROMO_TARIFFS'],
    }),
    addPromoTariffToStudent: build.mutation({
      query: (body) => ({
        url: apiRoutes.addPromoTariffToStudent(),
        method: 'POST',
        body,
      }),
    }),
  })
});

export const {
  useGetTariffsQuery,
  useGetTariffQuery,
  useEditTariffMutation,
  useCreateTariffMutation,
  useGetPromoTariffsQuery,
  useAddPromoTariffToStudentMutation,
} = rootApi;
