// import cn from 'classnames';

import { Button, DatePicker, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../hooks/useToggle';
import { useNavigate } from 'react-router';
import routes from '../../router/routes';
import queryString from 'query-string';
import dayjs from 'dayjs';

const TakeDateForBookkeepingModal = () => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    const datetime = values.toDatetime.endOf('day').toISOString();
    navigate({
      pathname: routes.tutorBookkeepingCreation(),
      search: queryString.stringify({ datetime }),
    })
  };

  return (
    <>
      <Button
        onClick={open}
        type="primary"
      >
        {t('Сформировать новую ведомость')}
      </Button>
      <Modal
        open={isOpen}
        title={t('Выберите дату')}
        onOk={() => {
          form.submit()
        }}
        onCancel={() => {
          form.resetFields();
          close();
        }}
        okText={t('Выбрать')}
        cancelText={t('Закрыть')}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item label={t('Выберите дату')} name="toDatetime" rules={[{ required: true }]}>
            <DatePicker
              style={{ width: '100%' }}
              format="DD.MM.YYYY"
              disabledDate={(current) => {
                return current && current >= dayjs().startOf('day');
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TakeDateForBookkeepingModal;
