import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLearningGoals: build.query({
      query: (params) => ({
        url: apiRoutes.learningGoals(),
        params,
      }),
      providesTags: ['LEARNING_GOALS'],
    }),
    getLearningGoal: build.query({
      query: (langId) => ({
        url: apiRoutes.learningGoals(langId),
      }),
      providesTags: ['LEARNING_GOAL'],
    }),
    editLearningGoal: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.learningGoals(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['LEARNING_GOALS', 'LEARNING_GOAL'] }),
    }),
    createLearningGoal: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.learningGoals(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['LEARNING_GOALS', 'LEARNING_GOAL'] }),
    }),
  })
});

export const {
  useGetLearningGoalsQuery,
  useGetLearningGoalQuery,
  useEditLearningGoalMutation,
  useCreateLearningGoalMutation,
} = rootApi;
