import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Details.module.scss';
import { useParams } from 'react-router';
import {
  useActivateStudentMutation,
  useDeactivateStudentMutation,
  useGetStudentDetailsQuery
} from '../../../store/apis/administratives/students';
import { Badge, Button, Card, Col, message, Popconfirm, Row, Skeleton, Space, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/ActionButton';
import { FiGift, FiLock, FiPlus, FiUnlock } from 'react-icons/fi';
import AddPromoPackage from '../../../containers/students/AddPromoPackage';
import StatsTabs from '../../../containers/students/StatsTabs';
import usePermissions from '../../../hooks/usePermissions';

const { Title, Text } = Typography;

const format = (value, pattern) => {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, _ => v[i++]);
}

const Details = () => {
  const { id } = useParams();
  const [t] = useTranslation();

  const checkPermissions = usePermissions();

  const [
    deactivateStudent,
    {
      isLoading: isDeactivateStudentLoading,
    }
  ] = useDeactivateStudentMutation();
  const [
    activateStudent,
    {
      isLoading: isActivateStudentLoading,
    }
  ] = useActivateStudentMutation();

  const {
    data: student,
    isLoading,
    isFetching,
    error,
  } = useGetStudentDetailsQuery(id, { skip: !id });

  const blockStudent = async () => {
    try {
      await deactivateStudent(id).unwrap();
      message.success(t('Студент деактивирован'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };
  const unblockStudent = async () => {
    try {
      await activateStudent(id).unwrap();
      message.success(t('Студент активирован'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  };

  if (isLoading || isFetching) return (
    <Skeleton loading />
  );

  if (error) return (
    <div>{JSON.stringify(error)}</div>
  );

  return (
    <div className={styles.wrapper}>
      <Row gutter={16}>
        <Col md={5} xl={4}>
          <div className={styles.photo}>
            <img src={student.photoUrl} alt="" />
          </div>
        </Col>
        <Col md={14} xl={16}>
          <Title level={2} style={{ marginBottom: 0, marginTop: 0 }}>{student.fullName}</Title>
          <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>{format(student.phoneNumber, '+ ### ## ### ## ##')}</Title>
          <Title level={4} style={{ marginTop: 0 }}>{student.email}</Title>
          <Text>
            <Text strong>{t('О себе')}</Text>: {student.introductionText}
          </Text>

          <StatsTabs />
        </Col>
        <Col md={5} xl={4}>
          {
            checkPermissions(['SUPER_ADMIN']) && (
              <Space direction="vertical" style={{ width: '100%' }}>
                {
                  student.isActive
                    ? (
                      <Popconfirm title={t('Вы уверены?')} onConfirm={blockStudent}>
                        <ActionButton color="red" text={t('Заблокировать')} icon={<FiLock />} />
                      </Popconfirm>
                    ) : (
                      <Popconfirm title={t('Вы уверены?')} onConfirm={unblockStudent}>
                        <ActionButton color="green" text={t('Разблокировать')} icon={<FiUnlock />} />
                      </Popconfirm>
                    )
                }
                <AddPromoPackage studentId={student.id} />
              </Space>
            )
          }
        </Col>
      </Row>
    </div>
  );
};

export default Details;
