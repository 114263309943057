import React, { useCallback, useState } from 'react';

import styles from './TrialLessonsList.module.scss';
import ContentHeader from '../../../components/ContentHeader';
import { Button, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetLessonSchedulesQuery } from '../../../store/apis/administratives/lessonSchedules';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import useQueryString from '../../../hooks/useQueryString';
import { formatDate } from '../../../utils';
import dayjs from 'dayjs';
import { useLazyGetStudentDetailsQuery } from '../../../store/apis/administratives/students';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import TrialLessonsListFilters from '../TrialLessonsListFilters';
import QueryView from '../../../containers/shared/QueryView';
import { filter, find, omit, propEq } from 'ramda';
import { useGetTutorsQuery } from '../../../store/apis/administratives/tutors';
import { useGetSubjectsQuery } from '../../../store/apis/dictionaries/subjects';

const TrialLessonsList = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();

  const [revealedPhones, setRevealedPhones] = useState({});

  const [
    getStudentDetails,
    { isFetching: isStudentFetching },
  ] = useLazyGetStudentDetailsQuery();

  const {
    data: lessonSchedules,
    isFetching: isLessonSchedulesFetching,
  } = useGetLessonSchedulesQuery({
    sort: '-startAtDate,timeSpan',
    tariffGroup: 'trial',
    ...restQuery,
    page,
    pageSize,
  });
  const { data: tutors, isFetching: isTeachersFetching } = useGetTutorsQuery({ page: -1 });
  const { data: subjects, isFetching: isSubjectsFetching } = useGetSubjectsQuery({ page: -1 });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const getPhone = (id) => async () => {
    const studentDetails = await getStudentDetails(id).unwrap();

    setRevealedPhones({
      ...revealedPhones,
      [id]: studentDetails.phoneNumber,
    })
  };

  const columns = [
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (tutor) => (
        <div>{tutor.fullName[i18n.language]}</div>
      ),
    },
    {
      title: t('Ученик'),
      dataIndex: 'student',
      key: 'student',
      render: (student) => (
        <div>
          <Link to={routes.students(student.id)}>
            {student.fullName[i18n.language]}
          </Link>
        </div>
      ),
    },
    {
      title: t('Телефон'),
      dataIndex: 'student',
      key: 'phone',
      width: 200,
      render: (student) => (
        <div>
          {
            revealedPhones[student.id] || (
              <Button
                type="link"
                size="small"
                style={{ paddingLeft: 0 }}
                onClick={getPhone(student.id)}
              >
                {t('Показать номер телефона')}
              </Button>
            )
          }
        </div>
      ),
    },
    {
      title: t('Предмет'),
      dataIndex: 'subject',
      key: 'subject',
      render: (subject) => (
        <div>{subject.name[i18n.language]}</div>
      ),
    },
    {
      title: t('Тип занятия'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <div>{type.name[i18n.language]}</div>
      ),
    },
    {
      title: t('Дата и время урока'),
      dataIndex: 'singleLessonTime',
      key: 'singleLessonTime',
      render: (datetime) => (
        <div>
          {formatDate(datetime, true)}
          <br/>
          <span style={{ fontWeight: 700 }}>{datetime ? dayjs(datetime).fromNow() : null}</span>
        </div>
      ),
    },
  ];

  const findById = (id, list) => (list && id) ? find(propEq(id, 'id'), list) : {};

  const queryView = filter(({ value }) => value)([
    {
      id: 'subject',
      label: `${t('Предмет')}: ${findById(restQuery.subject, subjects?.results)?.name?.[i18n.language]}`,
      value: restQuery.subject,
    },
    {
      id: 'tutorId',
      label: `${t('Учитель')}: ${findById(restQuery.tutorId, tutors?.results)?.fullName?.[i18n.language]}`,
      value: restQuery.tutorId,
    },
  ]);

  const onClearTag = (tag) => {

    const newQuery = omit([tag.id])({
      page,
      pageSize,
      ...restQuery,
    });
    navigate({
      pathname: '',
      search: queryString.stringify(newQuery, { skipNull: true, skipEmptyString: true }),
    });
  };

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Ближайшие пробные уроки')}>
          <TrialLessonsListFilters />
        </ContentHeader>
        <QueryView
          label={t('Показаны пробные уроки')}
          tags={queryView}
          onClose={onClearTag}
        />
      </div>
      <Row>
        <Col md={24}>
          <Table
            columns={columns}
            loading={isLessonSchedulesFetching}
            dataSource={lessonSchedules?.results}
            rowKey="id"
            onChange={handlePageChange}
            pagination={{
              total: lessonSchedules?.count,
              current: page,
              locale: { items_per_page: `/ ${t('на странице')}` },
              pageSize,
              showSizeChanger: true,
              showTitle: true,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TrialLessonsList;
