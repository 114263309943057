import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './Create.module.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useCreateFaqMutation,
  useEditFaqMutation,
  useGetFaqDetailsQuery
} from '../../../store/apis/administratives/faqs';
import { message, Skeleton, Typography } from 'antd';
import FaqForm from '../Form';

const Create = () => {
  const [t] = useTranslation();

  const [createFaq, { isLoading: isCreateLoading, isFetching: isCreateFetching }] = useCreateFaqMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await createFaq(values).unwrap();
      message.success(t('ВиО создан'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [createFaq, t]);

  return (
    <div>
      <Typography.Title level={4}>{t('Вопрос')}</Typography.Title>
      <FaqForm
        initialValues={{}}
        onSubmit={handleSubmit}
        loading={isCreateLoading || isCreateFetching}
        saveAndResetLabel={t('Сохранить и создать еще')}
      />
    </div>
  );
};

export default Create;
