import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useActivateDeliveryMethodForNotificationMutation,
  useDeactivateDeliveryMethodForNotificationMutation,
  useGetDeliveryMethodsQuery,
  useGetNotificationDetailsQuery
} from '../../../store/apis/administratives/notifications';
import { Alert, Col, message, Row, Skeleton, Space, Tag, Typography } from 'antd';
import FetchErrorContent from '../../../containers/shared/FetchErrorContent';
import { sort } from 'ramda';
import ContentHeader from '../../../components/ContentHeader';
import EditNotification from '../EditNotification';
import { useApiAliases } from '../../../apiAliases';

const { Title, Text } = Typography;

const Details = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();
  const apiAliases = useApiAliases();

  const {
    data: notificationData,
    isLoading: isNotificationDataLoading,
    isFetching: isNotificationDataFetching,
    error,
  } = useGetNotificationDetailsQuery(id, { skip: !id });

  const {
    data: deliveryMethods,
    isLoading: isDeliveryMethodsLoading,
    isFetching: isDeliveryMethodsFetching,
    error: deliveryMethodsError,
  } = useGetDeliveryMethodsQuery({ page: -1 });

  const [activateMethod] = useActivateDeliveryMethodForNotificationMutation();

  const [deactivateMethod] = useDeactivateDeliveryMethodForNotificationMutation();

  const handleToggleMethodActivation = useCallback((deliveryMethod) => async (checked) => {
    try {
      if (checked) await activateMethod({ typeId: id, body: { deliveryMethod } }).unwrap();
      else await deactivateMethod({ typeId: id, body: { deliveryMethod } }).unwrap();

      message.success(t('Способы оповещения изменены'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [activateMethod, deactivateMethod, id, t]);

  if (
    isNotificationDataLoading
    || isNotificationDataFetching
    || isDeliveryMethodsLoading
    || isDeliveryMethodsFetching
  ) return <Skeleton loading />;

  if (error) return <FetchErrorContent error={error} />
  if (deliveryMethodsError) return <FetchErrorContent error={deliveryMethodsError} />

  const deactivatedMethodsGlobally = deliveryMethods?.results.filter((method) => !method.isActive);

  return (
    <div>
      <ContentHeader title={notificationData?.name[i18n.language]}>
        <EditNotification
          id={notificationData?.id}
          initialValues={notificationData}
        />
      </ContentHeader>

      <Row gutter={20}>
        <Col md={24} style={{ marginBottom: 16 }}>
          <Text italic>{notificationData?.description?.[i18n.language]}</Text>
        </Col>
        <Col md={12}>
          <Text>{t('Системное название')}: </Text>
          <Text code>{notificationData?.sysName}</Text>
          <br/>
          <br/>
          <Text>{t('Оповещение получает')}: </Text>
          <Text strong>{notificationData?.receivingUserType.name[i18n.language]}</Text>
        </Col>
        <Col md={12}>
          <Title level={5}>
            {t('Способы оповещения')}
          </Title>
          {
            sort((a, b) => a.id - b.id, notificationData?.deliveryMethods).map(({ id, alias, isActive }) => (
              <Tag.CheckableTag
                key={id}
                checked={isActive}
                onChange={handleToggleMethodActivation(id)}
              >
                {apiAliases[alias]}
              </Tag.CheckableTag>
            ))
          }
          <div style={{ marginTop: 16 }}>
            {
              (deactivatedMethodsGlobally?.length > 0)
                ? (
                  <Alert
                    showIcon
                    type="warning"
                    message={t('Внимание! Следующие каналы оповещения выключены глобально')}
                    description={
                      <Space direction="vertical" size="small">
                        {deactivatedMethodsGlobally.map((method) => (
                          <Text strong key={method.id} >{apiAliases[method.alias]}</Text>
                        ))}
                        <Text>{t('Никакие оповещения не смогут быть отосланы по выключеному каналу')}</Text>
                      </Space>
                    }
                  />
                ) : null
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
