import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import { useGetPackageCancellationRequestsQuery } from '../../../store/apis/finances/packageCancellations';
import { Avatar, Button, Col, Divider, Row, Skeleton, Space, Table, Tag, Typography } from 'antd';
import { formatDate, formatNumber } from '../../../utils';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import LabelData from '../../../components/LabelData';
import RejectPackageCancellationRequest from '../RejectPackageCancellationRequest';
import ApprovePackageCancellationRequest from '../ApprovePackageCancellationRequest';
import usePermissions from '../../../hooks/usePermissions';

const { Text } = Typography;

const List = () => {
  const [t, i18n] = useTranslation();
  const { page, pageSize, ...query } = useQueryString();
  const navigate = useNavigate();
  const hasAccess = usePermissions();

  const {
    data: packageCancellationRequestsData,
    isLoading: isPackageCancellationRequestsLoading,
    isFetching: isPackageCancellationRequestsFetching,
  } = useGetPackageCancellationRequestsQuery({ page, pageSize, ...query });

  const columns = useMemo(() => [
    {
      title: t('Студент'),
      dataIndex: 'student',
      key: 'student',
      render: (data) => (
        <Row align="middle" gutter={8} wrap={false}>
          <Col>
            <Avatar src={data.photoUrl} />
          </Col>
          <Col>
            <Space direction="vertical" size="small">
              {
                hasAccess(['ADMIN', 'SUPER_ADMIN'])
                  ? (
                    <Link to={routes.students(data.id)}>
                      {data.fullName?.[i18n.language]}
                    </Link>
                  ) : data.fullName?.[i18n.language]
              }
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Учитель'),
      dataIndex: 'tutor',
      key: 'tutor',
      render: (data) => (
        <Row align="middle" gutter={8} wrap={false}>
          <Col>
            <Avatar src={data.photoUrl} />
          </Col>
          <Col>
            <Space direction="vertical" size="small">
              {
                hasAccess(['ADMIN', 'SUPER_ADMIN'])
                  ? (
                    <Link to={routes.teachers(data.id)}>
                      {data.fullName?.[i18n.language]}
                    </Link>
                  ) : data.fullName?.[i18n.language]
              }
              <Text type="secondary">{data.shortInfo?.[i18n.language]}</Text>
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      title: t('Сумма возврата (сум)'),
      dataIndex: 'amountToRefund',
      key: 'amountToRefund',
      render: (data) => formatNumber(data),
    },
    {
      title: t('Причина'),
      dataIndex: 'reason',
      key: 'reason',
      render: (data) => data.name[i18n.language],
    },
    {
      title: t('Статус'),
      dataIndex: 'status',
      key: 'status',
      render: (data) => {
        const colors = ['gold', 'blue', 'green', 'red'];
        return <Tag color={colors[data.id]}>{data.name[i18n.language]}</Tag>
      },
    },
  ], [i18n.language, t]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...query }),
    })
  }, [navigate, query]);

  if (isPackageCancellationRequestsFetching || isPackageCancellationRequestsLoading) return (
    <Skeleton loading />
  );

  const tableData = packageCancellationRequestsData?.results.map(({ package: pack, ...rest }) => ({
    student: pack.student,
    tutor: pack.tutor,
    tariff: pack.tariff,
    packagePrice: pack.price,
    packagePricePerLesson: pack.pricePerLesson,
    lessonCount: pack.lessonCount,
    paidAtTime: pack.paidAtTime,
    ...rest
  })) ?? [];

  return (
    <div>
      <div className={styles.header}>
        <ContentHeader title={t('Запросы на возврат средств от учеников')}>

        </ContentHeader>
      </div>
      <div className={styles.body}>
        <Table
          rootClassName="overflow-scroll"
          columns={columns}
          dataSource={tableData}
          rowKey="id"
          pagination={{
            total: packageCancellationRequestsData?.count,
            current: page,
            locale: { items_per_page: `/ ${t('на странице')}` },
            pageSize,
            showSizeChanger: true,
            showTitle: true,
            pageSizeOptions: [20, 50, 100],
          }}
          onChange={handlePageChange}
          expandable={{
            expandedRowRender: (record) => (
              <Row gutter={32}>
                <Col lg={8}>
                  <LabelData label={t('Дата создания запроса')} data={formatDate(record.createdTime, true)} />
                </Col>
                <Col lg={8}>
                  <LabelData label={t('Комментарий студента')} data={record.studentComment} />
                </Col>
                {
                  record.staffComment && (
                    <Col lg={8}>
                      <LabelData label={t('Комментарий администратора')} data={record.staffComment} />
                    </Col>
                  )
                }
                <Divider />
                <Col lg={8}>
                  <LabelData label={t('Тариф')} data={record.tariff?.name[i18n.language]} />
                </Col>
                <Col lg={8}>
                  <LabelData label={t('Количество уроков в тарифе')} data={record.lessonCount} />
                </Col>
                <Col lg={8}>
                  <LabelData label={t('Дата оплаты')} data={formatDate(record.paidAtTime)} />
                </Col>
                {
                  record.status.id === 1 && hasAccess(['ADMIN', 'SUPER_ADMIN']) && (
                    <>
                      <Divider />
                      <Col lg={8} offset={8}>
                        <Space size="large">
                          <RejectPackageCancellationRequest id={record.id} />
                          <ApprovePackageCancellationRequest id={record.id} />
                        </Space>
                      </Col>
                    </>
                  )
                }
              </Row>
            )
          }}
        />
      </div>
    </div>
  );
};

export default List;
