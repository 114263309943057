import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './ToggleTutorProfilePublicity.module.scss';
import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import { useCancelTutorPublicMutation, useMakeTutorPublicMutation } from '../../../store/apis/administratives/tutors';

const ToggleTutorProfilePublicity = ({ tutorId, isPublished }) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [
    publishTutor,
    { isLoading: isPublishLoading, isFetching: isPublishFetching },
  ] = useMakeTutorPublicMutation();
  const [
    unpublishTutor,
    { isLoading: isUnpublishLoading, isFetching: isUnpublishFetching },
  ] = useCancelTutorPublicMutation();

  const mode = isPublished ? 'deactivate' : 'activate';

  const modes = useMemo(() => ({
    deactivate: {
      confirmTitle: t('Вы действительно хотите деактивировать учителя?'),
      confirmDescription: t('Учитель не будет выходить в поиске для студентов'),
      okText: t('Да, деактивировать'),
      actionText: t('Деактивировать'),
      successText: t('Преподаватель деактивирован'),
      action: unpublishTutor,
    },
    activate: {
      confirmTitle: t('Вы действительно хотите активировать учителя?'),
      confirmDescription: t('Учитель будет выходить в поиске для студентов и доступен для брони'),
      okText: t('Да, активировать'),
      actionText: t('Активировать'),
      successText: t('Преподаватель активирован'),
      action: publishTutor,
    }
  }), [publishTutor, t, unpublishTutor]);

  const confirm = useCallback(async() => {
    try {
      await modes[mode].action({ tutorId }).unwrap();
      message.success(modes[mode].successText);
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [mode, modes, tutorId]);

  return (
    <Popconfirm
      open={isOpen}
      title={modes[mode].confirmTitle}
      description={modes[mode].confirmDescription}
      okText={modes[mode].okText}
      cancelText={t('Нет')}
      onConfirm={confirm}
      onCancel={close}
      okButtonProps={{
        loading: isPublishLoading || isPublishFetching || isUnpublishLoading || isUnpublishFetching,
      }}
    >
      <Button
        block
        type="primary"
        onClick={open}
      >
        {modes[mode].actionText}
      </Button>
    </Popconfirm>
  );
};

export default ToggleTutorProfilePublicity;
