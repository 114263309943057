// import cn from 'classnames';

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetBookkeepingDetailsQuery } from '../../store/apis/finances/bookkeeping';
import usePermissions from '../../hooks/usePermissions';
import { Link } from 'react-router-dom';
import routes from '../../router/routes';
import { formatDate, formatNumber, toSom } from '../../utils';
import { Col, Empty, Row, Skeleton, Table, Tabs, Tag, Typography } from 'antd';
import PayOneBookkeeping from './PayOneBookkeeping';
import PayAllBookkeeping from './PayAllBookkeeping';

const BookkeepingDetailsTable = ({
  data = [],
  hasPayAction = true,
}) => {
  const [t, i18n] = useTranslation();

  const checkPermissions = usePermissions();

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('ID'),
    },
    {
      key: 'tutor',
      dataIndex: 'tutor',
      title: t('Учитель'),
      render: (tutor) => (
        <Link to={routes.teachers(tutor.id)}>
          {tutor.fullName[i18n.language]}
        </Link>
      ),
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: t('Сумма к выплате'),
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'amountWithoutTaxes',
      dataIndex: 'amountWithoutTaxes',
      title: t('Сумма к выплате с учетом налогов'),
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'taxPercent',
      dataIndex: 'taxPercent',
      title: t('Налоговый процент'),
    },
    {
      key: 'isPaid',
      dataIndex: 'isPaid',
      title: t('Статус платежа'),
      render: (isPaid) => isPaid
        ? <Tag color="green">{t('Оплачен')}</Tag>
        : <Tag color="red">{t('Не оплачен')}</Tag>,
    },
    {
      key: 'actions',
      dataIndex: 'id',
      title: t('Действия'),
      render: (id) => checkPermissions(['SUPER_ADMIN', 'ACCOUNTANT']) && hasPayAction
        ? <PayOneBookkeeping paycheckId={id} />
        : null
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 20,
        }}
        rowKey="id"
        expandable={{
          expandedRowRender: (row) => {
            const incomeCols = [
              {
                key: 'lessonTime',
                dataIndex: 'lessonTime',
                title: t('Время урока'),
                render: (date) => formatDate(date, true),
              },
              {
                key: 'incomeAmount',
                dataIndex: 'incomeAmount',
                title: t('Начисления'),
                render: (amount) => formatNumber(toSom(amount)),
              },
              {
                key: 'student',
                dataIndex: 'student',
                title: t('Студент'),
                render: (student) => student.fullName?.[i18n.language],
              },
            ];

            const penaltyCols = [
              {
                key: 'lessonTime',
                dataIndex: 'lessonTime',
                title: t('Время урока'),
                render: (date) => formatDate(date, true),
              },
              {
                key: 'penaltyAmount',
                dataIndex: 'penaltyAmount',
                title: t('Штраф'),
                render: (amount) => formatNumber(toSom(amount)),
              },
              {
                key: 'student',
                dataIndex: 'student',
                title: t('Студент'),
                render: (student) => student.fullName?.[i18n.language],
              },
            ];

            const tabsItems = [
              {
                key: 1,
                label: t('Начисления'),
                children: (
                  <div >
                    <Typography.Title level={5}>{t('Начисления')}</Typography.Title>
                    <Table
                      columns={incomeCols}
                      dataSource={row.incomes}
                      pagination={{
                        pageSize: 50,
                      }}
                    />
                  </div>
                )
              },
              {
                key: 2,
                label: t('Штрафы'),
                children: (
                  <div>
                    <Typography.Title level={5}>{t('Штрафы')}</Typography.Title>
                    <Table
                      columns={penaltyCols}
                      dataSource={row.penalties}
                      pagination={{
                        pageSize: 50,
                      }}
                    />
                  </div>
                )
              }
            ]
            return (
              <Tabs type="card" defaultActiveKey={1} items={tabsItems} />
            )
          }
        }}
      />
    </div>
  );
};

export default BookkeepingDetailsTable;
