import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './List.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useActivateDeliveryMethodGloballyMutation, useDeactivateDeliveryMethodForGloballyMutation,
  useGetDeliveryMethodsQuery,
  useGetNotificationListQuery
} from '../../../store/apis/administratives/notifications';
import { Col, Row, Skeleton, Space, Switch, Table, Tag, Typography } from 'antd';
import FetchErrorContent from '../../../containers/shared/FetchErrorContent';
import ContentHeader from '../../../components/ContentHeader';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { sort } from 'ramda';
import { useApiAliases } from '../../../apiAliases';
import queryString from 'query-string';

const { Text, Title } = Typography;

const List = () => {
  const [t, i18n] = useTranslation();
  const apiAliases = useApiAliases();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...query } = useQueryString();

  const {
    data: notificationData,
    isLoading: isNotificationDataLoading,
    isFetching: isNotificationDataFetching,
    error,
  } = useGetNotificationListQuery({ page, pageSize, ...query });

  const {
    data: deliveryMethods,
    isLoading: isDeliveryMethodsLoading,
    isFetching: isDeliveryMethodsFetching,
    error: deliveryMethodsError,
  } = useGetDeliveryMethodsQuery({ page: -1 });

  const [activateDeliveryMethodGlobally] = useActivateDeliveryMethodGloballyMutation();
  const [deactivateDeliveryMethodGlobally] = useDeactivateDeliveryMethodForGloballyMutation();

  const goToNotificationDetails = useCallback((id) => () => {
    navigate(routes.notifications(id));
  }, [navigate]);

  const toggleDeliveryMethod = useCallback((id) => async (checked) => {
    try {
      if (checked) await activateDeliveryMethodGlobally(id).unwrap();
      else await deactivateDeliveryMethodGlobally(id).unwrap();
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [activateDeliveryMethodGlobally, deactivateDeliveryMethodGlobally]);

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize }),
    })
  }, [navigate]);

  const columns = useMemo(() => [
    {
      title: t('Оповещение'),
      key: 'name',
      dataIndex: 'name',
      render: (value) => value[i18n.language],
    },
    {
      title: t('Системное название'),
      key: 'sysName',
      dataIndex: 'sysName',
      render: (value) => <Text code>{value}</Text>,
    },
    {
      title: t('Способы оповещения'),
      key: 'deliveryMethods',
      dataIndex: 'deliveryMethods',
      render: (value) => value?.map((method) => (
        method.isActive ? <Tag key={method.id} color="blue">{apiAliases[method.alias]}</Tag> : null
      )),
    },
  ], [i18n.language, t]);

  if (
    isNotificationDataLoading
    || isDeliveryMethodsLoading
    || isNotificationDataFetching
    || isDeliveryMethodsFetching
  ) return (
    <Skeleton loading />
  );

  if (error) return (
    <FetchErrorContent error={error} />
  );

  return (
    <div>
      <ContentHeader title={t('Уведомления')} />

      <Table
        rootClassName="overflow-scroll"
        columns={columns}
        dataSource={notificationData?.results}
        rowKey="id"
        rowClassName={styles.row}
        onRow={(raw) => ({
          onClick: goToNotificationDetails(raw.id),
        })}
        onChange={handlePageChange}
        pagination={{
          total: notificationData?.count,
          current: page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />

      <Row>
        <Col md={24}>
          <Title level={3}>{t('Глобальные каналы уведомлений')}</Title>
        </Col>
        <Col md={24}>
          <Space direction="vertical">
            {
              sort((a, b) => a.id - b.id, deliveryMethods?.results).map(({ id, alias, isActive }) => (
                <Space key={id}>
                  <Switch
                    defaultChecked={isActive}
                    checked={isActive}
                    onChange={toggleDeliveryMethod(id)}
                    loading={isNotificationDataFetching || isDeliveryMethodsFetching}
                  /> <Text>{apiAliases[alias]}</Text>
                </Space>
              ))
            }
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default List;
