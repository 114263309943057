import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getSubjects: build.query({
      query: (params) => ({
        url: apiRoutes.subjects(),
        params,
      }),
      providesTags: ['SUBJECTS'],
    }),
    getSubject: build.query({
      query: (subjectId) => ({
        url: apiRoutes.subjects(subjectId),
      }),
      providesTags: ['SUBJECT'],
    }),
    editSubject: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.subjects(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SUBJECT', 'SUBJECTS'] }),
    }),
    createSubject: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.subjects(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['SUBJECT', 'SUBJECTS'] }),
    }),
  })
});

export const {
  useGetSubjectsQuery,
  useGetSubjectQuery,
  useEditSubjectMutation,
  useCreateSubjectMutation,
} = rootApi;
