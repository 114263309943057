import React from 'react';
import cn from 'classnames';

import styles from './ActionButton.module.scss';

const ActionButton = ({
  color = 'blue',
  onClick,
  text,
  icon,
}) => {
  const btnClasses = cn({
    [styles.btn]: true,
    [styles.btnBlue]: color === 'blue',
    [styles.btnRed]: color === 'red',
    [styles.btnGreen]: color === 'green',
    [styles.btnYellow]: color === 'yellow',
  });

  return (
    <button type="button" onClick={onClick} className={btnClasses}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.text}>{text}</span>
    </button>
  );
};

export default ActionButton;
