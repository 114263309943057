import apiRoutes from '../../../apiRoutes';
import { invalidateOn } from '../../utils';
import { rootApi } from '../index';

rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTutors: build.query({
      query: (params) => ({
        url: apiRoutes.tutors(),
        params,
      }),
      providesTags: ['TUTORS'],
    }),
    getTutorDetails: build.query({
      query: (id) => ({
        url: apiRoutes.tutors(id),
      }),
      providesTags: ['TUTOR_DETAILS'],
    }),
    editTutorPersonal: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.tutors(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    createTutor: build.mutation({
      query: ({ body }) => ({
        url: apiRoutes.tutors(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTORS'] }),
    }),

    createTutorEducation: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsEducation(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    createTutorCertificate: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsCertificates(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    createTutorWorkExperience: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsWorkExperience(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    createTutorLanguageSkill: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsLanguageSkill(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),

    editTutorEducation: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsEducation(body.id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    editTutorCertificate: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsCertificates(body.id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    editTutorWorkExperience: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsWorkExperience(body.id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    editTutorLanguageSkill: build.mutation({
      query: (body) => ({
        url: apiRoutes.tutorsLanguageSkill(body.id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),

    removeTutorEducation: build.mutation({
      query: (id) => ({
        url: apiRoutes.tutorsEducation(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    removeTutorCertificate: build.mutation({
      query: (id) => ({
        url: apiRoutes.tutorsCertificates(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    removeTutorWorkExperience: build.mutation({
      query: (id) => ({
        url: apiRoutes.tutorsWorkExperience(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    removeTutorLanguageSkill: build.mutation({
      query: (id) => ({
        url: apiRoutes.tutorsLanguageSkill(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),

    getTutorPrice: build.query({
      query: ({ tutorId }) => ({
        url: apiRoutes.learningTutorProfile(tutorId),
      }),
      providesTags: ['TUTOR_PRICE'],
    }),
    setTutorPrice: build.mutation({
      query: ({ tutorId, body }) => ({
        url: apiRoutes.learningTutorProfile(tutorId),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_PRICE'] }),
    }),

    makeTutorPublic: build.mutation({
      query: ({ tutorId }) => ({
        url: apiRoutes.makeTutorPublic(tutorId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),
    cancelTutorPublic: build.mutation({
      query: ({ tutorId }) => ({
        url: apiRoutes.cancelTutorPublic(tutorId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS', 'TUTORS'] }),
    }),

    changeTutorPassword: build.mutation({
      query: ({ tutorId, body }) => ({
        url: apiRoutes.changeTutorPassword(tutorId),
        method: 'POST',
        body,
      }),
    }),
    changeTutorPhone: build.mutation({
      query: ({ tutorId, body }) => ({
        url: apiRoutes.changeTutorPhone(tutorId),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS'] }),
    }),
    updateTutorInitialRate: build.mutation({
      query: ({ tutorId, body }) => ({
        url: apiRoutes.initialTutorRate(tutorId),
        method: 'PUT',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['TUTOR_DETAILS'] }),
    }),
  })
});

export const {
  useGetTutorsQuery,
  useLazyGetTutorsQuery,
  useGetTutorDetailsQuery,
  useLazyGetTutorDetailsQuery,
  useEditTutorPersonalMutation,
  useCreateTutorMutation,
  useCreateTutorEducationMutation,
  useCreateTutorCertificateMutation,
  useCreateTutorWorkExperienceMutation,
  useCreateTutorLanguageSkillMutation,
  useEditTutorEducationMutation,
  useEditTutorCertificateMutation,
  useEditTutorWorkExperienceMutation,
  useEditTutorLanguageSkillMutation,
  useRemoveTutorEducationMutation,
  useRemoveTutorCertificateMutation,
  useRemoveTutorWorkExperienceMutation,
  useRemoveTutorLanguageSkillMutation,
  useGetTutorPriceQuery,
  useSetTutorPriceMutation,
  useMakeTutorPublicMutation,
  useCancelTutorPublicMutation,
  useChangeTutorPasswordMutation,
  useChangeTutorPhoneMutation,
  useUpdateTutorInitialRateMutation,
} = rootApi;
