import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './LessonMovementsByTutor.module.scss';
import ContentHeader from '../../../components/ContentHeader';
import { useTranslation } from 'react-i18next';
import { useGetLessonMovementsQuery } from '../../../store/apis/reports/dashboard';
import { DatePicker, Skeleton, Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { joinExisting } from '../../../utils';
import routes from '../../../router/routes';
import dayjs from 'dayjs';
import usePermissions from '../../../hooks/usePermissions';

const { Title, Text } = Typography;

const LessonMovementsByTutor = () => {
  const [t] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);
  const hasAccess = usePermissions();

  const {
    data: movements,
    isLoading: isMovementsLoading,
    isFetching: isMovementsFetching,
    error: movementsError,
  } = useGetLessonMovementsQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const columns = [
    {
      title: t('Учитель'),
      dataIndex: 'id',
      key: 'id',
      render: (id, raw) => hasAccess(['ADMIN', 'SUPER_ADMIN', 'HR'])
        ? <Link to={routes.teachers(id)}>{joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')}</Link>
        : joinExisting([raw.lastName, raw.firstName, raw.secondName], ' '),
      width: 200,
      fixed: 'left',
    },
    {
      title: t('Отменено студентами'),
      dataIndex: 'cancellationsByStudentCount',
      key: 'cancellationsByStudentCount',
      render: (count, raw) => count,
      width: 120,
    },
    {
      title: t('Отменено учителем'),
      dataIndex: 'cancellationsByTutorCount',
      key: 'cancellationsByTutorCount',
      render: (count, raw) => count,
      width: 120,
    },
    {
      title: t('Проведено уроков'),
      dataIndex: 'finishedLessonCount',
      key: 'finishedLessonCount',
      render: (count, raw) => count,
      width: 120,
    },
    {
      title: t('Запросы на перенос занятий (от ученика)'),
      children: [
        {
          title: t('Текущие'),
          dataIndex: 'transfersByStudentNewCount',
          key: 'transfersByStudentNewCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Одобренные'),
          dataIndex: 'transfersByStudentApprovedCount',
          key: 'transfersByStudentApprovedCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Отклоненные учителем'),
          dataIndex: 'transfersByStudentRejectedByTutorCount',
          key: 'transfersByStudentRejectedByTutorCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Отклоненные системой'),
          dataIndex: 'transfersByStudentRejectedBySystemCount',
          key: 'transfersByStudentRejectedBySystemCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Всего'),
          dataIndex: 'transfersByStudentTotalCount',
          key: 'transfersByStudentTotalCount',
          render: (count, raw) => count,
          width: 140,
        },
      ],
    },
    {
      title: t('Запросы на перенос занятий (от ученика)'),
      children: [
        {
          title: t('Текущие'),
          dataIndex: 'transfersByTutorNewCount',
          key: 'transfersByTutorNewCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Одобренные'),
          dataIndex: 'transfersByTutorApprovedCount',
          key: 'transfersByTutorApprovedCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Отклоненные учеником'),
          dataIndex: 'transfersByTutorRejectedByStudentCount',
          key: 'transfersByTutorRejectedByStudentCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Отклоненные системой'),
          dataIndex: 'transfersByTutorRejectedBySystemCount',
          key: 'transfersByTutorRejectedBySystemCount',
          render: (count, raw) => count,
          width: 140,
        },
        {
          title: t('Всего'),
          dataIndex: 'transfersByTutorTotalCount',
          key: 'transfersByTutorTotalCount',
          render: (count, raw) => count,
          width: 140,
        },
      ],
    }
  ];

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
    {
      label: t('С начала года'),
      value: [dayjs().startOf('year'), dayjs()],
    },
  ];

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  if (isMovementsLoading) return <Skeleton />;
  if (movementsError) return (
    <div>
      <Title level={3}>Ой, кажется произошла ошибка</Title>
      <pre>{JSON.stringify(movements, 0, 2)}</pre>
    </div>
  );
  if (!movements) return null;

  return (
    <div>
      <ContentHeader title={t('Статистика по отменам')}/>
      <Space>
        <Text>{t('Статистика за период')}</Text>
        <DatePicker.RangePicker
          presets={rangePresets}
          format="DD.MM.YYYY"
          onChange={changeFilterDate}
          value={lessonDateDiapason}
          allowClear={false}
        />
      </Space>
      <Table
        loading={isMovementsFetching}
        columns={columns}
        rootClassName="overflow-scroll"
        rowKey="id"
        dataSource={movements.rows}
        bordered
        pagination={{
          pageSize: 20,
        }}
        scroll={{ x: 1600, y: 640 }}
      />
    </div>
  );
};

export default LessonMovementsByTutor;
