import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { message } from 'antd';
import routes from '../../../router/routes';
import ContentHeader from '../../../components/ContentHeader';
import DictionaryForm from '../../../containers/shared/DictionaryForm';
import {
  useCreatePackageCancellationRequestReasonMutation
} from '../../../store/apis/dictionaries/package-cancellation-request-reasons';

const Create = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [create, { isFetching, isLoading }] = useCreatePackageCancellationRequestReasonMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      await create({ body: values }).unwrap();
      message.success(t('Причина отмены пакета создана'));
    } catch (e) {
      console.log(JSON.stringify(e, 0, 2));
    }
  }, [create, t]);

  const handleExit = useCallback(() => {
    navigate(routes.packageCancellationRequestsReasons());
  }, [navigate]);

  return (
    <div>
      <ContentHeader title={t('Создать новую причину отмены пакета')} />
      <DictionaryForm
        onSubmit={handleSubmit}
        onExit={handleExit}
        initialValues={{}}
        loading={isFetching || isLoading}
        saveTitle={t('Сохранить и создать еще')}
        saveAndExitTitle={t('Сохранить и выйти')}
      />
    </div>
  );
};

export default Create;
