import React, { useCallback, useMemo } from 'react';
import { useGetTutorCvsQuery } from '../../../store/apis/tutorCv';
import { useTranslation } from 'react-i18next';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { Table, Tag } from 'antd';
import { formatDate, joinExisting } from '../../../utils';
import ContentHeader from '../../../components/ContentHeader';
import MarkAsApproved from '../../../containers/tutorCvs/MarkAsApproved';
import { useApiAliases } from '../../../apiAliases';

const List = () => {
  const [t] = useTranslation();
  const { page = 1, pageSize = 20, sort = '-id', ...restQuery } = useQueryString();
  const navigate = useNavigate();
  const apiAliases = useApiAliases();
  // const {
  //   data: reviews,
  //   isLoading: isReviewsLoading,
  //   isFetching: isReviewsFetching,
  // } = useGetReviewsQuery({ page, pageSize, sort, ...restQuery });

  const { data, isLoading, isFetching } = useGetTutorCvsQuery({ page, pageSize, sort, ...restQuery });

  const handlePageChange = useCallback((pagination) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ page: pagination.current, pageSize: pagination.pageSize, ...restQuery }),
    })
  }, [navigate, restQuery]);

  const columns = useMemo(() => [
    {
      title: t('Статус'),
      dataIndex: 'resolutionStatus',
      key: 'resolutionStatus',
      render: (status) => {
        const colors = ['gold', 'blue', 'green', 'red'];
        return (
          <Tag color={colors[status.id]}>{apiAliases[status.sysName]}</Tag>
        );
      },
    },
    {
      title: t('Дата заявки'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: t('ФИО'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, raw) => (
        joinExisting([raw.lastName, raw.firstName, raw.secondName], ' ')
      ),
    },
    {
      title: t('Дата рождения'),
      dataIndex: 'birthdate',
      key: 'birthdate',
      render: (date) => formatDate(date),
    },
    {
      title: t('Стаж'),
      dataIndex: 'yearsOfWorkExperience',
      key: 'yearsOfWorkExperience',
    },
    {
      title: t('Номер телефона'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Файлы'),
      dataIndex: 'files',
      key: 'files',
      render: (files) => (
        <div>
          {files?.map(({ name, url }) => (
            <a
              href={url}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginBottom: 10, display: 'block' }}
            >
              {name}<br/>
            </a>
          ))}
        </div>
      ),
    },
    {
      title: t('Действия'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, raw) => (
        <>
          {
            (raw.resolutionStatus.sysName === 'new' || raw.resolutionStatus.sysName === 'declined')
              ? <MarkAsApproved id={raw.id} status="approved" />
              : null
          }
          {
            (raw.resolutionStatus.sysName === 'new' || raw.resolutionStatus.sysName === 'approved')
              ? <MarkAsApproved id={raw.id} status="declined" />
              : null
          }
        </>
      ),
    },
  ], [t]);

  return (
    <div>
      <ContentHeader title={t('Резюме учителей (заявки)')} />

      <Table
        rootClassName="overflow-scroll"
        columns={columns}
        loading={isLoading || isFetching}
        dataSource={data?.results}
        rowKey="id"
        onChange={handlePageChange}
        pagination={{
          total: data?.count,
          current: page,
          locale: { items_per_page: `/ ${t('на странице')}` },
          pageSize,
          showSizeChanger: true,
          showTitle: true,
          pageSizeOptions: [20, 50, 100],
        }}
      />
    </div>
  );
};

export default List;
