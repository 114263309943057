import { useGetMeQuery } from '../store/apis/profile';
import { intersection, pluck, propEq } from 'ramda';

const usePermissions = () => {
  const {
    data: me,
    isLoading: isMeLoading,
    isFetching: isMeFetching,
  } = useGetMeQuery();

  const checkPermissions = (permissions) => {
    if (isMeLoading || isMeFetching) return false;
    if (!me || !me.roles) return false;
    const roles = pluck('alias')(me.roles);
    const intersectedPermissions = intersection(permissions, roles);

    return intersectedPermissions.length > 0;
  };

  return checkPermissions;
};

export default usePermissions;
